import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SignupFormikValues } from ".";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  SIGNUP_PATH,
  SIGNUP_SUBSCRIPTION_BENEFITS_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from "../../routes";
import {
  selectRegistrationEmail,
  setUserPassword,
} from "../../slices/registrationSlice";
import { getCommonStyles } from "../../style";
import { validatePassword } from "../../validation";
import BackButton from "../common/BackButton";
import FormikTextField from "../common/formik/FormikTextField";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";
import PasswordStrength from "../common/PasswordStrength";
import { getSignupStyles } from "../common/signupStyle";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  backButton: {
    alignSelf: "flex-start",
    padding: `${theme.spacing() * 2}px 0`,
  },
  termsAndConditions: {
    alignSelf: "flex-start",
  },
}));

const PasswordPage: React.FC<
  RouteComponentProps & WithMediaDownProps
> = function ({ history, isMediaDown }) {
  const classes = styles();
  const dispatch = useAppDispatch();

  const { values } = useFormikContext<SignupFormikValues>();
  const emailState = useAppSelector(selectRegistrationEmail);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const passwordStrengthErrors = useMemo(() => {
    return validationErrors.filter((el) => el !== "required");
  }, [validationErrors]);

  useEffect(() => {
    if (!emailState) {
      history.push(SIGNUP_PATH);
    }
  }, [emailState]);

  useEffect(() => {
    setValidationErrors(validatePassword(values.password));
  }, [values.password]);

  return (
    <React.Fragment>
      {!isMediaDown && <BackButton />}
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div className={classes.titleContainer}>
          {isMediaDown && <BackButton />}
          <Logo size={isMediaDown ? "md" : "lg"} />
          <Typography variant="h1" color="primary" align="center">
            Въведи {isMediaDown && <br />}парола
          </Typography>
        </div>
        <div className={classes.content}>
          <Field
            component={FormikTextField}
            name="password"
            label="Въведи парола"
            fullWidth
            InputProps={{
              inputProps: {
                type: showPassword ? "text" : "password",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLock} />
                </InputAdornment>
              ),
              endAdornment: values.password ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowPassword((prevState) => !prevState);
                    }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <PasswordStrength errors={passwordStrengthErrors} />
        </div>
        <div className={classes.footerContainer}>
          <FormControlLabel
            className={classes.termsAndConditions}
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                name="termsAndConditions"
                color="primary"
              />
            }
            label={
              <Typography
                variant="overline"
                color="primary"
                onClick={() => {
                  dispatch(setUserPassword(values.password));
                  history.push(TERMS_AND_CONDITIONS_PATH);
                }}
              >
                Приемам <u>условията за ползване</u>
              </Typography>
            }
          />
          <Button
            onClick={() => {
              dispatch(setUserPassword(values.password));
              history.push(SIGNUP_SUBSCRIPTION_BENEFITS_PATH);
            }}
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              !termsAccepted || !values.password || !!validationErrors.length
            }
          >
            Продължи
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default WithMediaDown(PasswordPage, "md");
