import { faDice, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Link,
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_API_KEY } from "../../config";
import {
  APPLICATION_PATH,
  APP_PRIVACY_POLICY_PATH,
  APP_TERMS_AND_CONDITIONS_PATH,
  FEEDBACK_PATH,
  GENERATED_ROUTES_PATH,
  GENERATED_ROUTE_OVERVIEW_PATH,
} from "../../routes";
import {
  fetchRandomRoute,
  generateRoutes,
  setGeneratedRoutes,
} from "../../slices/routeSlice";
import { selectCurrentUser } from "../../slices/usersSlice";
import AccentCard from "../common/AccentCard";
import AutocompleteTextField, {
  AutocompleteOption,
} from "../common/AutocompleteTextField";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";
import { getSignupStyles } from "../common/signupStyle";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import GeneratedRouteOverviewPage from "../generatedRoutes/OverviewPage";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  contentContainer: {
    margin: `${theme.spacing(5)}px auto`,
  },
  searchInputAdornment: {
    color: theme.palette.primary.main,
  },
  searchesLeftButton: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    minWidth: 0,
    minHeight: 0,
  },
  infiniteIcon: {
    width: 20,
    height: 20,
  },
  routeButton: {
    margin: `${theme.spacing()}px 0`,
  },
  map: {
    minHeight: 350,
    width: "100%",
    border: 0,
  },
  hiddenMap: {
    width: 0,
    height: 0,
    visibility: "hidden",
  },
  footer: {
    margin: `${theme.spacing()}px 0`,
  },
}));

const HomePage = ({ isMediaDown }: WithMediaDownProps) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(selectCurrentUser);
  const [search, setSearch] = useState<string>("");
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption>();
  const [map, setMap] = useState<HTMLDivElement>();
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [routeLoading, setRouteLoading] = useState<boolean>(false);
  // const [generateConfirmationOpen, setGenerateConfirmationOpen] =
  //   useState<boolean>(false);
  const location = useLocation();
  const applicationPathMatch = useMemo(() => {
    return matchPath(location.pathname, {
      path: APPLICATION_PATH,
      exact: true,
      strict: false,
    });
  }, [location]);

  const mapSrc = useMemo(() => {
    if (selectedOption && selectedOption.placeId && selectedOption.location) {
      return `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=place_id:${
        selectedOption.placeId
      }&center=${selectedOption.location.lat()},${selectedOption.location.lng()}&zoom=12&region=bg`;
    }
    return "";
  }, [selectedOption]);

  // const handleRecentTripsClick = useCallback(
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  //   },
  //   []
  // );

  const handleMapReset = useCallback(() => {
    if (selectedOption && marker) {
      marker.setMap(null);
      setMarker(undefined);
    }
  }, [selectedOption, marker]);

  useEffect(() => {
    dispatch(setGeneratedRoutes([]));
  }, []);

  // useEffect(() => {
  //   if (selectedOption) {
  //     if (selectedOption.location) {
  //       map?.setCenter(selectedOption.location);
  //       setMarker(
  //         new google.maps.Marker({
  //           map: map,
  //           position: selectedOption.location,
  //           title: selectedOption.text,
  //         })
  //       );
  //     }
  //   }
  // }, [selectedOption]);

  const handleRandomRoute = useCallback(() => {
    setRouteLoading(true);
    dispatch(fetchRandomRoute())
      .then((id) => {
        mixpanel.track("GENERATE_RANDOM_ROUTE", {
          Route: id,
        });
        setRouteLoading(false);
        history.push({
          pathname: GENERATED_ROUTE_OVERVIEW_PATH.replace(":id", id),
          search: "random=true",
        });
      })
      .catch(() => {
        setRouteLoading(false);
      });
  }, []);

  const handleGenerateRoute = useCallback(() => {
    // setGenerateConfirmationOpen(false);
    if (selectedOption && selectedOption.location) {
      setRouteLoading(true);
      dispatch(
        generateRoutes({
          latitude: selectedOption.location.lat(),
          longitude: selectedOption.location.lng(),
          name: selectedOption.text,
        })
      )
        .then(() => {
          setRouteLoading(false);
          history.push(GENERATED_ROUTES_PATH);
        })
        .catch(() => {
          setRouteLoading(false);
        });
    }
  }, [selectedOption]);

  const handleGenerateRouteClick = useCallback(() => {
    if (!user) {
      return;
    }
    handleGenerateRoute();
    // if (user.subscriptionExpirationDate) {
    //   handleGenerateRoute();
    // } else {
    //   setGenerateConfirmationOpen(true);
    // }
  }, [user, selectedOption]);

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  return (
    <WithLoadingBackdrop open={routeLoading}>
      <Container
        maxWidth={isMediaDown ? "xs" : "md"}
        disableGutters
        className={classes.container}
      >
        {!selectedOption && (
          <div className={classes.titleContainer}>
            {(!applicationPathMatch || !applicationPathMatch.isExact) &&
              isMediaDown && <BackButton />}
            <Logo size={isMediaDown ? "md" : "xl"} />
            <Typography variant="h1" color="primary" align="center">
              Накъде ти се пътува?
            </Typography>
          </div>
        )}
        <Container
          maxWidth={"sm"}
          disableGutters
          className={classNames(classes.content, classes.contentContainer)}
        >
          <AutocompleteTextField
            map={map}
            disabled={!user?.activated}
            label="Въведи дестинация"
            variant="outlined"
            option={selectedOption}
            fullWidth
            inputValue={search}
            onSelectOption={(place) => {
              handleMapReset();
              setSearch(place.text);
              setSelectedOption(place);
            }}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    className={classes.searchInputAdornment}
                    icon={faSearchLocation}
                    size="lg"
                  />
                </InputAdornment>
              ),
              endAdornment: null,
              // endAdornment: user?.activated ? (
              //   <InputAdornment position="end">
              //     <Fab
              //       color="primary"
              //       classes={{
              //         sizeSmall: classes.searchesLeftButton,
              //       }}
              //       size="small"
              //       onClick={handleRecentTripsClick}
              //     >
              //       {user.subscriptionExpirationDate ? (
              //         <AllInclusiveIcon className={classes.infiniteIcon} />
              //       ) : (
              //         <Typography>{user?.availableSearchesLeft}</Typography>
              //       )}
              //     </Fab>
              //   </InputAdornment>
              // ) : null,
            }}
          />

          {selectedOption ? (
            <>
              <Button
                disabled={!user?.activated}
                className={classes.routeButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenerateRouteClick}
              >
                ГЕНЕРИРАЙ МАРШРУТ
              </Button>
              <Button
                disabled={!user?.activated}
                variant="text"
                fullWidth
                onClick={() => {
                  handleMapReset();
                  setSearch("");
                  setSelectedOption(undefined);
                }}
              >
                <Typography variant="body1" color="error">
                  ИЗЧИСТИ
                </Typography>
              </Button>
            </>
          ) : (
            <Button
              disabled={!user?.activated}
              className={classes.routeButton}
              variant="text"
              color="primary"
              fullWidth
              startIcon={<FontAwesomeIcon icon={faDice} color="primart" />}
              onClick={handleRandomRoute}
            >
              СЛУЧАЕН МАРШРУТ
            </Button>
          )}
        </Container>
        {/* <div
          ref={(el) => {
            if (!map && el) {
              const mapInstance = new google.maps.Map(el, {
                zoom: 12,
              });
              setMap(mapInstance);
            }
          }}
          className={classNames({ [classes.map]: !!selectedOption })}
        /> */}
        <iframe
          ref={(el) => {
            if (!map && el) {
              setMap(el);
            }
          }}
          className={classNames(
            { [classes.hiddenMap]: !selectedOption },
            { [classes.map]: !!selectedOption }
          )}
          loading="lazy"
          src={mapSrc}
        ></iframe>
        <div className={classes.footerContainer}>
          <Typography variant="body2" color="textSecondary">
            Акцент на месеца
          </Typography>
          <AccentCard />
        </div>
        <div className={classNames({ [classes.footer]: isMediaDown })}>
          <Link to={FEEDBACK_PATH}>
            <Typography variant="body1" color="primary" align="center">
              Обратна връзка
            </Typography>
          </Link>
          <Link to={APP_TERMS_AND_CONDITIONS_PATH}>
            <Typography variant="body1" color="primary" align="center">
              Условия за ползване
            </Typography>
          </Link>
          <Link to={APP_PRIVACY_POLICY_PATH}>
            <Typography variant="body1" color="primary" align="center">
              Политика за поверителност
            </Typography>
          </Link>
        </div>
        {/* <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <RemainingTrips onClose={() => setAnchorEl(null)} />
        </Popover> */}
      </Container>
      {/* <RouteConfirmationDialog
        open={generateConfirmationOpen}
        title="Генерирай маршрут"
        onClose={() => setGenerateConfirmationOpen(false)}
        onConfirm={handleGenerateRoute}
      /> */}
      <Switch>
        <Route
          path={GENERATED_ROUTE_OVERVIEW_PATH}
          component={GeneratedRouteOverviewPage}
        />
      </Switch>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(HomePage, "md");
