import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TimelineIcon from "@material-ui/icons/Timeline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { PLACES_PATH, ROUTES_PATH } from "../../routes";
import { selectCurrentUser } from "../../slices/usersSlice";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    borderRadius: 4,
    boxShadow: "0 4px 2px -2px gray",
    width: "100%",
    background: `transparent linear-gradient(270deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%) 0% 0% no-repeat padding-box`,
    border: "unset",
    color: theme.palette.common.white,
  },
  subscriptionText: {
    display: "flex",
    alignItems: "center",
    margin: `${theme.spacing() / 2}px 0`,
  },
}));

const ProfileOverviewCard: React.FC = ({}) => {
  const classes = styles();
  const user = useAppSelector(selectCurrentUser);
  return (
    <div className={classNames(classes.container)}>
      <Link to={ROUTES_PATH}>
        <Typography
          component="div"
          variant="body1"
          color="inherit"
          className={classes.subscriptionText}
        >
          <TimelineIcon />
          &nbsp; Запаметени маршрути: {user ? user.savedRoutes.length : 0}
        </Typography>
      </Link>
      <Link
        to={{
          pathname: PLACES_PATH,
          search: "visited=true",
        }}
      >
        <Typography
          component="div"
          variant="body1"
          color="inherit"
          className={classes.subscriptionText}
        >
          <LocationOnIcon />
          &nbsp; Посетени обекти: {user ? user.visitedPlaces.length : 0}
        </Typography>
      </Link>
      <Link
        to={{
          pathname: PLACES_PATH,
          search: "favourite=true",
        }}
      >
        <Typography
          component="div"
          variant="body1"
          color="inherit"
          className={classes.subscriptionText}
        >
          <FavoriteIcon />
          &nbsp; Любими обекти: {user ? user.favouritePlaces.length : 0}
        </Typography>
      </Link>
    </div>
  );
};

export default ProfileOverviewCard;
