import moment from "moment";
import Note from "./Note";

export namespace Place {
  export interface Type {
    id: string;
    name: string;
    description: string;
    pictures: string[];
    coverPhoto?: string;
    notes: Note.Type[];
    author?: string;
    location: {
      longitude: number;
      latitude: number;
    };
    visitedCount: number;
    favouriteCount: number;
    regionId: string;
    categoryId: string;
    subCategoryId: string;
    createdAt?: moment.Moment;
    deletedAt?: moment.Moment;
  }

  export const parse = function (data: any): Place.Type {
    return {
      id: data.uuid,
      name: data.name,
      description: data.description,
      pictures: data.pictures || [],
      coverPhoto: data.cover_photo,
      notes: data.notes && data.notes.length ? data.notes.map(Note.parse) : [],
      author: data.author,
      location: data.location,
      visitedCount: data.visited_count,
      favouriteCount: data.favourite_count,
      regionId: data.region.uuid,
      categoryId: data.category.uuid,
      subCategoryId: data.sub_category.uuid,
      // createdAt: data.created_at ? moment(data.created_at) : undefined,
      // deletedAt: data.deleted_at ? moment(data.deleted_at) : undefined,
    };
  };
}

export default Place;
