import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import React, { useCallback } from "react";
import { FeedbackRequest } from "../../api/feedbackAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FEEDBACK_MAPING } from "../../constants";
import { hideFeedbackForm, submitFeedback } from "../../slices/feedbackSlice";
import { selectRegistrationEmail } from "../../slices/registrationSlice";
import { selectCurrentUser } from "../../slices/usersSlice";

const styles = makeStyles((theme: Theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  rating: {
    color: theme.palette.secondary.main,
    margin: `${theme.spacing(2)}px auto`,
  },
}));

const FeedbackDialog: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const context = useAppSelector((state) => state.ui.feedback.context);
  const emailState = useAppSelector(selectRegistrationEmail);
  const user = useAppSelector(selectCurrentUser);
  const [rating, setRating] = React.useState<number>(5);
  const [feedbackText, setFeedbackText] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const resetDialogState = useCallback(() => {
    setRating(5);
    setFeedbackText("");
    setLoading(false);
  }, []);
  const handleDialogClose = useCallback(() => {
    resetDialogState();
    dispatch(hideFeedbackForm());
  }, []);
  const handleSubmit = useCallback(() => {
    if (context) {
      setLoading(true);
      let email = user?.email || "";
      let providerType = user?.providerType || "local";
      if (context === "registration" && !!emailState) {
        email = emailState;
        providerType = "local";
      }
      const feedback: FeedbackRequest = {
        context,
        rating,
        email,
        provider_type: providerType,
      };
      if (feedbackText) {
        feedback["text"] = feedbackText;
      }
      dispatch(submitFeedback(feedback)).then(() => {
        resetDialogState();
        dispatch(hideFeedbackForm());
      });
    }
  }, [context, rating, feedbackText, emailState]);
  return (
    <Dialog
      open={Boolean(context)}
      onClose={(_, reson) => {
        if (reson !== "backdropClick") {
          handleDialogClose();
        }
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" color="primary">
          Твоето мнение
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2">
          {context ? FEEDBACK_MAPING[context] : ""}
        </Typography>
        <Rating
          className={classes.rating}
          name="simple-controlled"
          color="primary"
          value={rating}
          onChange={(_, newValue) => {
            if (!newValue) {
              setRating(0);
            } else {
              setRating(newValue);
            }
          }}
        />
        <TextField
          variant="outlined"
          label="Кажи ни какво мислиш"
          value={feedbackText}
          onChange={(event) => {
            setFeedbackText(event.target.value);
          }}
          multiline
          minRows={2}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleDialogClose}>
          Затвори
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          autoFocus
          disabled={loading}
        >
          {loading ? "Изпращане..." : "Изпрати"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
