import { makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs, { TabsProps } from "@material-ui/core/Tabs";
import classNames from "classnames";
import React from "react";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";

const styles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing() / 2,
    minHeight: "unset",
  },
  tab: {
    backgroundColor: "#00000014",
    height: theme.spacing(4),
    minHeight: "unset",
    minWidth: 160,
  },
  desktopTab: {
    minWidth: 225,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing() / 2,
  },
  indicator: {
    display: "none",
  },
}));

export interface TabOption {
  label: string;
  value: string;
}

interface OwnProps {
  options: TabOption[];
  onChange: (newValue: string) => void;
}

type ComponentProps = OwnProps &
  Omit<TabsProps, "onChange"> &
  WithMediaDownProps;

const CustomTabs: React.FC<ComponentProps> = ({
  options,
  isMediaDown,
  onChange,
  ...rest
}) => {
  const classes = styles();
  return (
    <Tabs
      classes={{ root: classes.root, indicator: classes.indicator }}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      {...rest}
    >
      {options.map((option) => (
        <Tab
          key={option.value}
          classes={{
            root: classNames(classes.tab, {
              [classes.desktopTab]: !isMediaDown,
            }),
            selected: classes.selected,
          }}
          label={option.label}
          value={option.value}
        />
      ))}
    </Tabs>
  );
};

export default WithMediaDown(CustomTabs, "md");
