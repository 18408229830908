import { AxiosError } from "axios";
import { Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { facebookLogin, googleLogin, signup } from "../../api/authAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../constants";
import {
  APPLICATION_PATH,
  SIGNUP_PASSWORD_PATH,
  SIGNUP_SUBSCRIPTION_BENEFITS_PATH,
} from "../../routes";
import {
  selectRegistrationEmail,
  selectRegistrationPassword,
  selectRegistrationSubscriptionMethod,
  setSubmitError,
} from "../../slices/registrationSlice";
import { FormikValidationSchema } from "../../validation";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import BenefitsOverviewPage from "../subscription/BenefitsOverviewPage";
import BenefitsOverview from "../subscription/NonSocialBenefitsPage";
import { SubscriptionType } from "../subscription/constants";
import EmailPage from "./EmailPage";
import PasswordPage from "./PasswordPage";

export interface SignupFormikValues {
  email: string;
  password: string;
  subscription?: SubscriptionType;
}

const SignupPage: React.FC<RouteComponentProps> = function ({
  history,
  location,
}) {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();

  const emailState = useAppSelector(selectRegistrationEmail);
  const passwordState = useAppSelector(selectRegistrationPassword);
  const subscriptionState = useAppSelector(
    selectRegistrationSubscriptionMethod
  );
  const initialValues = useMemo<SignupFormikValues>(
    () => ({
      email: emailState || "",
      password: passwordState || "",
      subscription: subscriptionState,
    }),
    [emailState, passwordState, subscriptionState]
  );
  const [socialLogin, setSocialLogin] = useState<boolean>(false);
  const [socialLoading, setSocialLoading] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      history.replace(APPLICATION_PATH);
    }
  }, []);

  const handleSignup = useCallback(
    (_, actions: FormikHelpers<SignupFormikValues>) => {
      return new Promise<void>((resolve, reject) => {
        signup(emailState, passwordState)
          .then(() => {
            actions.setSubmitting(false);
            resolve();
          })
          .catch((error: AxiosError) => {
            const { response } = error;
            dispatch(setSubmitError(response?.data.message));
            actions.setSubmitting(false);
            reject();
          });
      });
    },
    [emailState, passwordState]
  );

  const handleFacebookResponse = useCallback(
    (userInfo: ReactFacebookLoginInfo) => {
      facebookLogin(userInfo)
        .then(({ token, isNew }) => {
          if (token) {
            setSocialLoading(false);
            localStorage.setItem(ACCESS_TOKEN_KEY, token);
            localStorage.setItem(PROVIDER_TYPE_KEY, "facebook");
            if (isNew) {
              history.push(SIGNUP_SUBSCRIPTION_BENEFITS_PATH);
            } else {
              handleSocialBenefitSubmit();
            }
          } else {
            alert("Something went wrong!");
          }
        })
        .catch((error: AxiosError) => {
          const { response } = error;
          console.log("Error -> ", response);
        });
    },
    []
  );

  const handleGoogleResponse = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (response.code) {
        console.log("GoogleLoginResponseOffline -> ", response.code);
      } else {
        const { profileObj, tokenId } = response as GoogleLoginResponse;
        googleLogin(profileObj.email, tokenId)
          .then(({ token, isNew }) => {
            if (token) {
              setSocialLoading(false);
              localStorage.setItem(ACCESS_TOKEN_KEY, token);
              localStorage.setItem(PROVIDER_TYPE_KEY, "google");
              if (isNew) {
                history.push(SIGNUP_SUBSCRIPTION_BENEFITS_PATH);
              } else {
                handleSocialBenefitSubmit();
              }
            } else {
              alert("Something went wrong!");
            }
          })
          .catch((error: AxiosError) => {
            const { response } = error;
            console.log("Error -> ", response);
          });
      }
    },
    [location]
  );

  const handleSocialBenefitSubmit = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    history.replace(urlSearchParams.get("next") || APPLICATION_PATH);
  }, [location]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormikValidationSchema}
      onSubmit={handleSignup}
    >
      {(props) => (
        <WithLoadingBackdrop open={props.isSubmitting || socialLoading}>
          <Switch>
            <Route exact path={SIGNUP_PASSWORD_PATH} component={PasswordPage} />
            <Route
              exact
              path={SIGNUP_SUBSCRIPTION_BENEFITS_PATH}
              render={() => {
                if (socialLogin) {
                  return (
                    <BenefitsOverviewPage
                      onSubmitForm={handleSocialBenefitSubmit}
                    />
                  );
                } else {
                  return <BenefitsOverview />;
                }
              }}
            />
            {/* <Route
              exact
              path={SIGNUP_SUBSCRIPTIONS_PATH}
              component={SubscriptionsPage}
            /> */}
            {/* <Route
              exact
              path={SIGNUP_SUBSCRIPTION_OVERVIEW_PATH}
              component={SubscriptionOverviewPage}
            /> */}
            <Route exact path={path}>
              <EmailPage
                onFacebookSignup={handleFacebookResponse}
                onGoogleSignup={handleGoogleResponse}
                onFacebookRequest={() => {
                  setSocialLoading(true);
                  setSocialLogin(true);
                }}
                onGoogleRequest={() => {
                  setSocialLoading(true);
                  setSocialLogin(true);
                }}
              />
            </Route>
            <Redirect to={path} />
          </Switch>
        </WithLoadingBackdrop>
      )}
    </Formik>
  );
};

export default SignupPage;
