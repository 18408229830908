import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { changeUserPassword } from "../../../api/userAPI";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PROFILE_EDIT_PATH } from "../../../routes";
import { showNotification } from "../../../slices/notificationSlice";
import {
  setProfileUpdateFailed,
  setProfileUpdateStatus,
} from "../../../slices/usersSlice";
import { validatePassword } from "../../../validation";
import BackButton from "../BackButton";
import Logo from "../Logo";
import PasswordStrength from "../PasswordStrength";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));
const MobileEditPassword: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const profileUpdateStatus = useAppSelector(
    (state) => state.users.profileUpdateStatus
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const passwordStrengthErrors = useMemo(() => {
    return validationErrors.filter((el) => el !== "required");
  }, [validationErrors]);

  const handleSaveChanges = useCallback(() => {
    dispatch(setProfileUpdateStatus("password"));
    changeUserPassword(newPassword)
      .then((response) => {
        if (response.status === 200) {
          setNewPassword("");
          dispatch(setProfileUpdateStatus("idle"));
          dispatch(
            showNotification({
              text: "Паролата беше променена успешно!",
              type: "success",
            })
          );
          history.push(PROFILE_EDIT_PATH);
        }
      })
      .catch((error) => {
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        });
        dispatch(setProfileUpdateFailed());
      });
  }, [newPassword]);

  useEffect(() => {
    setValidationErrors(validatePassword(newPassword));
  }, [newPassword]);

  return (
    <React.Fragment>
      <BackButton />
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Logo size="md" />
          <Typography variant="h1" color="primary" align="center">
            Промени парола
          </Typography>
        </div>
        <div className={classes.contentContainer}>
          <TextField
            name="password"
            label="Въведи нова парола"
            variant="outlined"
            value={newPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewPassword(e.target.value)
            }
            InputProps={{
              inputProps: {
                type: showPassword ? "text" : "password",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLock} />
                </InputAdornment>
              ),
              endAdornment: newPassword ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowPassword((prevState) => !prevState);
                    }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <PasswordStrength errors={passwordStrengthErrors} />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={
            !newPassword ||
            !!passwordStrengthErrors.length ||
            profileUpdateStatus === "password"
          }
        >
          ЗАПАЗИ ПРОМЕНИТЕ
        </Button>
      </div>
    </React.Fragment>
  );
};

export default MobileEditPassword;
