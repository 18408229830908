import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

interface OwnProps {
  open: boolean;
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
}

const RemoveRouteConfirmationDialog: React.FC<OwnProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      onClose={(_, reson) => {
        if (reson !== "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" color="primary">
          Премахни маршрут
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Наистина ли искаш да премахнеш този маршрут от профила си?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Затвори
        </Button>
        <Button
          className={classes.confirmButton}
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          Премахни
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveRouteConfirmationDialog;
