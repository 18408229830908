import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useMemo } from "react";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ADD_PLACE_PATH,
  APPLICATION_ABOUT_US_PATH,
  APPLICATION_PATH,
  APP_PRIVACY_POLICY_PATH,
  APP_TERMS_AND_CONDITIONS_PATH,
  FEEDBACK_PATH,
  GENERATED_ROUTES_PATH,
  GENERATED_ROUTE_OVERVIEW_PATH,
  PLACES_PATH,
  PLACE_OVERVIEW_PATH,
  PROFILE_EDIT_PATH,
  PROFILE_PATH,
  REACTIVATE_USER_PATH,
  ROUTES_PATH,
  ROUTE_OVERVIEW_PATH,
} from "../../routes";
import { showNotification } from "../../slices/notificationSlice";
import { fetchCurrentUser, selectCurrentUser } from "../../slices/usersSlice";
import AboutUsPage from "../common/AboutUs";
import AddPlacePage from "../common/AddPlacePage";
import FeedbackPage from "../common/FeedbackPage";
import DesktopHeader from "../common/header/DesktopHeader";
import MobileHeader from "../common/header/MobileHeader";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Layout from "../common/Layout";
import ReactivateUserPage from "../common/ReactivateUserPage";
import GeneratedRoutesOverview from "../generatedRoutes";
import GeneratedRouteOverviewPage from "../generatedRoutes/OverviewPage";
import PlaceOverviewPage from "../PlaceOverviewPage";
import PlacesPage from "../PlacesPage";
import PrivacyPolicyPage from "../PrivacyPolicyPage";
import Profile from "../profile";
import SavedRoutesOverview from "../savedRoutes";
import SavedRouteOverviewPage from "../savedRoutes/OverviewPage";
import TermsAndConditionsPage from "../TermsAndConditionsPage";
import PageComponent from "./Page";

const styles = makeStyles((theme: Theme) => ({
  gridContainer: {
    height: "100%",
    overflow: "hidden",
  },
  gridBackground: {
    backgroundImage: "url(/mountains_background.png)",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  mobileLayer: {
    backgroundColor: "#f2f2f2",
  },
  desktopLayer: {
    backgroundColor: "#f2f2f213",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  desktopContainer: {
    padding: 0,
    margin: 0,
    overflow: "hidden",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorButton: {
    minWidth: 200,
  },
}));

const HomeComponent = ({ isMediaDown }: WithMediaDownProps) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector(selectCurrentUser);
  const status = useAppSelector((state) => state.users.status);

  const showMobileHeader = useMemo(() => {
    const result = user && isMediaDown;
    const isInHome = matchPath(location.pathname, {
      path: APPLICATION_PATH,
      exact: true,
      strict: false,
    })?.isExact;
    const isInProfile = matchPath(location.pathname, {
      path: PROFILE_PATH,
      exact: true,
      strict: false,
    })?.isExact;
    const isInEditProfile = matchPath(location.pathname, {
      path: PROFILE_EDIT_PATH,
      exact: true,
      strict: false,
    })?.isExact;

    return result && (isInHome || isInProfile || isInEditProfile);
  }, [location, isMediaDown, user]);

  useEffect(() => {
    if (user && !user.activated) {
      dispatch(
        showNotification({
          text: "Моля, активирай профила си чрез линка в имейла!",
          type: "warning",
          duration: 10000,
        })
      );
    }
  }, [user?.activated]);

  useEffect(() => {
    dispatch(fetchCurrentUser()).then((user) => {
      let userName = "N/A";
      if (user.firstName) {
        if (user.lastName) {
          userName = `${user.firstName} ${user.lastName}`;
        } else {
          userName = user.firstName;
        }
      }
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.email,
        $name: userName,
      });
      const userProperties = {
        Email: user.email,
        Activated: user.activated,
        Provider: user.providerType,
        "Has Subscription": user.hasSubscription,
      };
      mixpanel.register(userProperties);
    });
  }, []);

  if (user && !!user.deletedAt) {
    return (
      <Layout>
        <Switch>
          <Route path={REACTIVATE_USER_PATH} component={ReactivateUserPage} />
          <Redirect to={REACTIVATE_USER_PATH} />
        </Switch>
      </Layout>
    );
  }

  return (
    <Grid
      className={classNames(classes.gridContainer, {
        [classes.gridBackground]: !isMediaDown,
      })}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
    >
      <div
        className={classNames(
          classes.layer,
          { [classes.mobileLayer]: isMediaDown },
          { [classes.desktopLayer]: !isMediaDown }
        )}
      ></div>
      {!user && status === "loading" && <CircularProgress size={80} />}
      {!user && status === "failed" && (
        <Container maxWidth={false} className={classes.errorContainer}>
          <Typography variant="h2" color="primary" align="center">
            Нещо се обърка. Моля, опитай отново!
          </Typography>
          <Button
            className={classes.errorButton}
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(fetchCurrentUser());
            }}
          >
            Презареди
          </Button>
        </Container>
      )}
      {user && (
        <Container
          maxWidth={false}
          className={classNames(classes.container, {
            [classes.desktopContainer]: !isMediaDown,
          })}
        >
          {!isMediaDown && <DesktopHeader />}
          {user && user.activated ? (
            <Layout>
              <Switch>
                <Route
                  path={APPLICATION_ABOUT_US_PATH}
                  component={AboutUsPage}
                />
                <Route
                  path={PLACE_OVERVIEW_PATH}
                  component={PlaceOverviewPage}
                />
                <Route
                  path={GENERATED_ROUTE_OVERVIEW_PATH}
                  component={GeneratedRouteOverviewPage}
                />
                <Route
                  path={GENERATED_ROUTES_PATH}
                  component={GeneratedRoutesOverview}
                />
                <Route path={PROFILE_PATH} component={Profile} />
                <Route path={PLACES_PATH} component={PlacesPage} />
                <Route
                  path={ROUTE_OVERVIEW_PATH}
                  component={SavedRouteOverviewPage}
                />
                <Route path={ROUTES_PATH} component={SavedRoutesOverview} />
                <Route
                  exact
                  path={APPLICATION_PATH}
                  component={PageComponent}
                />
                <Route path={FEEDBACK_PATH} component={FeedbackPage} />
                <Route path={ADD_PLACE_PATH} component={AddPlacePage} />
                <Route
                  exact
                  path={APP_TERMS_AND_CONDITIONS_PATH}
                  component={TermsAndConditionsPage}
                />
                <Route
                  exact
                  path={APP_PRIVACY_POLICY_PATH}
                  component={PrivacyPolicyPage}
                />
                <Redirect to={APPLICATION_PATH} />
              </Switch>
            </Layout>
          ) : (
            <Layout>
              <Switch>
                <Route
                  exact
                  path={APPLICATION_PATH}
                  component={PageComponent}
                />
                <Route path={FEEDBACK_PATH} component={FeedbackPage} />
                <Route
                  exact
                  path={APP_TERMS_AND_CONDITIONS_PATH}
                  component={TermsAndConditionsPage}
                />
                <Route
                  exact
                  path={APP_PRIVACY_POLICY_PATH}
                  component={PrivacyPolicyPage}
                />
                <Route
                  path={APPLICATION_ABOUT_US_PATH}
                  component={AboutUsPage}
                />
                <Route path={PROFILE_PATH} component={Profile} />
                <Redirect to={APPLICATION_PATH} />
              </Switch>
            </Layout>
          )}
        </Container>
      )}
      {showMobileHeader && <MobileHeader />}
    </Grid>
  );
};

export default WithMediaDown(HomeComponent, "md");
