import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { showNotification } from "../../slices/notificationSlice";
import { selectCurrentUser } from "../../slices/usersSlice";
import { submitFeedback } from "../../slices/feedbackSlice";
import BackButton from "./BackButton";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";
import WithLoadingBackdrop from "./WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  fullHeight: {
    height: "100%",
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  inputElement: {
    borderColor: "#afa8a8",
  },
}));

const FeedbackPage: React.FC<WithMediaDownProps> = function ({ isMediaDown }) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (text: string) => {
    setFeedbackMessage(text);
  };

  const handleSendFeedback = () => {
    setLoading(true);
    dispatch(
      submitFeedback({
        context: "general",
        text: feedbackMessage,
        email: user?.email || "N/A",
        provider_type: user?.providerType || "local",
      })
    ).then(() => {
      setLoading(false);
      setFeedbackMessage("");
      dispatch(
        showNotification({
          type: "success",
          text: "Благодарим за обратната връзка!",
        })
      );
    });
  };

  return (
    <WithLoadingBackdrop open={loading}>
      <Container maxWidth="sm" disableGutters className={classes.fullHeight}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.fullHeight}
        >
          <Grid item xs={12}>
            {isMediaDown && <BackButton />}
            <Typography variant="h1" color="primary" align="center">
              Обратна връзка
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{ classes: { notchedOutline: classes.inputElement } }}
              variant="outlined"
              multiline
              fullWidth
              placeholder="Остави ни обратна връзка..."
              rows={15}
              value={feedbackMessage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value)
              }
            />
          </Grid>
          <Grid item xs className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSendFeedback}
              disabled={!feedbackMessage}
            >
              Изпрати
            </Button>
          </Grid>
        </Grid>
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(FeedbackPage, "md");
