import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LOGIN_PATH, SIGNUP_PATH } from "../../routes";
import { showFeedbackForm } from "../../slices/feedbackSlice";
import {
  resetRegistrationState,
  selectRegistrationEmail,
  selectRegistrationPassword,
  selectRegistrationSubscriptionMethod,
} from "../../slices/registrationSlice";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  logo: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  button: {
    marginBottom: theme.spacing() * 2,
    maxWidth: 400,
  },
}));

const SuccessfulSignupPage: React.FC<
  RouteComponentProps & WithMediaDownProps
> = function ({ history, isMediaDown }) {
  const classes = styles();
  const dispatch = useAppDispatch();

  const emailState = useAppSelector(selectRegistrationEmail);
  const passwordState = useAppSelector(selectRegistrationPassword);
  const subscriptionState = useAppSelector(
    selectRegistrationSubscriptionMethod
  );

  useEffect(() => {
    if (!emailState || !passwordState || !subscriptionState) {
      history.push(SIGNUP_PATH);
    } else {
      dispatch(showFeedbackForm("registration"));
    }
  }, [emailState, passwordState, subscriptionState]);

  return (
    <Container
      maxWidth={isMediaDown ? "xs" : "sm"}
      className={classes.container}
    >
      <div>
        <div className={classes.logo}>
          <Logo size={isMediaDown ? "md" : "lg"} />
        </div>
        <Typography variant="h1" color="primary" className={classes.title}>
          Линкът лети към твоята поща!
        </Typography>
      </div>
      <Typography variant="button" color="primary">
        Благодарим ти, че ни се довери!
        <br />
        До 2-3 минути ще получиш линк на твоя имейл адрес, с който ще можеш да
        активираш профила си.
      </Typography>
      <Button
        className={classes.button}
        onClick={() => {
          dispatch(resetRegistrationState());
          history.replace(LOGIN_PATH);
        }}
        variant="contained"
        color="primary"
        fullWidth
      >
        Влез
      </Button>
    </Container>
  );
};

export default WithMediaDown(SuccessfulSignupPage, "md");
