import { Avatar, IconButton, TextField, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Note from "../../entities/Note";
import {
  selectCommentsByPlaceId,
  selectPlaceById,
  submitPlaceComment,
} from "../../slices/placeSlice";
import {
  ensureUsersByIds,
  selectCurrentUser,
  selectUserById,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    width: 600,
    maxHeight: "90%",
    overflow: "hidden",
  },
  mobileContainer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  backdrop: {
    minWidth: 300,
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  avatar: {
    backgroundColor: "unset",
    marginRight: theme.spacing(),
  },
  addCommentContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(),
  },
  commentsContainer: {
    overflow: "auto",
  },
  closeButton: {
    width: "fit-content",
    alignSelf: "flex-end",
  },
}));

const commentRowStyle = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing()}px 0`,
    borderBottom: `1px solid #0000001F`,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    backgroundColor: "unset",
    marginRight: theme.spacing(),
  },
}));

interface OwnProps {
  open: boolean;
  placeId: string;
  onleBackdropClose: () => void;
}

type ComponentProps = OwnProps & WithMediaDownProps;

const PlaceCommentsBackdrop: React.FC<ComponentProps> = function ({
  open,
  placeId,
  isMediaDown,
  onleBackdropClose,
}) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const place = useAppSelector((state) => selectPlaceById(state, placeId));
  const notes = useAppSelector((state) =>
    selectCommentsByPlaceId(state, placeId)
  );

  const [comment, setComment] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const authorIds = notes.map((note) => note.author);
    dispatch(ensureUsersByIds(authorIds));
  }, [place]);

  const handleSubmitComment = useCallback(() => {
    setSubmitting(true);
    dispatch(submitPlaceComment(placeId, comment))
      .then(() => {
        setSubmitting(false);
        setComment("");
      })
      .catch(() => {
        setSubmitting(false);
      });
  }, [comment]);

  return (
    <Backdrop disableStrictModeCompat className={classes.backdrop} open={open}>
      <div
        className={classNames(classes.container, {
          [classes.mobileContainer]: isMediaDown,
        })}
      >
        <IconButton
          className={classes.closeButton}
          size="small"
          onClick={onleBackdropClose}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.commentsContainer}>
          {!notes.length && (
            <Typography variant="h5" color="primary" align="center">
              Все още няма оставени коментари
            </Typography>
          )}
          {notes.map((note) => (
            <PlaceCommetRow key={note.id} note={note} />
          ))}
        </div>
        <div className={classes.addCommentContainer}>
          <Avatar
            alt={user ? user.firstName : "firstName"}
            src={user ? user.profilePicture : ""}
            className={classNames(classes.avatar)}
          >
            {(!user || !user.profilePicture) && (
              <AccountCircleSharpIcon color="primary" fontSize="large" />
            )}
          </Avatar>
          <TextField
            label="Напиши коментар"
            disabled={submitting}
            variant="outlined"
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            multiline
            minRows={2}
            fullWidth
          />
        </div>
        {!!comment && (
          <Button
            disabled={submitting}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmitComment}
          >
            {submitting ? "Изпращане..." : "Изпрати"}
          </Button>
        )}
      </div>
    </Backdrop>
  );
};

const PlaceCommetRow: React.FC<{ note: Note.Type }> = function ({
  note: { author, text, createdAt },
}) {
  const classes = commentRowStyle();
  const fetching = useAppSelector((state) => state.users.fetching);
  const user = useAppSelector((state) => selectUserById(state, author));
  return (
    <div className={classes.container}>
      {fetching[author] ? (
        <CircularProgress className={classNames(classes.avatar)} />
      ) : (
        <Avatar
          alt={user ? user.firstName : "firstName"}
          src={user ? user.profilePicture : ""}
          className={classNames(classes.avatar)}
        >
          {(!user || !user.profilePicture) && (
            <AccountCircleSharpIcon color="primary" fontSize="large" />
          )}
        </Avatar>
      )}
      <div className={classNames(classes.textContainer)}>
        <div className={classes.headerContainer}>
          <Typography variant="button" color="textPrimary">
            {user
              ? `${user.firstName} ${user.lastName ? user.lastName : ""}`
              : "..."}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {createdAt.fromNow()}
          </Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default WithMediaDown(PlaceCommentsBackdrop, "sm");
