import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";

const styles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: "1rem",
  },
  backButton: {
    width: "fit-content",
  },
  mobileLabel: {
    marginLeft: theme.spacing(),
  },
  mobileButton: {
    alignSelf: "flex-start",
  },
}));

interface OwnProps {
  label?: string;
  onBackClick?: () => void;
}

type ComponentProps = OwnProps & RouteComponentProps & WithMediaDownProps;

const BackButton: React.FC<ComponentProps> = ({
  onBackClick,
  label,
  history,
  isMediaDown,
}) => {
  const classes = styles();

  if (isMediaDown) {
    return (
      <IconButton
        className={classNames(classes.backButton, classes.mobileButton)}
        color="primary"
        onClick={() => {
          if (onBackClick) {
            onBackClick();
          }
          history.goBack();
        }}
      >
        <FontAwesomeIcon className={classes.icon} icon={faChevronLeft} />
        {!!label && (
          <Typography
            className={classes.mobileLabel}
            color="primary"
            variant="h5"
            align="left"
          >
            {label}
          </Typography>
        )}
      </IconButton>
    );
  }
  return (
    <Button
      className={classes.backButton}
      onClick={() => {
        if (onBackClick) {
          onBackClick();
        }
        history.goBack();
      }}
      variant="outlined"
      color="primary"
      startIcon={
        <FontAwesomeIcon className={classes.icon} icon={faChevronLeft} />
      }
    >
      Назад
    </Button>
  );
};

export default withRouter(WithMediaDown(BackButton, "md"));
