import axios, { AxiosError } from "axios";
import mixpanel from "mixpanel-browser";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { store } from "../app/store";
import { baseApiUrl } from "../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../constants";
import { LOGIN_PATH } from "../routes";
import { showNotification } from "../slices/notificationSlice";
import { setCurrentUser } from "../slices/usersSlice";

export type SocialProfileType = "facebook" | "google";

let API_REQUEST_INTERCEPTOR_ID: number;
let API_RESPONSE_INTERCEPTOR_ID: number;

axios.defaults.baseURL = `${baseApiUrl}/api`;

function interceptApiRequest() {
  return axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

function interceptApiResponse() {
  return axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { response } = error;
      const urlApiIndex = response ? response.config.url.indexOf("/api") : 0;

      if (response && urlApiIndex !== 0) {
        if (response.status === 401) {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(PROVIDER_TYPE_KEY);
          store.dispatch(setCurrentUser({ user: undefined }));
          mixpanel.track("LOGOUT_UNAUTHORIZED");
          mixpanel.reset();
          window.location.assign(
            `${LOGIN_PATH}?next=${encodeURI(window.location.pathname)}`
          );
        } else {
          store.dispatch(
            showNotification({ text: response.data.message, type: "error" })
          );
        }
      } else {
        store.dispatch(
          showNotification({
            text: "Нещо се обърка. Моля, опитай отново по-късно!",
            type: "error",
          })
        );
      }
      return Promise.reject(error);
    }
  );
}

export const setInterceptApiRequest = function () {
  if (API_REQUEST_INTERCEPTOR_ID === undefined) {
    API_REQUEST_INTERCEPTOR_ID = interceptApiRequest();
  }
};

export const setInterceptApiResponse = function () {
  if (API_RESPONSE_INTERCEPTOR_ID === undefined) {
    API_RESPONSE_INTERCEPTOR_ID = interceptApiResponse();
  }
};

export const login = function (
  email: string,
  password: string
): Promise<{ token: string }> {
  return new Promise<{ token: string }>((resolve, reject) => {
    axios
      .post("/login", { email: email.toLocaleLowerCase(), password })
      .then(({ data }) => {
        if (!data.token) {
          store.dispatch(
            showNotification({
              text: "Нещо се обърка. Моля, опитай отново по-късно!",
              type: "error",
            })
          );
          reject();
        } else {
          resolve({ token: data.token });
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          store.dispatch(
            showNotification({
              text: error.response.data.message,
              type: "error",
            })
          );
        }
        reject(error);
      });
  });
};

export const signup = function (
  email: string,
  password: string
): Promise<void> {
  // TBD
  return new Promise<void>((resolve, reject) => {
    axios
      .post("/signup", { email: email.toLocaleLowerCase(), password })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkSignupEmailExisting = function (
  email: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .get(`/signup/email-existing/?email=${email}`)
      .then(() => {
        resolve();
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          store.dispatch(
            showNotification({
              text: error.response.data.message,
              type: "error",
            })
          );
        }
        reject(error);
      });
  });
};

export const checkResetPasswordTokenExisting = function (
  token: string
): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .get(`/password-reset/token-validate/?token=${token}`)
      .then(({ data }) => {
        resolve(data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const googleLogin = function (
  email: string,
  token: string
): Promise<{ token: string; isNew: boolean }> {
  return new Promise<{ token: string; isNew: boolean }>((resolve, reject) => {
    axios
      .post("/signup/google", { email, token })
      .then(({ data }) => {
        resolve({ token: data.token, isNew: data.is_new });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const facebookLogin = function (
  userInfo: ReactFacebookLoginInfo
): Promise<{ token: string; isNew: boolean }> {
  const body = {
    email: userInfo.email,
    profile_picture: userInfo.picture?.data.url,
    first_name: (userInfo as any).first_name,
    last_name: (userInfo as any).last_name,
    token: userInfo.accessToken,
  };
  return new Promise<{ token: string; isNew: boolean }>((resolve, reject) => {
    axios
      .post("/signup/facebook", body)
      .then(({ data }) => {
        resolve({ token: data.token, isNew: data.is_new });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
