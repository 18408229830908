// export const PLATFORM_BENEFITS = [
//   "да генерирате случайни маршрути",
//   "да маркирате обекти като любими и посетени",
//   "да филтрирате обектите по избрани от вас критерии",
// ];

export const PLATFORM_BENEFITS = [
  "да генерирате маршрут около избрана от вас точка",
  "да генерирате маршрут, съдържащ избрани от вас обекти",
  "да генерирате случайни маршрути",
  "да запаметявате генерираните маршрути",
  "да маркирате обекти като любими и посетени",
  "да филтрирате обектите по избрани от вас критерии",
  "да оставяте коментари за избрани от вас обекти",
];

export const SUBSCRIPTION_BENEFITS = [
  "генерирате маршрут около избрана от вас точка",
  "генерирате маршрут, съдържащ избрани от вас обекти",
  "запаметявате вашите маршрути",
];

export type SubscriptionType = "free" | "monthly" | "single" | "multiple";

export interface SubscriptionDefinition {
  type: SubscriptionType;
  title: string;
  price: number;
  benefits: string[];
}

export const freeSubscription: SubscriptionDefinition = {
  type: "free",
  title: "Безплатен",
  price: 0,
  benefits: ["1 месец безплатен абонамент", ...SUBSCRIPTION_BENEFITS],
};

export const monthlySubscription: SubscriptionDefinition = {
  type: "monthly",
  title: "Месечен",
  price: 4.99,
  benefits: ["1 месец безплатен абонамент", ...SUBSCRIPTION_BENEFITS],
};

export const singleSubscription: SubscriptionDefinition = {
  type: "single",
  title: "Еднократен",
  price: 1.99,
  benefits: [
    "възможност за еднократно генериране на маршрути",
    ...SUBSCRIPTION_BENEFITS,
  ],
};

export const multipleSubscription: SubscriptionDefinition = {
  type: "multiple",
  title: "Петкратен",
  price: 4.99,
  benefits: [
    "възможност за 5 генерирания на маршрути ",
    ...SUBSCRIPTION_BENEFITS,
  ],
};

export const getAllSubscriptions = function (
  withFree?: boolean
): SubscriptionType[] {
  const result = ["monthly", "single", "multiple"] as SubscriptionType[];
  if (withFree) {
    return ["free", ...result];
  }
  return result;
};

export const getSubscriptionByType = function (type: SubscriptionType) {
  switch (type) {
    case "free":
      return freeSubscription;
    case "monthly":
      return monthlySubscription;
    case "single":
      return singleSubscription;
    case "multiple":
      return multipleSubscription;
  }
};
