import moment from "moment";

export namespace Note {
  export interface Type {
    id: string;
    text: string;
    author: string;
    createdAt: moment.Moment;
    deletedAt?: moment.Moment;
  }

  export const parse = function (data: any): Note.Type {
    return {
      id: data.uuid,
      text: data.text,
      author: data.author.uuid,
      createdAt: moment(data.created_at),
      deletedAt: data.deleted_at ? moment(data.deleted_at) : undefined,
    };
  };
}

export default Note;
