import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { generateGoogleMapsLink } from "../../googleMapsUtils";
import {
  APPLICATION_PATH,
  GENERATED_ROUTES_PATH,
  ROUTES_PATH,
} from "../../routes";
import { selectPlaceByIds } from "../../slices/placeSlice";
import { markRouteAsSave, setGeneratedRoutes } from "../../slices/routeSlice";
import { saveRoute, selectCurrentUser } from "../../slices/usersSlice";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import PlaceOverviewCard from "../common/PlaceOverviewCard";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  gridContainer: {
    overflow: "auto",
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  gridItem: {
    minWidth: "calc(100% - 16px)",
  },
  mobileButtonSpace: {
    marginTop: theme.spacing(),
  },
  desktopButtonSpace: {
    marginLeft: theme.spacing(),
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "0 auto",
  },
  desktopHeader: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(),
  },
  desktopTitle: {
    flexBasis: "65%",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing()}px 0`,
  },
}));

type ComponentProps = RouteComponentProps<{ id: string }> & WithMediaDownProps;

const RouteOverviewPage: React.FC<ComponentProps> = ({
  match,
  isMediaDown,
  history,
}) => {
  const classes = styles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const route = useAppSelector((state) =>
    state.routes.generatedRoutes.find((el) => el.id === match.params.id)
  );
  const places = useAppSelector((state) =>
    route ? selectPlaceByIds(state, route.places) : []
  );
  // const [
  //   generateConfirmationOpen,
  //   setGenerateConfirmationOpen,
  // ] = useState<boolean>(false);

  const handleSaveRoute = useCallback(() => {
    if (route) {
      const urlSearchParams = new URLSearchParams(location.search);
      const isRandom =
        urlSearchParams.has("random") && Boolean(urlSearchParams.get("random"));
      // if (
      //   isRandom &&
      //   !generateConfirmationOpen &&
      //   user &&
      //   !user.subscriptionExpirationDate
      // ) {
      //   setGenerateConfirmationOpen(true);
      //   return;
      // }
      // setGenerateConfirmationOpen(false);
      setLoading(true);
      dispatch(saveRoute(route.id, isRandom))
        .then(() => {
          if (!isRandom) {
            setLoading(false);
            dispatch(markRouteAsSave(route.id));
            history.push(GENERATED_ROUTES_PATH);
          } else {
            dispatch(setGeneratedRoutes([]));
            history.replace(ROUTES_PATH);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [route, location, user]);

  const handleLoadOnMap = useCallback(() => {
    window.open(generateGoogleMapsLink(places), "_blank");
  }, [places]);

  const buttonContainer = useMemo(
    () => (
      <div className={classNames({ [classes.footer]: isMediaDown })}>
        {!route?.isSaved && (
          <Button variant="contained" color="primary" onClick={handleSaveRoute}>
            ЗАПАЗИ МАРШРУТ
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleLoadOnMap}
          className={classNames(
            { [classes.mobileButtonSpace]: isMediaDown },
            { [classes.desktopButtonSpace]: !isMediaDown }
          )}
        >
          ПОКАЖИ НА КАРТАТА
        </Button>
      </div>
    ),
    [route, isMediaDown]
  );

  if (!route) {
    return <Redirect to={APPLICATION_PATH} />;
  }
  return (
    <WithLoadingBackdrop open={loading}>
      <Container className={classes.container} maxWidth="lg" disableGutters>
        <div
          className={classNames(classes.header, {
            [classes.desktopHeader]: !isMediaDown,
          })}
        >
          {isMediaDown && <BackButton label={route.title} />}
          <React.Fragment>
            {!isMediaDown && (
              <Typography
                className={classes.desktopTitle}
                variant="h3"
                color="primary"
                align="left"
              >
                {route.title}
              </Typography>
            )}
            {!isMediaDown && buttonContainer}
          </React.Fragment>
        </div>
        <Grid
          className={classes.gridContainer}
          container
          spacing={1}
          wrap={isMediaDown ? "nowrap" : "wrap"}
        >
          {route.places.map((placeId) => {
            return (
              <Grid
                key={placeId}
                item
                xs={6}
                lg={12}
                className={isMediaDown ? classes.gridItem : ""}
              >
                <PlaceOverviewCard
                  variant={isMediaDown ? "vertical" : "horizontal"}
                  placeId={placeId}
                />
              </Grid>
            );
          })}
        </Grid>
        {isMediaDown && buttonContainer}
      </Container>
      {/* <RouteConfirmationDialog
        open={generateConfirmationOpen}
        title="Запази маршрут"
        onClose={() => setGenerateConfirmationOpen(false)}
        onConfirm={handleSaveRoute}
      /> */}
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(RouteOverviewPage, "md");
