import { Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const getCardMediaProperties = (theme: Theme): CSSProperties => ({
  position: "relative",
  width: "100%",
  color: theme.palette.common.white,
});

const getCardHeaderProperties = (theme: Theme): CSSProperties => ({
  backgroundColor: `${theme.palette.primary.main}80`,
});

const getCardActionProperties = (theme: Theme): CSSProperties => ({
  color: theme.palette.common.white,
  alignSelf: "center",
  margin: 0,
});

const getCardRootProperties = (theme: Theme): CSSProperties => ({
  display: "flex",
  width: "100%",
  position: "relative",
  margin: theme.spacing(),
  maxWidth: 600,
});

const getMobileCardRootProperties = (theme: Theme): CSSProperties => ({
  margin: `${theme.spacing()}px auto`,
});

const getSeeAccentContainerProperties = (theme: Theme): CSSProperties => ({
  position: "absolute",
  right: "2%",
  bottom: 0,
  marginBottom: theme.spacing(2),
  backgroundColor: `${theme.palette.primary.main}80`,
  borderRadius: theme.spacing() / 2,
});

const getLoadingProperties = (theme: Theme): CSSProperties => ({
  alignSelf: "center",
  margin: "0 auto",
});

export const getCommonCardStyles = (theme: Theme) => ({
  cardMedia: getCardMediaProperties(theme),
  cardRoot: getCardRootProperties(theme),
  cardHeader: getCardHeaderProperties(theme),
  cardAction: getCardActionProperties(theme),
  mobileCardRoot: getMobileCardRootProperties(theme),
  seeAccentContainer: getSeeAccentContainerProperties(theme),
  loading: getLoadingProperties(theme),
});
