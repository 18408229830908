import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import React, { useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  changeUserProfilePicture,
  selectCurrentUser,
} from "../../slices/usersSlice";

const styles = makeStyles((theme: Theme) => ({
  avatarIcon: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    width: "100%",
    height: "100%",
    border: `${theme.spacing() / 2}px solid ${theme.palette.primary.main}`,
    backgroundColor: "#00000000",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const UploadImageAvatar: React.FC = () => {
  const user = useAppSelector(selectCurrentUser);
  const profileUpdateStatus = useAppSelector(
    (state) => state.users.profileUpdateStatus
  );
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const classes = styles();
  const handleChangeImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        dispatch(changeUserProfilePicture(event.target.files[0]));
      }
    },
    []
  );
  return (
    <React.Fragment>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={handleChangeImage}
      />
      <Avatar
        alt={user ? user.firstName : "firstName"}
        src={user ? user.profilePicture : ""}
        className={classes.avatar}
        onClick={() => {
          inputFile.current && inputFile.current.click();
        }}
      >
        {(!user || !user.profilePicture) && (
          <AccountCircleSharpIcon
            color="action"
            className={classes.avatarIcon}
          />
        )}
        {profileUpdateStatus === "profile_picture" && <CircularProgress />}
      </Avatar>
    </React.Fragment>
  );
};

export default UploadImageAvatar;
