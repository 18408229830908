import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_API_KEY } from "../../config";
import { PLACE_OVERVIEW_PATH, ROUTE_OVERVIEW_PATH } from "../../routes";
import { selectPlaceByIds } from "../../slices/placeSlice";
import {
  changeRouteStatus,
  removeRoute,
  selectSavedRouteById,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import RemoveRouteConfirmationDialog from "../common/RemoveRouteConfirmationDialog";
import RouteCompleteConfirmationDialog from "../common/RouteCompleteConfirmationDialog";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  cardContent: {
    display: "flex",
    alignItems: "center",
    minHeight: 100,
    padding: theme.spacing(),
    "&:last-child": {
      padding: theme.spacing(),
    },
  },
  cardHeader: {
    padding: `${theme.spacing()}px ${theme.spacing(
      2
    )}px 0 ${theme.spacing()}px`,
  },
  cardHeaderContentCompleted: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderContent: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  routeTitle: {
    width: "100%",
  },
  placeContainer: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
  },
  placeCardRoot: {
    flex: 1,
    color: theme.palette.common.white,
    margin: `0 ${theme.spacing() / 2}px`,
    minWidth: 240,
  },
  placeCardMedia: {
    minHeight: 250,
  },
  placeCardHeader: {
    backgroundColor: `${theme.palette.primary.main}80`,
  },
  placeCardHeaderContent: {
    overflow: "hidden",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 120,
    margin: `0 ${theme.spacing() / 2}px`,
  },
  actionButton: {
    display: "flex",
    flexDirection: "column",
  },
  map: {
    flex: 1,
    minHeight: 250,
  },
  completedLabel: {
    margin: `0 ${theme.spacing()}px`,
  },
  loading: {
    width: "100%",
    height: theme.spacing(),
  },
}));

interface OwnProps {
  routeId: string;
}

const MobileCard: React.FC<OwnProps> = ({ routeId }) => {
  const classes = styles();
  const mapRef = createRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const savedRoute = useAppSelector((state) =>
    selectSavedRouteById(state, routeId)
  );
  const route = useAppSelector((state) =>
    savedRoute ? state.routes.byId[savedRoute.routeId] : null
  );
  const user = useAppSelector((state) => state.users.me);
  const places = useAppSelector((state) =>
    savedRoute && route ? selectPlaceByIds(state, route.places) : []
  );

  const isCompletedRoute = useMemo(() => {
    return (
      savedRoute &&
      savedRoute.status === "completed" &&
      !!savedRoute.completedDate
    );
  }, [savedRoute]);

  const [loading, setLoading] = useState<boolean>(false);
  const [removeConfirmationDialogOpen, setRemoveConfirmationDialogOpen] =
    useState<boolean>(false);

  const [visitAllPlacesDialogOpen, setVisitAllPlacesDialogOpen] =
    useState<boolean>(false);

  const handleRemoveRoute = useCallback(() => {
    if (savedRoute) {
      setRemoveConfirmationDialogOpen(false);
      setLoading(true);
      dispatch(removeRoute(savedRoute.id)).then(() => {
        setLoading(false);
      });
    }
  }, [savedRoute]);

  const handleNotCompleteRoute = useCallback(() => {
    if (savedRoute) {
      setLoading(true);
      dispatch(changeRouteStatus(savedRoute.id, "not_started", false)).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [savedRoute]);

  const handleCompleteRoute = useCallback(
    (visitAllPlaces: boolean) => {
      if (savedRoute) {
        setLoading(true);
        dispatch(changeRouteStatus(savedRoute.id, "completed", visitAllPlaces))
          .then(() => {
            setLoading(false);
            setVisitAllPlacesDialogOpen(false);
          })
          .catch(() => {
            setLoading(false);
            setVisitAllPlacesDialogOpen(false);
          });
      }
    },
    [savedRoute]
  );

  const handleRouteStatusChange = useCallback(() => {
    let areAllVisited = false;
    if (isCompletedRoute) {
      handleNotCompleteRoute();
    } else if (route && user) {
      areAllVisited = route.places.every((el) =>
        user.visitedPlaces.includes(el)
      );
      if (areAllVisited) {
        handleCompleteRoute(false);
      } else {
        setVisitAllPlacesDialogOpen(true);
      }
    }
    mixpanel.track("ROUTE_STATUS_CHANGE", {
      From: "Desktop Card",
      "Mark As": isCompletedRoute ? "Not Completed" : "Completed",
      "All visited": areAllVisited,
    });
  }, [user, user?.visitedPlaces, route, route?.places, isCompletedRoute]);

  const handlePreviewRoute = useCallback(() => {
    if (savedRoute) {
      history.push(ROUTE_OVERVIEW_PATH.replace(":id", savedRoute.id));
    }
  }, [savedRoute]);

  useEffect(() => {
    if (mapRef && mapRef.current && places.length) {
      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: places[0].location.latitude,
          lng: places[0].location.longitude,
        },
        zoom: 10,
      });
      places.forEach((place) => {
        new google.maps.Marker({
          position: {
            lat: place.location.latitude,
            lng: place.location.longitude,
          },
          map,
        });
      });
    }
  }, [mapRef.current, places]);

  return (
    <WithLoadingBackdrop open={loading}>
      <Card>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            content: classNames(classes.cardHeaderContent, {
              [classes.cardHeaderContentCompleted]: isCompletedRoute,
            }),
          }}
          title={
            <Button
              onClick={handlePreviewRoute}
              variant="text"
              color="primary"
              fullWidth
            >
              <Typography
                className={classes.routeTitle}
                variant="h5"
                noWrap
                align="left"
              >
                {savedRoute ? savedRoute.title : "..."}
              </Typography>
            </Button>
          }
          subheader={
            isCompletedRoute ? (
              <Typography
                className={classes.completedLabel}
                variant="body1"
                color="primary"
              >
                Завършен на:{" "}
                {savedRoute &&
                  savedRoute.completedDate &&
                  savedRoute.completedDate.format("DD MMMM YYYY")}
              </Typography>
            ) : null
          }
        />
        <CardContent className={classes.cardContent}>
          {savedRoute && route && places.length ? (
            <React.Fragment>
              <div className={classes.map} ref={mapRef} />
              <div className={classes.placeContainer}>
                {places.map((place) => (
                  <Card
                    key={place.id}
                    className={classes.placeCardRoot}
                    onClick={() => {
                      history.push(
                        PLACE_OVERVIEW_PATH.replace(":id", place.id)
                      );
                    }}
                  >
                    <CardMedia
                      className={classes.placeCardMedia}
                      image={place.coverPhoto}
                    >
                      <CardHeader
                        classes={{
                          root: classes.placeCardHeader,
                          content: classes.placeCardHeaderContent,
                        }}
                        title={
                          <Typography variant="h5" noWrap>
                            {place.name}
                          </Typography>
                        }
                      />
                    </CardMedia>
                  </Card>
                ))}
              </div>
              <div className={classes.actionContainer}>
                <Button
                  classes={{ label: classes.actionButton }}
                  onClick={handleRouteStatusChange}
                >
                  <LocationOnIcon color="primary" />
                  <Typography variant="body1" color="primary">
                    Oтбележи като {isCompletedRoute ? "непосетен" : "посетен"}
                  </Typography>
                </Button>
                <Button
                  classes={{ label: classes.actionButton }}
                  onClick={() => setRemoveConfirmationDialogOpen(true)}
                >
                  <CloseIcon color="error" />
                  <Typography variant="body1" color="error">
                    Изтрий маршрут
                  </Typography>
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <LinearProgress className={classes.loading} />
          )}
        </CardContent>
      </Card>
      <RouteCompleteConfirmationDialog
        open={visitAllPlacesDialogOpen}
        onConfirm={() => {
          handleCompleteRoute(true);
        }}
        onClose={() => {
          handleCompleteRoute(false);
          setVisitAllPlacesDialogOpen(false);
        }}
      />
      <RemoveRouteConfirmationDialog
        open={removeConfirmationDialogOpen}
        onConfirm={handleRemoveRoute}
        onClose={() => {
          setRemoveConfirmationDialogOpen(false);
        }}
      />
    </WithLoadingBackdrop>
  );
};

export default MobileCard;
