import Grid from "@material-ui/core/Grid";
import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  PROFILE_EDIT_PASSWORD_PATH,
  PROFILE_EDIT_PATH,
  PROFILE_PATH,
} from "../../routes";
import BackButton from "../common/BackButton";
import MobileEditPassword from "../common/editProfile/MobileEditPassword";
import MobileEditProfile from "../common/editProfile/MobileEditProfile";
import ProfileOverview from "./ProfileOverview";

const MobileProfile: React.FC = () => {
  return (
    <Switch>
      <Route exact path={PROFILE_PATH}>
        <div>
          <Grid container alignItems="center">
            <BackButton label="Моят профил" />
          </Grid>
          <ProfileOverview />
        </div>
      </Route>
      <Route exact path={PROFILE_EDIT_PATH} component={MobileEditProfile} />
      <Route
        exact
        path={PROFILE_EDIT_PASSWORD_PATH}
        component={MobileEditPassword}
      />
    </Switch>
  );
};

export default MobileProfile;
