import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect } from "react";
import { useGoogleLogout } from "react-google-login";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_CLIENT_ID } from "../../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../constants";
import { APPLICATION_PATH, BASE_PATH } from "../../routes";
import {
  activateProfile,
  selectCurrentUser,
  setCurrentUser,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
  },
  actionContainer: {
    width: "100%",
  },
  button: {
    margin: `${theme.spacing()}px 0`,
    maxWidth: 400,
  },
}));

const SuccessfulSignupPage: React.FC<
  RouteComponentProps & WithMediaDownProps
> = function ({ history, isMediaDown }) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (user && !user.deletedAt) {
      history.replace(APPLICATION_PATH);
    }
  }, []);
  const { signOut } = useGoogleLogout({
    onLogoutSuccess: () => {
      console.log("Logout Sucess!");
    },
    onFailure: () => {
      console.log("Logout Failed!");
    },
    clientId: GOOGLE_CLIENT_ID || "",
  });
  const handleReactivateProfile = useCallback(() => {
    dispatch(activateProfile()).then(() => {
      history.replace(APPLICATION_PATH);
    });
  }, []);
  const handleLogout = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(PROVIDER_TYPE_KEY);
    dispatch(setCurrentUser({ user: undefined }));
    if (user?.providerType === "google") {
      signOut();
    }
    mixpanel.track("LOGOUT_DEACTIVATED");
    mixpanel.reset();
    history.replace(BASE_PATH);
  }, [user]);
  return (
    <Container
      maxWidth={isMediaDown ? "xs" : "sm"}
      className={classes.container}
    >
      <Logo size={isMediaDown ? "md" : "lg"} />
      <Typography variant="h6" color="primary">
        Профилът ти е бил деактивиран!
        <br />
        За да продължиш да използваш приложението, моля да го активираш отново.
      </Typography>
      <div className={classes.actionContainer}>
        <Button
          className={classes.button}
          onClick={handleReactivateProfile}
          variant="contained"
          color="primary"
          fullWidth
        >
          Активирай
        </Button>
        <Button
          className={classes.button}
          onClick={handleLogout}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Изход
        </Button>
      </div>
    </Container>
  );
};

export default WithMediaDown(SuccessfulSignupPage, "md");
