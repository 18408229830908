import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import {
  fetchRandomRoute as fetchRandomRouteApi,
  fetchRoutesByIds as fetchRoutes,
  generateOwnRoute as generateOwnRouteApi,
  generateRoutes as generateRoutesApi,
  GenerateRoutesConfig,
} from "../api/routeAPI";
import { AppState, AppThunk } from "../app/store";
import Route from "../entities/Route";
import { ensurePlacesByIds } from "./placeSlice";
import { fetchCurrentUser } from "./usersSlice";

export interface GeneratedRoute extends Route.Type {
  isSaved?: boolean;
}

export interface RouteSlice {
  byId: { [key: string]: Route.Type };
  generatedRoutes: GeneratedRoute[];
  fetching: { [key: string]: boolean };
  status: "idle" | "loading" | "failed";
}

type FetchRoutesPayload = { routes: Route.Type[] };
type FetchInProgress = { ids: string[] };

const initialState: RouteSlice = {
  byId: {},
  generatedRoutes: [],
  fetching: {},
  status: "idle",
};

export const ensureRoutesByIds =
  (ids: string[]): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    const routesState = getState().routes;
    const routesToFetch = ids.filter((id) => {
      const route = routesState.byId[id];
      const isFetching = routesState.fetching[id];
      return !route && !isFetching;
    });
    if (routesToFetch.length) {
      return dispatch(fetchRoutesByIds(routesToFetch));
    } else {
      const placesToFetch: string[] = [];
      const routes = routesToFetch.map((id) => routesState.byId[id]);
      routes.forEach((el) => {
        placesToFetch.push(...el.places);
      });
      dispatch(ensurePlacesByIds(placesToFetch)).then(() => {
        dispatch(setRoutes({ routes }));
      });
    }
    return Promise.resolve();
  };

export const fetchRoutesByIds =
  (ids: string[]): AppThunk<Promise<void>> =>
  (dispatch, _) => {
    dispatch(setFetchingInProgress({ ids }));
    return fetchRoutes(ids)
      .then((routes) => {
        const placesToFetch: string[] = [];
        routes.forEach((el) => {
          placesToFetch.push(...el.places);
        });
        dispatch(ensurePlacesByIds(placesToFetch)).then(() => {
          dispatch(setRoutes({ routes }));
        });
      })
      .catch((error) => {
        dispatch(setFetchingFailed({ ids }));
      });
  };

export const fetchRandomRoute = (): AppThunk<Promise<string>> => (dispatch) => {
  return new Promise<string>((resolve, reject) => {
    fetchRandomRouteApi()
      .then((route) => {
        dispatch(setGeneratedRoutes([route]));
        dispatch(ensurePlacesByIds(route.places))
          .then(() => {
            resolve(route.id);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject();
      });
  });
};

export const generateRoutes =
  (config: GenerateRoutesConfig): AppThunk<Promise<void>> =>
  (dispatch) => {
    return new Promise<void>((resolve, reject) => {
      generateRoutesApi(config)
        .then((routes) => {
          mixpanel.track("GENERATE_ROUTE_SEARCH", {
            location: {
              latitude: config.latitude,
              longitude: config.longitude,
              name: config.name,
            },
          });
          const placeIds: string[] = [];
          routes.forEach((el) => {
            placeIds.push(...el.places);
          });
          dispatch(ensurePlacesByIds(placeIds)).then(() => {
            dispatch(setGeneratedRoutes(routes));
            dispatch(fetchCurrentUser());
            resolve();
          });
        })
        .catch((error) => reject(error));
    });
  };

export const generateOwnRoute =
  (routeIds: string[], optimizeRoute: boolean): AppThunk<Promise<string>> =>
  (dispatch) => {
    return new Promise<string>((resolve, reject) => {
      generateOwnRouteApi(routeIds, optimizeRoute)
        .then((route) => {
          dispatch(setGeneratedRoutes([route]));
          dispatch(ensurePlacesByIds(route.places))
            .then(() => {
              dispatch(fetchCurrentUser());
              resolve(route.id);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject();
        });
    });
  };

export const routeSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRoutes: (state, action: PayloadAction<FetchRoutesPayload>) => {
      const { routes } = action.payload;
      routes.forEach((route) => {
        state.byId[route.id] = { ...route };
        state.fetching[route.id] = false;
      });
      state.status = "idle";
    },
    setGeneratedRoutes: (state, action: PayloadAction<GeneratedRoute[]>) => {
      state.generatedRoutes = action.payload;
    },
    markRouteAsSave: (state, action: PayloadAction<string>) => {
      const routeToUpdate = state.generatedRoutes.find(
        (el) => el.id === action.payload
      );
      const updatedRoutes = state.generatedRoutes.filter(
        (el) => el.id !== action.payload
      );
      if (routeToUpdate) {
        routeToUpdate.isSaved = true;
        updatedRoutes.push(routeToUpdate);
      }
      state.generatedRoutes = updatedRoutes;
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = true;
        state.status = "loading";
      });
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = false;
        state.status = "loading";
      });
      state.status = "failed";
    },
  },
});

export const {
  setRoutes,
  setGeneratedRoutes,
  setFetchingInProgress,
  setFetchingFailed,
  markRouteAsSave,
} = routeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRegionById = (state: AppState, id: string) =>
  state.routes.byId[id];

export default routeSlice.reducer;
