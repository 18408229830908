import * as Yup from "yup";

export enum PasswordValidationNames {
  length = "length",
  lowercase = "lowercase",
  uppercase = "uppercase",
  digit = "digit",
  required = "required",
}

export const PasswordValidationText: { [key: string]: string } = {
  length: "Минимум 6 символа",
  lowercase: "Малки букви a - z",
  uppercase: "Главни букви A - Z",
  digit: "Цифри 0-9",
};

const EmailValidationSchema = Yup.string()
  .email("Невалиден имейл")
  .required("Моля въведете имейл");

export const FormikValidationSchema = Yup.object().shape({
  email: EmailValidationSchema,
  password: Yup.string().required("Моля въведете парола"),
});

const PasswordValidationSchema = Yup.string()
  .min(6, PasswordValidationNames.length)
  .matches(new RegExp("(?=.*[A-Z])"), PasswordValidationNames.uppercase)
  .matches(new RegExp("(?=.*[a-z])"), PasswordValidationNames.lowercase)
  .matches(new RegExp("(?=.*[0-9])"), PasswordValidationNames.digit)
  .required(PasswordValidationNames.required);

export const validatePassword = function (password: string) {
  try {
    PasswordValidationSchema.validateSync(password, {
      abortEarly: false,
    });
    return [];
  } catch (error) {
    const errors = (error as Yup.ValidationError).errors;
    return errors;
  }
};

export const validateEmail = function (password: string) {
  try {
    EmailValidationSchema.validateSync(password, {
      abortEarly: false,
    });
    return [];
  } catch (error) {
    const errors = (error as Yup.ValidationError).errors;
    return errors;
  }
};
