import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../constants";
import { BASE_PATH } from "../routes";

export default function PrivateRoute({ ...rest }: RouteProps) {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token) {
    return <Route {...rest} />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: BASE_PATH,
            state: { from: location },
          }}
        />
      )}
    />
  );
}
