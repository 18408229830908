export namespace BlogPost {
  export interface Type {
    id: string;
    longDescription: string;
    author: string;
    placeId: string;
  }

  export const parse = function (data: any): BlogPost.Type {
    return {
      id: data.uuid,
      longDescription: data.long_description,
      author: data.author,
      placeId: data.place.uuid,
    };
  };
}

export default BlogPost;
