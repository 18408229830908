import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { CSSProperties } from "react";
import FacebookLogin, { ReactFacebookLoginProps } from "react-facebook-login";
import { FACEBOOK_CLIENT_ID } from "../../config";

const styles = makeStyles((theme: Theme) => ({
  facebookIcon: {
    margin: `0 ${theme.spacing()}px`,
  },
}));

const FACEBOOK_BUTTON_STYLE: CSSProperties = {
  width: "100%",
  backgroundColor: "#1877F2",
  borderColor: "#1877F2",
  boxShadow:
    "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
  padding: 12,
  fontSize: 14,
};

interface OwnProps
  extends Pick<
    ReactFacebookLoginProps,
    "redirectUri" | "onClick" | "onFailure" | "callback"
  > {}

const FacebookSignupButton: React.FC<OwnProps> = ({
  redirectUri,
  callback,
  onFailure,
  onClick,
}) => {
  const classes = styles();

  return (
    <FacebookLogin
      appId={FACEBOOK_CLIENT_ID || ""}
      fields="first_name,last_name,email,picture"
      redirectUri={redirectUri}
      onClick={onClick}
      callback={callback}
      autoLoad={false}
      onFailure={onFailure}
      buttonStyle={FACEBOOK_BUTTON_STYLE}
      textButton="ВЛЕЗ С FACEBOOK"
      icon={
        <FontAwesomeIcon
          className={classes.facebookIcon}
          size="lg"
          icon={faFacebook}
        />
      }
    />
  );
};

export default FacebookSignupButton;
