import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RouteFilterNames } from "../../../constants";
import User from "../../../entities/User";
import mixpanel from "mixpanel-browser";
import {
  ADD_PLACE_PATH,
  APPLICATION_PATH,
  PLACES_PATH,
  ROUTES_PATH,
} from "../../../routes";
import { selectCurrentUser } from "../../../slices/usersSlice";
import ProfileOverview from "../../profile/ProfileOverview";
import BackButton from "../BackButton";
import Logo from "../Logo";

const styles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  navigationLinksContainer: {
    display: "flex",
    alignItems: "center",
    listStyleType: "none",
    padding: 0,
  },
  navigationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
  },
  flexFullContainer: {
    flex: 1,
  },
  profileLink: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing() / 2,
  },
  iconButtonPadding: {
    padding: theme.spacing() / 2,
  },
  pulseAnimation: {
    animation: "$pulse-animation 1s infinite",
  },
  generateRouteButton: {
    marginLeft: theme.spacing() / 2,
  },
  popover: {
    padding: theme.spacing(2),
  },
  "@keyframes pulse-animation": {
    "0%": {
      boxShadow: `0 0 0 0px #00666680`,
    },
    "100%": {
      boxShadow: `0 0 0 8px #00666699`,
    },
  },
}));

const DesktopHeader: React.FC = () => {
  const classes = styles();
  const user = useAppSelector(selectCurrentUser);
  const history = useHistory();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const match = useMemo(() => {
    return matchPath(location.pathname, {
      path: APPLICATION_PATH,
      exact: true,
      strict: false,
    });
  }, [location]);

  useEffect(() => {
    if (!match || !match.isExact) {
      handleProfileClose();
    }
  }, [match]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar}>
          <div className={classes.flexFullContainer}>
            {(!match || !match.isExact) && <BackButton />}
          </div>
          {(!match || !match.isExact) && (
            <IconButton className={classes.iconButtonPadding}>
              <Link to={APPLICATION_PATH}>
                <Logo size="md" />
              </Link>
            </IconButton>
          )}
          <nav className={classes.navigationContainer}>
            <ul className={classes.navigationLinksContainer}>
              <li>
                <Button
                  onClick={() => {
                    mixpanel.track("ADD_PLACE_PAGE_OPEN");
                    history.push(ADD_PLACE_PATH);
                  }}
                  disabled={!user?.activated}
                  variant="text"
                >
                  <Typography
                    variant="body1"
                    color={user?.activated ? "primary" : "textSecondary"}
                  >
                    ДОБАВИ ОБЕКТ
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => {
                    mixpanel.track("ROUTES_PAGE_OPEN");
                    history.push({
                      pathname: ROUTES_PATH,
                      search: `${RouteFilterNames.status}=${User.SavedRouteStatus.notStarted}`,
                    });
                  }}
                  disabled={!user?.activated}
                  variant="text"
                >
                  <Typography
                    variant="body1"
                    color={user?.activated ? "primary" : "textSecondary"}
                  >
                    МАРШРУТИ
                  </Typography>
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => {
                    mixpanel.track("PLACES_PAGE_OPEN");
                    history.push(PLACES_PATH);
                  }}
                  disabled={!user?.activated}
                  variant="text"
                >
                  <Typography
                    variant="body1"
                    color={user?.activated ? "primary" : "textSecondary"}
                  >
                    ОБЕКТИ
                  </Typography>
                </Button>
              </li>
              <li>
                <IconButton
                  className={classes.iconButtonPadding}
                  aria-describedby={id}
                  onClick={handleProfileClick}
                >
                  <Avatar
                    alt={user ? user.firstName : "firstName"}
                    src={user ? user.profilePicture : ""}
                    className={classNames({
                      [classes.pulseAnimation]: user && !user.firstName,
                    })}
                  >
                    {(!user || !user.profilePicture) && (
                      <AccountCircleSharpIcon color="primary" />
                    )}
                  </Avatar>
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  classes={{ paper: classes.popover }}
                  anchorEl={anchorEl}
                  onClose={handleProfileClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ProfileOverview />
                </Popover>
              </li>
              <li>
                <Button
                  onClick={() => {
                    history.push(APPLICATION_PATH);
                  }}
                  disabled={!user?.activated}
                  className={classes.generateRouteButton}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  ГЕНЕРИРАЙ МАРШРУТ
                </Button>
              </li>
            </ul>
          </nav>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default DesktopHeader;
