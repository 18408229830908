import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { getCommonStyles } from "../../style";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";
import { getSignupStyles } from "../common/signupStyle";
import { PLATFORM_BENEFITS, SUBSCRIPTION_BENEFITS } from "./constants";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-evenly",
  },
  benefitElement: {
    margin: `${theme.spacing()}px 0`,
  },
  backButton: {
    alignSelf: "flex-start",
    paddingTop: theme.spacing() * 2,
  },
  subscriptionButton: {
    marginBottom: theme.spacing(),
  },
  button: {
    marginBottom: theme.spacing(),
  },
  titleText: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  mobileText: {
    fontSize: 28,
  },
}));

interface OwnProps {
  onSubmitForm: () => void;
}

type ComponentProps = OwnProps & WithMediaDownProps;

const BenefitsPage: React.FC<ComponentProps> = function ({
  isMediaDown,
  onSubmitForm,
}) {
  const classes = styles();

  return (
    <Container
      maxWidth={isMediaDown ? "sm" : "md"}
      className={classes.container}
      disableGutters
    >
      <div className={classes.titleContainer}>
        {isMediaDown && <BackButton />}
        <Logo size={isMediaDown ? "md" : "lg"} />
        <Typography
          className={classNames({ [classes.titleText]: isMediaDown })}
          align="center"
          variant="h2"
          color="primary"
          component="div"
        >
          За истинските&nbsp;{isMediaDown && <br />}
          <Typography
            className={classNames({ [classes.mobileText]: isMediaDown })}
            variant="h3"
            color="primary"
            display="inline"
          >
            приключенци,
            <br />
            изследователи
          </Typography>
          &nbsp;и&nbsp;{isMediaDown && <br />}
          <Typography
            className={classNames({ [classes.mobileText]: isMediaDown })}
            variant="h3"
            color="primary"
            display="inline"
          >
            свободни души
          </Typography>
        </Typography>
      </div>
      <Container maxWidth={false} disableGutters className={classes.content}>
        <div>
          <Typography
            variant="h5"
            color="primary"
            gutterBottom={isMediaDown}
            align={isMediaDown ? "inherit" : "center"}
          >
            Какво ще можете да правите в платформата?
          </Typography>
          {PLATFORM_BENEFITS.map((el, idx) => (
            <Typography
              key={idx}
              variant="body2"
              gutterBottom={isMediaDown}
              className={classNames({
                [classes.benefitElement]: !isMediaDown,
              })}
            >
              &#10003; {el}
            </Typography>
          ))}
        </div>

        {/* <div>
          <Typography
            variant="h5"
            color="primary"
            gutterBottom={isMediaDown}
            align={isMediaDown ? "inherit" : "center"}
          >
            С абонамента ще получите възможност да:
          </Typography>
          {SUBSCRIPTION_BENEFITS.map((el, idx) => (
            <Typography
              key={idx}
              variant="body2"
              gutterBottom={isMediaDown}
              className={classNames({
                [classes.benefitElement]: !isMediaDown,
              })}
            >
              &#10003; {el}
            </Typography>
          ))}
        </div> */}
      </Container>
      <div className={classes.footerContainer}>
        {/* <Button
            className={classNames(classes.subscriptionButton, classes.button)}
            onClick={() => {
              history.push(SIGNUP_SUBSCRIPTIONS_PATH);
            }}
            variant="contained"
            color="primary"
            fullWidth
          >
            ИЗБЕРИ АБОНАМЕНТ
          </Button> */}
        <Button
          className={classes.button}
          onClick={onSubmitForm}
          variant="outlined"
          color="primary"
          fullWidth
        >
          ПРОДЪЛЖИ БЕЗПЛАТНО
        </Button>
      </div>
    </Container>
  );
};

export default WithMediaDown(BenefitsPage, "md");
