import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import User from "../../entities/User";
import { selectSavedRoutesByStatus } from "../../slices/usersSlice";
import MobileCard from "./MobileCard";

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
}));

interface OwnProps {
  selectedStatus: User.SavedRoute["status"];
}

const MobilePage: React.FC<OwnProps> = ({ selectedStatus }) => {
  const classes = styles();
  const routes = useAppSelector((state) =>
    selectSavedRoutesByStatus(state, selectedStatus)
  );
  return (
    <div className={classes.container}>
      {routes.map((el) => (
        <MobileCard key={el.id} routeId={el.id} />
      ))}
      {!routes.length && (
        <Typography variant="h5" color="primary" align="center">
          Нямаш {selectedStatus === "completed" ? "завършени" : "незавършени"}{" "}
          маршрути.
        </Typography>
      )}
    </div>
  );
};

export default MobilePage;
