import { CardActionArea } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MaterialCard from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { APPLICATION_PATH, PLACE_OVERVIEW_PATH } from "../../routes";
import {
  ensureCurrentMonthlyAccent,
  selectCurrentMonthlyAccent,
} from "../../slices/monthlyAccentSlice";
import { selectPlaceById } from "../../slices/placeSlice";
import { selectRegionById } from "../../slices/regionSlice";
import { selectCurrentUser } from "../../slices/usersSlice";

const styles = makeStyles((theme: Theme) => ({
  cardMedia: {
    width: "100%",
    height: "100%",
    color: theme.palette.common.white,
  },
  cardRoot: {
    display: "flex",
    width: "100%",
    height: 200,
    position: "relative",
  },
  seeAccentContainer: {
    position: "absolute",
    right: 30,
    top: "80%",
    color: theme.palette.common.white,
  },
  loading: {
    alignSelf: "center",
    margin: "0 auto",
  },
}));

const Card: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const monthlyAccent = useAppSelector(selectCurrentMonthlyAccent);
  const user = useAppSelector(selectCurrentUser);
  const place = useAppSelector((state) =>
    selectPlaceById(state, monthlyAccent ? monthlyAccent.placeId : "")
  );
  const region = useAppSelector((state) =>
    selectRegionById(state, place ? place.regionId : "")
  );
  useEffect(() => {
    dispatch(ensureCurrentMonthlyAccent());
  }, []);

  const handleViewClick = useCallback(() => {
    if (location.pathname.includes(APPLICATION_PATH)) {
      history.push(PLACE_OVERVIEW_PATH.replace(":id", place.id));
      mixpanel.track("MONTHLY_ACCENT_PREVIEW", {
        Place: place.id,
      });
    }
  }, [history, place]);
  return (
    <MaterialCard className={classes.cardRoot}>
      {place ? (
        <CardActionArea onClick={handleViewClick}>
          <CardMedia
            className={classes.cardMedia}
            image={place ? place.coverPhoto : undefined}
          >
            <CardHeader
              title={<Typography variant="body2">Акцент на месеца</Typography>}
              subheader={
                <Typography variant="h5">
                  {region ? place.name : "..."}
                </Typography>
              }
            />
            {!!user?.activated && location.pathname.includes(APPLICATION_PATH) && (
              <div className={classes.seeAccentContainer}>
                <Button
                  color="inherit"
                  onClick={handleViewClick}
                  variant="text"
                >
                  <Typography variant="body2">РАЗГЛЕДАЙ</Typography>
                </Button>
              </div>
            )}
          </CardMedia>
        </CardActionArea>
      ) : (
        <CircularProgress className={classes.loading} size={80} />
      )}
    </MaterialCard>
  );
};

export default Card;
