import Container from "@material-ui/core/Container";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import BackButton from "./common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import PlaceOverviewCard from "./common/PlaceOverviewCard";

type ComponentProps = RouteComponentProps<{ id: string }> & WithMediaDownProps;
const PlaceOverviewPage: React.FC<ComponentProps> = ({
  match,
  isMediaDown,
}) => {
  return (
    <Container maxWidth="md" disableGutters>
      {isMediaDown && <BackButton label="Обекти" />}
      <PlaceOverviewCard
        variant="vertical"
        placeId={match ? match.params.id : ""}
      />
    </Container>
  );
};

export default WithMediaDown(PlaceOverviewPage, "md");
