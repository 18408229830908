import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { AutocompleteOption } from "./AutocompleteTextField";

const styles = makeStyles((theme: Theme) => ({
  row: {
    padding: theme.spacing() / 2,
  },
  icon: {
    marginRight: theme.spacing(),
  },
  primaryIcon: {
    color: theme.palette.secondary.main,
  },
  secondaryIcon: {
    color: theme.palette.text.secondary,
  },
}));

interface OwnProps {
  option: AutocompleteOption;
}

const AutocompleteOptionRow: React.FC<OwnProps> = ({ option }: OwnProps) => {
  const classes = styles();
  return (
    <Typography variant="body1" color="textSecondary" className={classes.row}>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        className={classNames(
          classes.icon,
          { [classes.primaryIcon]: option.placeId },
          { [classes.secondaryIcon]: !option.placeId }
        )}
      />
      {option.text}
    </Typography>
  );
};

export default AutocompleteOptionRow;
