import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMonthlyAccent } from "../api/monthlyAccentAPI";
import { AppState, AppThunk } from "../app/store";
import MonthlyAccent from "../entities/MonthlyAccent";
import { ensureBlogPostById } from "./blogPostSlice";

export interface MonthlyAccentState {
  current: MonthlyAccent.Type | null;
  status: "idle" | "loading" | "failed";
}

type FetchCurrentMonthlyAccentPayload = { monthlyAccent: MonthlyAccent.Type };

const initialState: MonthlyAccentState = {
  current: null,
  status: "idle",
};

export const ensureCurrentMonthlyAccent = (): AppThunk => (
  dispatch,
  getState
) => {
  if (!getState().monthlyAccent.current) {
    dispatch(fetchCurrentMonthlyAccent());
  }
};

export const fetchCurrentMonthlyAccent = (): AppThunk => (dispatch, _) => {
  dispatch(setFetchingInProgress());
  fetchMonthlyAccent()
    .then((monthlyAccent) => {
      dispatch(ensureBlogPostById(monthlyAccent.blogPostId)).then(() => {
        dispatch(setCurrentMonthlyAccent({ monthlyAccent }));
      });
    })
    .catch((error) => {
      dispatch(setFetchingFailed());
    });
};

export const monthlyAccentSlice = createSlice({
  name: "monthlyAccent",
  initialState,
  reducers: {
    setCurrentMonthlyAccent: (
      state,
      action: PayloadAction<FetchCurrentMonthlyAccentPayload>
    ) => {
      state.current = action.payload.monthlyAccent;
      state.status = "idle";
    },
    setFetchingInProgress: (state) => {
      state.status = "loading";
    },
    setFetchingFailed: (state) => {
      state.status = "failed";
    },
  },
});

export const {
  setCurrentMonthlyAccent,
  setFetchingInProgress,
  setFetchingFailed,
} = monthlyAccentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentMonthlyAccent = (state: AppState) =>
  state.monthlyAccent.current;

export default monthlyAccentSlice.reducer;
