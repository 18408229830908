import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { APP_PRIVACY_POLICY_PATH } from "../routes";
import BackButton from "./common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";

const styles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
    textAlign: "center",
  },
  sectionTitle: {
    margin: `${theme.spacing()}px 0`,
  },
  backButton: {
    alignSelf: "flex-start",
    padding: `${theme.spacing() * 2}px 0`,
  },
  contentMarginContainer: {
    marginTop: theme.spacing() * 4,
  },
}));

const PrivacyPolicyPage: React.FC<WithMediaDownProps> = function ({
  isMediaDown,
}) {
  const classes = styles();
  const location = useLocation();
  const isInApplicationPC = useMemo(() => {
    return matchPath(location.pathname, {
      path: APP_PRIVACY_POLICY_PATH,
      exact: true,
      strict: false,
    })?.isExact;
  }, [location]);

  return (
    <Container disableGutters maxWidth="md">
      {!isMediaDown && !isInApplicationPC && <BackButton />}
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div
          className={classNames(classes.content, {
            [classes.contentMarginContainer]: !isMediaDown,
          })}
        >
          {isMediaDown && !isInApplicationPC && <BackButton />}
          {!isInApplicationPC && <Logo size={isMediaDown ? "md" : "lg"} />}
          <Typography variant="h1" color="primary" className={classes.title}>
            Политика за поверителност
          </Typography>
          <Typography variant="body2" color="textPrimary" align="justify">
            Защитата на личните данни е от голямо значение за ЕКСПЛОР ТЕХНОЛОДЖИ
            ООД и искаме процесът по обработка на Вашите лични данни да бъде
            напълно открит и прозрачен за Вас. Ето защо имаме политика, която
            определя как ще бъдат обработвани и защитени Вашите лични данни.
            <br />
            Настоящата Политика за поверителност се отнася до вашите личните
            данни, които ЕКСПЛОР ТЕХНОЛОДЖИ ООД събира с цел да ви предостави
            услуги ("Услуги") съгласно предмета си на дейност.
            <br />
            Политиката за поверителност обяснява как в ЕКСПЛОР ТЕХНОЛОДЖИ ООД
            събираме и обработваме Вашите лични данни. Лични данни означава
            всяка информация или набор от информация, която идентифицира или би
            могла да бъде използвана за идентифициране на потребителя. Това
            включва информация като име и фамилия, домашен адрес, имейл адрес,
            номер на картата за самоличност, телефонен номер,
            месторабота/училище, IP адрес, идентификационен номер на
            „бисквитка“. Тази политика обяснява как обработваме Вашите лични
            данни, какви са Вашите права и как законът защитава Вас и данните
            Ви.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ЗАКОНОВАТА РАМКА ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ
            </Typography>
            Спазването на поверителността и защитата на Вашите лични данни е
            важен въпрос за нас, който взимаме под внимание във всички наши
            бизнес взаимоотношения. Ние зачитаме поверителността на личните Ви
            данни и винаги действаме в съответствие с разпоредбите на Закона за
            защита на личните данни и Регламент (ЕС) 2016/679 на Европейския
            парламент и на Съвета от 27 април 2016 г.
            <br />
            Възможно е в течение на времето да има и други промени, които ще
            трябва да направим в тази политика за защита на личните данни, като
            в такива случаи ще Ви уведомяваме всеки път, когато направим промяна
            в нашата политика. Може да се наложи да поискаме от Вас да приемете
            промените или да дадете наново съгласието си за използването на
            Вашите лични данни.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              СЪБИРАНЕ НА ДАННИ
            </Typography>
            Данни, които Вие споделяте с нас: • Имейл адрес • Име и Фамилия
            (незадължително) • Профилна снимка (незадължително)
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ОСНОВАНИЕ ЗА ОБРАБОТВАНЕ
            </Typography>
            Обработването на лични данни включва събиране, съхранение,
            унищожаване, предаване, коригиране, актуализиране, изтриване,
            унищожаване и всички други действия, които се извършват с Вашите
            лични данни.
            <br />
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД събира лични данни и след получено изрично,
            ясно, свободно и недвусмислено съгласие от Вас за целите на
            обработването. Съгласието за обработване на личните Ви данни се
            предоставя чрез писмена декларация-съгласие или при регистрация на
            уеб страницата ни.
            <br />
            Съгласието, което предоставяте, може винаги да бъде оттеглено чрез
            изпращане на имейл до: info@goxplore.bg.
            <br />
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД обработва лични данни и в изпълнение на
            законови задължения, както ако е необходимо с цел да се защитят
            животът и здравето на физическото лице, за което се отнасят данните.
            <br />
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД може да обработва лични данни и в случай че
            има правен (легитимен) интерес, освен когато пред този интерес
            преимущество имат интересите на физическото лице, за което се
            отнасят данните.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ТРЕТИ СТРАНИ
            </Typography>
            Понякога след Ваше изрично съгласие дадено през инструментите
            партньори, можем да получим данни от трети страни, например данни от
            профила Ви в социални мрежи, като Facebook или Instagram.
            <br />
            Лични данни могат да бъдат предоставяни на държавни и/или
            правоприлагащи органи, ако това се изисква от приложимото
            законодателство.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ВХОД/РЕГИСТРАЦИЯ ЧРЕЗ СОЦИАЛНИ МРЕЖИ
            </Typography>
            При регистрация и вход в платформата, имате възможност да потвърдите
            самоличността си чрез съществуващ профил в социалните медии Facebook
            и Google, като по този начин се регистрирате и влизате в нашата
            платформа.
            <br />
            За тази цел, на страницата за регистрация или вход ще откриете
            съответните бутони с лога на съответните социални мрежи, поддържани
            от нашия уебсайт. Преди установяване на връзка през социалните
            мрежи, трябва изрично да се съгласите с процеса и предаване на
            данни, описани тук:
            <br />• След кликване върху съответния символ се отваря нов прозорец
            на съответната социална мрежа, в което трябва да влезете с данните
            си за вход в социалната мрежа. След като сте влезли, социалната
            мрежа ще Ви уведоми кои данни ще ни бъдат предоставени за
            потвърждаване на Вашата самоличност като част от процеса на
            регистрация или вход. Ако одобрявате този трансфер на данни,
            изискваните от нас полета за регистрация ще бъдат попълнени с
            предадените данни. Информацията, от която се нуждаем за
            регистрация/вход е Вашето име и Вашия имейл адрес.
            <br />• Едва след изричното Ви съгласие за използване на предадените
            и изисквани данни, Вашата информация ще бъде съхранявана при нас и
            използвана за целите, посочени в настоящата Политика за
            поверителност. Няма друга връзка, в допълнение с процеса на
            удостоверяване на самоличността, между Вашия клиентски профил в
            нашия уебсайт и Вашия профил в съответната социална мрежа.
            <br />• С цел извършване на процеса на удостоверяване на
            самоличността за регистрация или вход, Вашият IP адрес се предава на
            съответния доставчик на социална мрежа. Нямаме влияние върху целта и
            обхвата на събирането на данни и на обработването на данните от
            страна на съответния доставчик на социалната мрежа. За повече
            информация, моля, прочетете информацията за защита на данните на
            съответния доставчик:
            <br />
            <a href="http://www.facebook.com/policy.php" target="_blank">
              http://www.facebook.com/policy.php
            </a>
            &nbsp;(Facebook Inc.)
            <br />
            <a href="https://policies.google.com/privacy?hl=bg" target="_blank">
              https://policies.google.com/privacy?hl=bg
            </a>
            &nbsp;(Google Inc.)
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ИЗПОЛЗВАНЕ НА ЛИЧНИ ДАННИ
            </Typography>
            Предоставените от Вас лични данни се обработват и използват от
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД само и единствено за посочените цели. Те
            включват, по-специално:
            <br />
            1. Маркетингови цели: В случай, че оставите имейл адреса си на
            нашата страница, предоставената от Вас информация се използва, за да
            Ви пращаме имейли, свързани с нашата платформа и услугите,
            предлагани от нас.
            <br />
            2. Използване на формуляр за контакт: В случай, че използвате
            формуляра за контакт за запитвания, предоставената от Вас информация
            във формуляра, включително данните за контакт, се използват за
            обработка на запитването и се съхраняват за по-нататъшна употреба,
            ако възникнат въпроси за последващи действия.v3. Регистрация в
            платформата: В случай, че се регистрирате като потребител в
            платформата, ние Ви изпращаме имейли, свързани с платформата и
            услугите, които предлагаме. Също така използваме данните Ви за да
            управляваме Вашия профил в платформата и да Ви идентифицираме като
            потребител.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ЗАЩИТА НА ДАННИТЕ
            </Typography>
            В ЕСКПЛОР ТЕХНОЛОДЖИ ООД сме въвели нужните мерки за сигурност на
            информацията, за да предотвратим случайно изгубване, използване или
            достъп до Вашите лични данни по неразрешен начин, промяна или
            разкриване. Освен това ограничаваме достъпа до Вашите лични данни на
            тези служители, доставчици на външни услуги и други трети страни,
            които имат легитимно право на достъп до тях. Те са обект на
            задължение за поверителност.
            <br />
            Предвид естеството на Интернет, обръщаме внимание на факта, че може
            да има пропуски в сигурността при предаването на данни през Интернет
            (например чрез електронна поща) и че пълната защита на данните от
            достъпа на трети лица не е възможна.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              СРОК ЗА СЪХРАНЕНИЕ НА ВАШИТЕ ЛИЧНИ ДАННИ
            </Typography>
            Ние съхраняваме Вашите лични данни само толкова дълго, колкото е
            необходимо, за да изпълним целите, за които сме ги събрали,
            включително за целите на удовлетворяването на законови, счетоводни
            или отчетни изисквания.
            <br />
            За да определим подходящия период за съхранение на личните данни,
            ние отчитаме обема, естеството и чувствителността на личните данни,
            потенциалния риск от вреди от неразрешено използване или разкриване
            на Вашите лични данни, целите, за които ние обработваме Вашите лични
            данни и дали можем да постигнем тези цели чрез други средства, както
            и приложимите законови изисквания.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ВАШИТЕ ПРАВА
            </Typography>
            Общият регламент за защита на лични данни гарантира определен набор
            от права, които можете да упражните във връзка с обработваните от
            нас Ваши лични данни. По-конкретно вие имате:
            <br />• Право на достъп до информация относно обработката на лични
            данни и сведения какви лични данни ЕКСПЛОР ТЕХНОЛОДЖИ ООД обработва
            за Вас;
            <br />• Право на получаване на копие от информацията, която
            съхраняваме;
            <br />• Можете да ни поискате копие от личните данни, които
            съхраняваме за вас, като ни изпратите имейл на адрес:
            info@goxplore.bg;
            <br />• Право да ни кажете дали информацията, която съхраняваме, е
            неправилна;
            <br />• Право да ни кажете, че искате да преустановим използването
            на Вашите лични данни;
            <br />• Право да възразите срещу използването на Вашите лични данни;
            <br />• Право да поискате да изтрием личните Ви данни;
            <br />• Право да поискате ограничаване на обработката;
            <br />• Право да ни помолите да спрем да ги използваме, ако няма
            нужда да го правим (известно като “правото да бъдеш забравен”);
            <br />• Право да поискате преносимост на личните Ви данни към друг
            Администратор.
            <br />
            Ще се опитаме да отговорим на всички легитимни искания в рамките на
            един месец. Понякога може да ни отнеме повече от месец, в който
            случай ще Ви уведомим.
            <br />
            Имате право да поставяте под въпрос всяка информация, която
            съхраняваме за вас, която смятате за грешна или непълна. Моля,
            свържете се с нас по електронната поща на адрес info@goxplore.bg ,
            ако искате да направите това, и ще предприемем разумни стъпки, за да
            проверим точността на информацията и, ако е необходимо, да я
            коригираме.
            <br />
            Възможно е да има правни основания, поради които трябва да запазим
            или използваме Вашите данни, които ние ще споделим с Вас, когато
            упражнявате някое от гореизброените права.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ОТТЕГЛЯНЕ НА СЪГЛАСИЕТО
            </Typography>
            Можете да оттеглите вашето съгласие за това да използваме Вашите
            лични данни по всяко време. Моля, свържете се с нас на имейл на
            info@goxplore.bg, ако искате да оттеглите Вашето съгласие. Ако
            оттеглите съгласието си, може да не сме в състояние да Ви
            предоставим определени продукти или услуги.
            <br />
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              КОНТАКТИ
            </Typography>
            За въпроси, свързани с настоящите Правила за поверителност, моля,
            свържете се с:
            <br />
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД
            <br />
            ЕИК: 206530000
            <br />
            гр. София, 1712, ж.к. "Младост-3", бл. 364, вх. 2, ет. 13, ап. 59
            Имейл адрес: info@goxplore.bg
            <br />
            Длъжностно лице: Илиян Бачийски
            <br />
            Моля, ако не сте доволни от начина, по който сме използвали личните
            Ви данни, уведомете ни като ни изпратите имейл на: info@goxplore.bg.
            <br />С разглеждането и използването на функциите на нашия уебсайт,
            Вие изрично се съгласявате Вашите лични данни да бъдат събирани и
            обработвани от нас, като същевременно с това гарантирате тяхната
            точност и достоверност. Вие също така декларирате, че сте били
            информиран/а за типа лични данни, които събираме и обработваме, за
            целите, за които ще бъдат използвани те, както и за Вашето право на
            достъп, коригиране или изтриване на събраните лични данни.
          </Typography>
        </div>
      </Container>
    </Container>
  );
};

export default WithMediaDown(PrivacyPolicyPage, "md");
