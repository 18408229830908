export namespace MonthlyAccent {
  export interface Type {
    id: string;
    month: number;
    blogPostId: string;
    placeId: string;
  }

  export const parse = function (data: any): MonthlyAccent.Type {
    return {
      id: data.uuid,
      month: data.month,
      blogPostId: data.blog_post.uuid,
      placeId: data.blog_post.place.uuid,
    };
  };
}

export default MonthlyAccent;
