import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  APPLICATION_PATH,
  GENERATED_ROUTE_OVERVIEW_PATH,
  ROUTES_PATH,
} from "../../routes";
import { setGeneratedRoutes } from "../../slices/routeSlice";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import RouteCard from "../common/RouteCard";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
  },
  cardsContainer: {
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
  },
  mobileCardContainer: {
    minHeight: 300,
  },
  cardsContainerMobile: {
    flexDirection: "column",
    justifyContent: "unset",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  desktopHeader: {
    justifyContent: "center",
  },
  continueBtn: {
    alignSelf: "center",
    maxWidth: 400,
    marginTop: theme.spacing(2),
  },
}));

const GeneratedRoutesOverview: React.FC<WithMediaDownProps> = ({
  isMediaDown,
}) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const routes = useAppSelector((state) => state.routes.generatedRoutes);
  useEffect(() => {
    if (!routes.length) {
      history.push(APPLICATION_PATH);
    } else {
      const areAllSaved = routes.every((el) => el.isSaved);
      if (areAllSaved) {
        handleGenerateComplete();
      }
    }
  }, []);

  const handleGenerateComplete = useCallback(() => {
    dispatch(setGeneratedRoutes([]));
    history.replace(ROUTES_PATH);
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container} disableGutters>
      <div
        className={classNames(classes.header, {
          [classes.desktopHeader]: !isMediaDown,
        })}
      >
        {isMediaDown && <BackButton label="Избери посока" />}
        {!isMediaDown && (
          <Typography variant="h3" color="primary">
            Избери посока
          </Typography>
        )}
      </div>
      <div
        className={classNames(classes.cardsContainer, {
          [classes.cardsContainerMobile]: isMediaDown,
        })}
      >
        {routes.map((el) => (
          <RouteCard
            minHeight={isMediaDown ? 300 : 400}
            key={el.id}
            route={el}
            onPreview={() => {
              history.push(GENERATED_ROUTE_OVERVIEW_PATH.replace(":id", el.id));
            }}
          />
        ))}
      </div>
      <Button
        className={classes.continueBtn}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleGenerateComplete}
      >
        ПРОДЪЛЖИ
      </Button>
    </Container>
  );
};

export default WithMediaDown(GeneratedRoutesOverview, "md");
