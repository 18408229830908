import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Field, useFormikContext } from "formik";
import React, { useCallback } from "react";
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import { SignupFormikValues } from ".";
import { checkSignupEmailExisting } from "../../api/authAPI";
import { useAppDispatch } from "../../app/hooks";
import {
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  SIGNUP_PASSWORD_PATH,
} from "../../routes";
import { setUserEmail } from "../../slices/registrationSlice";
import { getCommonStyles } from "../../style";
import BackButton from "../common/BackButton";
import FacebookSignupButton from "../common/FacebookSignupButton";
import FormikTextField from "../common/formik/FormikTextField";
import GoogleSignupButton from "../common/GoogleSignupButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";
import { getSignupStyles } from "../common/signupStyle";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  basicLoginInput: {
    marginBottom: theme.spacing(),
  },
}));

interface OwnProps {
  onFacebookSignup: (userInfo: ReactFacebookLoginInfo) => void;
  onGoogleSignup: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  onGoogleRequest?: () => void;
  onFacebookRequest?: () => void;
}

type ComponentProps = OwnProps & RouteComponentProps & WithMediaDownProps;

const EmailPage: React.FC<ComponentProps> = function ({
  onFacebookSignup,
  onGoogleSignup,
  onGoogleRequest,
  onFacebookRequest,
  history,
  isMediaDown,
}) {
  const classes = styles();
  const dispatch = useAppDispatch();

  const {
    values,
    errors,
    isSubmitting,
  } = useFormikContext<SignupFormikValues>();

  const handleFacebookFailure = useCallback(
    (response: ReactFacebookFailureResponse) => {
      console.log("Facebook Failure-> ", response);
    },
    []
  );

  const handleGoogleFailure = useCallback((error: any) => {
    console.log("Google Failure-> ", error);
  }, []);

  const handleEmailConfirmed = useCallback(() => {
    if (!errors.email) {
      checkSignupEmailExisting(values.email).then(() => {
        dispatch(setUserEmail(values.email));
        history.push(SIGNUP_PASSWORD_PATH);
      });
    }
  }, [values, errors]);

  return (
    <React.Fragment>
      {!isMediaDown && <BackButton />}
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div className={classes.titleContainer}>
          {isMediaDown && <BackButton />}
          <Logo size={isMediaDown ? "md" : "lg"} />
          <Typography variant="h1" color="primary">
            Регистрация
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.socialMediaContainer}>
            <FacebookSignupButton
              redirectUri={window.location.href}
              callback={onFacebookSignup}
              onFailure={handleFacebookFailure}
              onClick={onFacebookRequest}
            />
            <GoogleSignupButton
              onSuccess={onGoogleSignup}
              onFailure={handleGoogleFailure}
              onRequest={onGoogleRequest}
            />
          </div>
          <div className={classes.divider}>
            <Typography variant="body2" color="textSecondary">
              или
            </Typography>
          </div>
          <div className={classes.basicCredentialsContainer}>
            <Field
              component={FormikTextField}
              name="email"
              className={classes.basicLoginInput}
              label="Въведи е-поща"
              fullWidth
              InputProps={{
                inputProps: {
                  type: "email",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleEmailConfirmed}
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting || !values.email}
            >
              Регистрирай се
            </Button>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Typography variant="body2" color="primary">
            Имаш профил?&nbsp;
            <Link to={LOGIN_PATH}>
              <Typography variant="body1" component="span" color="primary">
                Влез
              </Typography>
            </Link>
          </Typography>
          <Link to={FORGOT_PASSWORD_PATH}>
            <Typography variant="body1" color="primary">
              Забравена парола
            </Typography>
          </Link>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(WithMediaDown(EmailPage, "md"));
