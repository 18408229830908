import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { showNotification } from "../../slices/notificationSlice";
import AutocompleteOptionRow from "./AutocompleteOptionRow";

let autocompleteService: google.maps.places.AutocompleteService;
let placesService: google.maps.places.PlacesService;
let sessionToken: google.maps.places.AutocompleteSessionToken;

const styles = makeStyles((theme: Theme) => ({
  autocompleteInputRoute: {
    padding: `${theme.spacing()}px !important`,
  },
}));

export interface AutocompleteOption {
  text: string;
  placeId?: string;
  location?: google.maps.LatLng;
}

interface OwnProps {
  inputValue: string;
  option?: AutocompleteOption;
  map?: HTMLDivElement | google.maps.Map;
  onSelectOption: (place: AutocompleteOption) => void;
}

type ComponentProps = OwnProps & TextFieldProps;

const AutocopleteTextField: React.FC<ComponentProps> = ({
  inputValue,
  option,
  map,
  disabled,
  onSelectOption,
  ...rest
}: ComponentProps) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const [predictions, setPredictions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    if (map) {
      if (!placesService) {
        placesService = new google.maps.places.PlacesService(map);
      }
      if (!sessionToken) {
        sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
    }
  }, [map]);

  useEffect(() => {
    if (inputValue && inputValue.length >= 3) {
      if (!autocompleteService) {
        autocompleteService = new google.maps.places.AutocompleteService();
      }
      autocompleteService
        .getPlacePredictions({
          input: inputValue,
          types: ["geocode", "establishment"],
          componentRestrictions: { country: "bg" },
          sessionToken,
        })
        .then((response) => {
          const result = [
            ...response.predictions.map((el) => ({
              text: el.description,
              placeId: el.place_id,
            })),
          ];
          setPredictions(result);
        });
    }
    if (!inputValue) {
      setPredictions([]);
    }
  }, [inputValue]);

  const handleSelectOption = useCallback((option: AutocompleteOption) => {
    if (option && option.placeId) {
      placesService.getDetails(
        { placeId: option.placeId, sessionToken },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const result: AutocompleteOption = {
              text: option.text,
              placeId: option.placeId,
              location: place?.geometry?.location,
            };
            onSelectOption(result);
            sessionToken = new google.maps.places.AutocompleteSessionToken();
          } else {
            dispatch(
              showNotification({
                text: "Не успяхме да открием информация. Моля, опитай отново!",
                type: "warning",
              })
            );
          }
        }
      );
    }
    // else {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       const requestLocation = new google.maps.LatLng({
    //         lat: latitude,
    //         lng: longitude,
    //       });

    //       const result: AutocompleteOption = {
    //         text: "Моето местоположение",
    //         location: requestLocation,
    //       };
    //       onSelectOption(result);
    //     },
    //     (error) => {
    //       dispatch(
    //         showNotification({
    //           text: "Не успяхме да открием местоположението ти!",
    //           type: "warning",
    //         })
    //       );
    //     }
    //   );
    // }
  }, []);

  return (
    <Autocomplete
      classes={{ inputRoot: classes.autocompleteInputRoute }}
      fullWidth
      freeSolo
      value={option}
      inputValue={inputValue}
      // options={
      //   predictions.length ? predictions : [{ text: "Моето местоположение" }]
      // }
      disabled={disabled}
      options={predictions}
      getOptionLabel={(option) => option.text}
      onChange={(_, newValue) => {
        handleSelectOption(newValue as AutocompleteOption);
      }}
      blurOnSelect
      renderOption={(option) => {
        return <AutocompleteOptionRow option={option} />;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          disabled={disabled}
          value={inputValue}
          InputProps={{ ...params.InputProps, ...rest.InputProps }}
          placeholder={rest.placeholder || "Въведи поне три символа..."}
        />
      )}
    />
  );
};

export default AutocopleteTextField;
