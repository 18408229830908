import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../app/store";
import { SubscriptionType } from "../components/subscription/constants";

export interface RegistrationState {
  email: string;
  password: string;
  subscription?: SubscriptionType;
  submitError: string;
}

const initialState: RegistrationState = {
  email: "",
  password: "",
  submitError: "",
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setUserSubscriptionMethod: (
      state,
      action: PayloadAction<SubscriptionType>
    ) => {
      state.subscription = action.payload;
    },
    setSubmitError: (state, action: PayloadAction<string>) => {
      state.submitError = action.payload;
    },
    resetRegistrationState: (_) => {
      return { ...initialState };
    },
  },
});

export const {
  setUserEmail,
  setUserPassword,
  setUserSubscriptionMethod,
  setSubmitError,
  resetRegistrationState,
} = registrationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRegistrationEmail = (state: AppState) =>
  state.ui.registration.email;

export const selectRegistrationPassword = (state: AppState) =>
  state.ui.registration.password;

export const selectRegistrationSubscriptionMethod = (state: AppState) =>
  state.ui.registration.subscription;

export const selectSubmitError = (state: AppState) =>
  state.ui.registration.submitError;

export default registrationSlice.reducer;
