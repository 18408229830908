import { Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const containerProperties: CSSProperties = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
const contentProperties: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const titleContainerProperties: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const getLogoProperties = (theme: Theme): CSSProperties => ({
  marginTop: theme.spacing() * 3,
});

const getSocialMediaContainerProperties = (theme: Theme): CSSProperties => ({
  width: "100%",
  margin: `${theme.spacing() * 3}px 0`,
});

const getBasicCredentialsContainerProperties = (
  theme: Theme
): CSSProperties => ({
  width: "100%",
  margin: `${theme.spacing() * 4}px 0`,
});

const getFooterContainerProperties = (theme: Theme): CSSProperties => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const getSignupStyles = (theme: Theme) => ({
  container: containerProperties,
  content: contentProperties,
  titleContainer: titleContainerProperties,
  logo: getLogoProperties(theme),
  socialMediaContainer: getSocialMediaContainerProperties(theme),
  basicCredentialsContainer: getBasicCredentialsContainerProperties(theme),
  footerContainer: getFooterContainerProperties(theme),
});
