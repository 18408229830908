import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import {
  APPLICATION_ABOUT_US_PATH,
  APP_PRIVACY_POLICY_PATH,
  APP_TERMS_AND_CONDITIONS_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from "../../routes";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "./Logo";

const styles = makeStyles((theme: Theme) => ({
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    justifyContent: "space-between",
    height: "100%",
  },
  header: {
    alignItems: "center",
  },
  title: {
    margin: `${theme.spacing()}px 0`,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
  },
  ownImageContainer: {
    display: "flex",
    flexDirection: "column",
    width: 145,
    height: 190,
    margin: `0 ${theme.spacing()}px`,
  },
  ownImage: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  partnerImage: {
    minWidth: 130,
    margin: theme.spacing() / 2,
  },
  horizontalImage: {
    height: 35,
  },
  verticalImage: {
    height: 80,
  },
  backButton: {
    alignSelf: "flex-start",
  },
  footer: {
    margin: `${theme.spacing()}px 0`,
  },
  mixa: {
    fontFamily: "Mixa-Bold",
    margin: `${theme.spacing()}px 0`,
  },
}));

const AboutUsPage: React.FC<WithMediaDownProps> = function ({ isMediaDown }) {
  const classes = styles();
  const location = useLocation();
  const applicationPathMatch = useMemo(() => {
    return matchPath(location.pathname, {
      path: APPLICATION_ABOUT_US_PATH,
      exact: true,
      strict: false,
    });
  }, [location]);
  return (
    <Container
      maxWidth="md"
      className={classNames(classes.columnContainer, classes.container)}
      disableGutters
    >
      <div className={classNames(classes.columnContainer, classes.header)}>
        {(isMediaDown || !applicationPathMatch?.isExact) && (
          <div className={classes.backButton}>
            <BackButton />
          </div>
        )}
        {!applicationPathMatch?.isExact && (
          <Logo size={isMediaDown ? "md" : "lg"} />
        )}
        <Typography
          className={classes.title}
          align="center"
          variant="h3"
          color="primary"
          component="div"
        >
          Какво е GoXplore?
        </Typography>
        <Typography
          className={classes.mixa}
          align="center"
          variant="h2"
          color="textPrimary"
          component="div"
        >
          Началната точка на всяко пътуване.
        </Typography>
        <Typography
          align="left"
          variant="body2"
          color="textPrimary"
          component="div"
        >
          GoXplore e онлайн платформа, която ще ти помогне сам да организираш
          лесно и бързо незабравими пътешествия и да посетиш невероятни кътчета
          в България. Предлагаме ти списък от разнообразни обекти и изготвяне на
          различни маршрути, за да имаш винаги ясна посока към следващото ти
          приключение!
        </Typography>
        <Typography
          className={classes.title}
          align="center"
          variant="h3"
          color="primary"
          component="div"
        >
          Кои сме ние?
        </Typography>
        <div className={classes.imageContainer}>
          <div className={classes.ownImageContainer}>
            <img
              className={classes.ownImage}
              src="https://cdn.goxplore.bg/private/Iliyan_profile.jpeg"
              alt="Iliyan"
            />
            <Typography
              className={classes.title}
              align="center"
              variant="h5"
              color="primary"
            >
              Илиян
            </Typography>
          </div>
          <div className={classes.ownImageContainer}>
            <img
              className={classes.ownImage}
              src="https://cdn.goxplore.bg/private/Veronika_profile.jpg"
              alt="Veronika"
            />
            <Typography
              className={classes.title}
              align="center"
              variant="h5"
              color="primary"
            >
              Вероника
            </Typography>
          </div>
        </div>
        <Typography
          align="left"
          variant="body2"
          color="textPrimary"
          component="div"
        >
          Ние, екипът на GoXplore, или просто Илиян и Вероника, сме двама
          любители-пътешественици, които обожават да откриват нови места.
          Създадохме платформата с много любов към приключенията и силно се
          надяваме да успеем да запалим този интерес и в теб!
        </Typography>
        <Typography
          className={classes.title}
          align="center"
          variant="h3"
          color="primary"
          component="div"
        >
          Благодарим на нашите партньори и приятели:
        </Typography>
        <div className={classes.imageContainer}>
          <a href="https://cosmonavt.studio" target="_blank">
            <img
              className={classNames(
                classes.partnerImage,
                classes.horizontalImage
              )}
              src="https://cdn.goxplore.bg/partners/logo_cosmonavt.png"
              alt="Cosmonavt Studio"
            />
          </a>
          <a href="https://roden-puzzle.bg" target="_blank">
            <img
              className={classNames(
                classes.partnerImage,
                classes.verticalImage
              )}
              src="https://cdn.goxplore.bg/partners/logo_puzzle.svg"
              alt="Roden Puzel"
            />
          </a>
          <a href="https://journeybeyondhorizon.com" target="_blank">
            <img
              className={classNames(
                classes.partnerImage,
                classes.horizontalImage
              )}
              src="https://cdn.goxplore.bg/partners/logo_journey.png"
              alt="Journey"
            />
          </a>
          <a href="https://mytravelingself.com" target="_blank">
            <img
              className={classNames(
                classes.partnerImage,
                classes.horizontalImage
              )}
              src="https://cdn.goxplore.bg/partners/logo_mytravelingself.jpg"
              alt="Mytravelingself"
            />
          </a>
        </div>
      </div>
      <div className={classes.footer}>
        <Link
          to={
            applicationPathMatch?.isExact
              ? APP_TERMS_AND_CONDITIONS_PATH
              : TERMS_AND_CONDITIONS_PATH
          }
        >
          <Typography variant="body1" color="primary" align="center">
            Условия за ползване
          </Typography>
        </Link>
        <Link
          to={
            applicationPathMatch?.isExact
              ? APP_PRIVACY_POLICY_PATH
              : PRIVACY_POLICY_PATH
          }
        >
          <Typography variant="body1" color="primary" align="center">
            Политика за поверителност
          </Typography>
        </Link>
        <Typography variant="body2" color="primary" align="center">
          Всички права запазени GoXplore 2021
        </Typography>
      </div>
    </Container>
  );
};

export default WithMediaDown(AboutUsPage, "md");
