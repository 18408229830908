import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../constants";

const styles = makeStyles((theme: Theme) => ({
  googleButton: {
    width: "100%",
    height: 45,
    justifyContent: "center",
    margin: `${theme.spacing()}px 0`,
    "&>span": {
      fontWeight: "bolder !important",
    },
    fontSize: 14,
  },
}));

interface OwnProps {
  onSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  onFailure: (error: any) => void;
  onRequest?: () => void;
}

const GoogleSignupButton: React.FC<OwnProps> = ({
  onSuccess,
  onFailure,
  onRequest,
}) => {
  const classes = styles();
  const isSignedIn =
    !!localStorage.getItem(ACCESS_TOKEN_KEY) &&
    localStorage.getItem(PROVIDER_TYPE_KEY) === "google";
  return (
    <GoogleLogin
      className={classes.googleButton}
      clientId={GOOGLE_CLIENT_ID || ""}
      buttonText="ВЛЕЗ С GOOGLE"
      autoLoad={false}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
      isSignedIn={isSignedIn}
      onRequest={onRequest}
    />
  );
};

export default GoogleSignupButton;
