import moment from "moment";

export namespace Route {
  export interface Type {
    id: string;
    title: string;
    places: string[];
    createdAt: moment.Moment;
  }

  export const parse = function (data: any): Route.Type {
    return {
      id: data.uuid,
      title: data.title,
      places: data.places.map((el: any) => el.uuid),
      createdAt: moment(data.created_at).locale("bg"),
    };
  };
}

export default Route;
