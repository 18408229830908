import axios from "axios";
import Place from "../entities/Place";
import { PlaceFilterNames } from "../constants";

export interface PlaceFilterConfig {
  page: number;
  limit: number;
  search?: string;
  regions?: string[];
  categories?: string[];
  subcategories?: string[];
  favourite?: boolean;
  visited?: boolean;
  notVisited?: boolean;
}

export interface FetchPlacesResponse {
  result: string[];
  count: number;
}

export function fetchPlacesByIds(ids: string[]): Promise<Place.Type[]> {
  return new Promise<Place.Type[]>((resolve, reject) => {
    axios
      .get(`/places/?ids=${ids.join("&ids=")}`)
      .then(({ data: { result } }) => {
        resolve(result.map(Place.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchSinglePlaceById(id: string): Promise<Place.Type> {
  return new Promise<Place.Type>((resolve, reject) => {
    axios
      .get(`/places/${id}`)
      .then(({ data }) => {
        resolve(Place.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchPlaces(
  filter: PlaceFilterConfig
): Promise<FetchPlacesResponse> {
  return new Promise<FetchPlacesResponse>((resolve, reject) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set(PlaceFilterNames.page, String(filter.page));
    urlSearchParams.set(PlaceFilterNames.limit, String(filter.limit));
    urlSearchParams.set(PlaceFilterNames.includeFields, "ids");
    if (filter.regions) {
      filter.regions.forEach((region) => {
        urlSearchParams.append(PlaceFilterNames.region, region);
      });
    }
    if (filter.categories) {
      filter.categories.forEach((category) => {
        urlSearchParams.append(PlaceFilterNames.category, category);
      });
    }
    if (filter.subcategories) {
      filter.subcategories.forEach((subcategory) => {
        urlSearchParams.append(PlaceFilterNames.subcategory, subcategory);
      });
    }
    if (filter.search) {
      urlSearchParams.set(PlaceFilterNames.search, filter.search);
    }
    if (filter.visited) {
      urlSearchParams.set(PlaceFilterNames.visited, String(filter.visited));
    }
    if (filter.notVisited) {
      urlSearchParams.set(
        PlaceFilterNames.notVisited,
        String(filter.notVisited)
      );
    }
    if (filter.favourite) {
      urlSearchParams.set(PlaceFilterNames.favourite, String(filter.favourite));
    }
    axios
      .get(`/places/?${urlSearchParams.toString()}`)
      .then(({ data }) => {
        const result: [{ uuid: string }] = data.result;
        resolve({ result: result.map((el) => el.uuid), count: data.count });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitPlaceComment(
  placeId: string,
  comment: string
): Promise<Place.Type> {
  return new Promise<Place.Type>((resolve, reject) => {
    axios
      .post("/places/comment", {
        place_id: placeId,
        text: comment,
      })
      .then(({ data }) => {
        resolve(Place.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
