import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import {
  changeUserEmailNotifications as changeCurrentUserEmailNotifications,
  changeUserProfileNames as changeCurrentUserProfileNames,
  changeUserProfilePicture as changeCurrentUserProfilePicture,
  fetchCurrentUser as fetchUser,
  saveRoute as saveRouteApi,
  updateFavouritePlaces as updateFavouritePlacesApi,
  updateVisitedPlaces as updateVisitedPlacesApi,
  removeRoute as removeRouteApi,
  changeRouteStatus as changeRouteStatusApi,
  changeRouteTitle as changeRouteTitleApi,
  activeteProfile as activeteProfileApi,
  fetchUsersByIds as fetchUsersByIdsApi,
} from "../api/userAPI";
import { AppState, AppThunk } from "../app/store";
import User from "../entities/User";
import { showFeedbackForm } from "./feedbackSlice";
import { showNotification } from "./notificationSlice";
import { fetchPlacesByIds } from "./placeSlice";
import { ensureRoutesByIds } from "./routeSlice";

export interface UsersState {
  byId: { [key: string]: User.Type };
  fetching: { [key: string]: boolean };
  me?: User.Type;
  status: "idle" | "loading" | "failed";
  profileUpdateStatus:
    | "idle"
    | "names"
    | "profile_picture"
    | "email_notification"
    | "password"
    | "favourite_places"
    | "visited_places"
    | "failed";
}

type FetchUsersPayload = { users: User.Type[] };
type FetchInProgress = { ids: string[] };
type FetchCurrentUserPayload = { user?: User.Type };

const initialState: UsersState = {
  me: undefined,
  byId: {},
  fetching: {},
  status: "idle",
  profileUpdateStatus: "idle",
};

export const fetchCurrentUser = (): AppThunk<Promise<User.Type>> => (
  dispatch,
  _
) => {
  dispatch(setFetchingInProgress({ ids: [] }));
  return new Promise<User.Type>((resolve, reject) => {
    fetchUser()
      .then((user) => {
        dispatch(setCurrentUser({ user }));
        resolve(user);
      })
      .catch((error) => {
        dispatch(setFetchingFailed({ ids: [] }));
        reject(error);
      });
  });
};

export const changeUserProfilePicture = (file: File): AppThunk => (
  dispatch,
  _
) => {
  dispatch(setProfileUpdateStatus("profile_picture"));
  changeCurrentUserProfilePicture(file)
    .then((user) => {
      mixpanel.track("CHANGE_PROFILE_PICTUIRE");
      dispatch(setCurrentUser({ user }));
      dispatch(
        showNotification({
          text: "Профилната снимка беше успешно променена!",
          type: "success",
        })
      );
    })
    .catch((error) => {
      dispatch(
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        })
      );
      dispatch(setProfileUpdateFailed());
    });
};

export const changeUserProfileNames = (
  firstName: string,
  lastName: string
): AppThunk => (dispatch, _) => {
  dispatch(setProfileUpdateStatus("names"));
  changeCurrentUserProfileNames(firstName, lastName)
    .then((user) => {
      mixpanel.track("CHANGE_PROFILE_NAMES");
      dispatch(setCurrentUser({ user }));
      dispatch(
        showNotification({
          text: "Данните бяха успешно променени!",
          type: "success",
        })
      );
    })
    .catch((error) => {
      dispatch(
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        })
      );
      dispatch(setProfileUpdateFailed());
    });
};

export const changeUserEmailNotifications = (
  hasSubscribed: boolean
): AppThunk => (dispatch, _) => {
  dispatch(setProfileUpdateStatus("email_notification"));
  changeCurrentUserEmailNotifications(hasSubscribed)
    .then((user) => {
      mixpanel.track("CHANGE_EMAIL_NOTIFICATION", {
        Subscribed: hasSubscribed,
      });
      dispatch(setCurrentUser({ user }));
      dispatch(
        showNotification({
          text: "Настройките бяха променени успешно!",
          type: "success",
        })
      );
    })
    .catch((error) => {
      dispatch(
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        })
      );
      dispatch(setProfileUpdateFailed());
    });
};

export const updateFavouritePlaces = (
  placeId: string
): AppThunk<Promise<string[]>> => (dispatch, getState) => {
  dispatch(setProfileUpdateStatus("favourite_places"));
  const user = getState().users.me;
  return new Promise<string[]>((resolve, reject) => {
    if (user) {
      let favouritePlaces: string[] = [];
      const isInFavourite = user.favouritePlaces.includes(placeId);
      if (isInFavourite) {
        favouritePlaces = user.favouritePlaces.filter((el) => el !== placeId);
      } else {
        favouritePlaces = [...user.favouritePlaces, placeId];
      }
      const isRemoving = user.favouritePlaces.length > favouritePlaces.length;
      const successText = `Това място беше успешно ${
        isRemoving ? "премахнато от" : "добавено в"
      } любими!`;
      updateFavouritePlacesApi(favouritePlaces)
        .then((user) => {
          mixpanel.track("UPDATE_FAVOURITE_PLACE", {
            Type: isRemoving ? "Remove" : "Add",
          });
          const placesToFetch = isRemoving
            ? [placeId, ...favouritePlaces]
            : [...favouritePlaces];
          dispatch(fetchPlacesByIds(placesToFetch)).then(() => {
            dispatch(setCurrentUser({ user }));
            dispatch(
              showNotification({
                text: successText,
                type: "success",
              })
            );
            if (
              (user.favouritePlaces.length === 3 ||
                user.favouritePlaces.length === 8) &&
              !isRemoving &&
              !user.feedbacks.includes("add_favourite_place")
            ) {
              dispatch(showFeedbackForm("add_favourite_place"));
            }
            resolve(favouritePlaces);
          });
        })
        .catch((error) => {
          dispatch(
            showNotification({
              text: "Промените не бяха записани. Моля, опитай отново!",
              type: "warning",
            })
          );
          dispatch(setProfileUpdateFailed());
          reject(error);
        });
    } else {
      reject();
    }
  });
};

export const updateVisitedPlaces = (
  placeId: string
): AppThunk<Promise<string[]>> => (dispatch, getState) => {
  dispatch(setProfileUpdateStatus("visited_places"));
  const user = getState().users.me;
  return new Promise<string[]>((resolve, reject) => {
    if (user) {
      let visitedPlaces: string[] = [];
      const isVisited = user.visitedPlaces.includes(placeId);
      if (isVisited) {
        visitedPlaces = user.visitedPlaces.filter((el) => el !== placeId);
      } else {
        visitedPlaces = [...user.visitedPlaces, placeId];
      }
      const isRemoving = user.visitedPlaces.length > visitedPlaces.length;
      const successText = `Това място беше успешно ${
        isRemoving ? "премахнато от" : "добавено в"
      } посетени!`;
      updateVisitedPlacesApi(visitedPlaces)
        .then((user) => {
          mixpanel.track("UPDATE_VISITED_PLACE", {
            Type: isRemoving ? "Remove" : "Add",
          });
          const placesToFetch = isRemoving
            ? [placeId, ...visitedPlaces]
            : [...visitedPlaces];
          dispatch(fetchPlacesByIds(placesToFetch)).then(() => {
            dispatch(setCurrentUser({ user }));
            dispatch(
              showNotification({
                text: successText,
                type: "success",
              })
            );
            if (
              (user.visitedPlaces.length === 3 ||
                user.visitedPlaces.length === 8) &&
              !isRemoving &&
              !user.feedbacks.includes("visit_place")
            ) {
              dispatch(showFeedbackForm("visit_place"));
            }
            resolve(visitedPlaces);
          });
        })
        .catch((error) => {
          dispatch(
            showNotification({
              text: "Промените не бяха записани. Моля, опитай отново!",
              type: "warning",
            })
          );
          dispatch(setProfileUpdateFailed());
          reject(error);
        });
    } else {
      reject();
    }
  });
};

export const saveRoute = (
  routeId: string,
  isRandom: boolean
): AppThunk<Promise<void>> => (dispatch) => {
  return new Promise<void>((resolve, reject) => {
    saveRouteApi(routeId, isRandom)
      .then((user) => {
        mixpanel.track("ROUTE_SAVE", {
          Random: isRandom,
          Route: routeId,
        });
        dispatch(
          ensureRoutesByIds(user.savedRoutes.map((el) => el.routeId))
        ).then(() => {
          dispatch(setCurrentUser({ user }));
          dispatch(
            showNotification({
              text: "Маршрутът беше успешно запаметен!",
              type: "success",
            })
          );
          if (isRandom && !user.feedbacks.includes("save_random_route")) {
            dispatch(showFeedbackForm("save_random_route"));
          }
          if (!isRandom && !user.feedbacks.includes("save_generated_route")) {
            dispatch(showFeedbackForm("save_generated_route"));
          }
          resolve();
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeRoute = (routeId: string): AppThunk<Promise<void>> => (
  dispatch
) => {
  return new Promise<void>((resolve, reject) => {
    removeRouteApi(routeId)
      .then((savedRoutes) => {
        mixpanel.track("ROUTE_REMOVE", {
          Route: routeId,
        });
        dispatch(setSavedRoutes(savedRoutes));
        dispatch(
          showNotification({
            text: "Маршрутът беше успешно премахнат!",
            type: "success",
          })
        );
        resolve();
      })
      .catch((error) => {
        dispatch(
          showNotification({
            text: "Промените не бяха записани. Моля, опитай отново!",
            type: "warning",
          })
        );
        reject(error);
      });
  });
};

export const changeRouteStatus = (
  routeId: string,
  status: User.SavedRoute["status"],
  visitAllPlaces: boolean
): AppThunk<Promise<void>> => (dispatch, getState) => {
  return new Promise<void>((resolve, reject) => {
    const savedRoute = getState().users.me?.savedRoutes.find(
      (el) => el.id === routeId
    );
    const route = savedRoute
      ? getState().routes.byId[savedRoute.routeId]
      : null;
    changeRouteStatusApi(routeId, status, visitAllPlaces)
      .then((savedRoutes) => {
        dispatch(setSavedRoutes(savedRoutes));
        if (status === "completed" && visitAllPlaces && savedRoute && route) {
          dispatch(fetchPlacesByIds(route.places));
          dispatch(fetchCurrentUser());
        }
        dispatch(
          showNotification({
            text: `Маршрутът беше успешно отбелязан като ${
              status === "completed" ? "завършен" : "незавършен"
            }!`,
            type: "success",
          })
        );
        resolve();
      })
      .catch((error) => {
        dispatch(
          showNotification({
            text: "Промените не бяха записани. Моля, опитай отново!",
            type: "warning",
          })
        );
        reject(error);
      });
  });
};

export const changeRouteTitle = (
  routeId: string,
  title: string
): AppThunk<Promise<void>> => (dispatch) => {
  return new Promise<void>((resolve, reject) => {
    changeRouteTitleApi(routeId, title)
      .then((savedRoutes) => {
        dispatch(setSavedRoutes(savedRoutes));
        resolve();
      })
      .catch((error) => {
        dispatch(
          showNotification({
            text: "Промените не бяха записани. Моля, опитай отново!",
            type: "warning",
          })
        );
        reject(error);
      });
  });
};

export const activateProfile = (): AppThunk<Promise<void>> => (dispatch) => {
  return new Promise<void>((resolve, reject) => {
    activeteProfileApi()
      .then((user) => {
        dispatch(setCurrentUser({ user }));
        dispatch(
          showNotification({
            text: "Профилът беше успешно активиран!",
            type: "success",
          })
        );
        mixpanel.track("ACTIVATE_PROFILE");
        resolve();
      })
      .catch((error) => {
        dispatch(
          showNotification({
            text: "Промените не бяха записани. Моля, опитай отново!",
            type: "warning",
          })
        );
        reject(error);
      });
  });
};

export const ensureUsersByIds = (ids: string[]): AppThunk<Promise<void>> => (
  dispatch,
  getState
) => {
  const userToFetch = ids.filter((id) => {
    const user = getState().users.byId[id];
    const isFetching = getState().users.fetching[id];
    return !user && !isFetching;
  });
  if (userToFetch.length) {
    return dispatch(fetchUsersByIds(ids));
  }
  return Promise.resolve();
};

export const fetchUsersByIds = (ids: string[]): AppThunk<Promise<void>> => (
  dispatch,
  _
) => {
  dispatch(setFetchingInProgress({ ids }));
  return fetchUsersByIdsApi(ids)
    .then((users) => {
      dispatch(setUsers({ users }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setFetchingFailed({ ids }));
    });
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<FetchCurrentUserPayload>) => {
      state.me = action.payload.user;
      state.status = "idle";
      state.profileUpdateStatus = "idle";
    },
    setUsers: (state, action: PayloadAction<FetchUsersPayload>) => {
      const { users } = action.payload;
      users.forEach((user) => {
        state.byId[user.id] = { ...user };
        state.fetching[user.id] = false;
      });
      state.status = "idle";
    },
    setSavedRoutes: (state, action: PayloadAction<User.SavedRoute[]>) => {
      if (state.me) {
        state.me.savedRoutes = action.payload;
      }
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      if (action && action.payload) {
        const { ids } = action.payload;
        const fetching: { [key: string]: boolean } = {};
        ids.forEach((id) => {
          fetching[id] = true;
        });
        state.fetching = {
          ...state.fetching,
          ...fetching,
        };
      }

      state.status = "loading";
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      if (action && action.payload) {
        const { ids } = action.payload;
        const fetching: { [key: string]: boolean } = {};
        ids.forEach((id) => {
          fetching[id] = false;
        });
        state.fetching = {
          ...state.fetching,
          ...fetching,
        };
      }
      state.status = "failed";
    },
    setProfileUpdateFailed: (state) => {
      state.profileUpdateStatus = "failed";
    },
    setProfileUpdateStatus: (
      state,
      action: PayloadAction<UsersState["profileUpdateStatus"]>
    ) => {
      state.profileUpdateStatus = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setFetchingInProgress,
  setFetchingFailed,
  setProfileUpdateStatus,
  setProfileUpdateFailed,
  setSavedRoutes,
  setUsers,
} = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentUser = (state: AppState) => state.users.me;
export const selectUserById = (state: AppState, id: string) =>
  state.users.byId[id];
export const selectIsFavouritePlace = (state: AppState, placeId: string) =>
  state.users.me?.favouritePlaces.includes(placeId);
export const selectIsVisitedPlace = (state: AppState, placeId: string) =>
  state.users.me?.visitedPlaces.includes(placeId);
export const selectSavedRouteById = (state: AppState, routeId: string) => {
  return state.users.me?.savedRoutes.find((el) => el.id === routeId);
};
export const selectSavedRoutesByStatus = (
  state: AppState,
  status: User.SavedRoute["status"]
) => {
  return state.users.me
    ? state.users.me.savedRoutes.filter((el) => el.status === status)
    : [];
};

export default usersSlice.reducer;
