import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RouteFilterNames } from "../../constants";
import User from "../../entities/User";
import { ensureRoutesByIds } from "../../slices/routeSlice";
import BackButton from "../common/BackButton";
import CustomTabs, { TabOption } from "../common/CustomTabs";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";

const TAB_OPTIONS: TabOption[] = [
  { label: "НЕЗАВЪРШЕНИ", value: "not_started" },
  { label: "ЗАВЪРШЕНИ", value: "completed" },
];

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  tabContainer: {
    maxWidth: 450,
    margin: `${theme.spacing()}px auto`,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  desktopHeader: {
    justifyContent: "center",
    marginBottom: theme.spacing(),
  },
}));

const SavedRoutesOverview: React.FC<WithMediaDownProps> = ({ isMediaDown }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const savedRoutes = useAppSelector((state) =>
    state.users.me ? state.users.me.savedRoutes : []
  );

  const urlSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  useEffect(() => {
    dispatch(ensureRoutesByIds(savedRoutes.map((el) => el.routeId)));
  }, []);

  const handleChangeStatus = useCallback(
    (status: User.SavedRoute["status"]) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set(RouteFilterNames.status, status);
      history.push({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [location]
  );

  return (
    <Container maxWidth="lg" className={classes.container} disableGutters>
      <div
        className={classNames(classes.header, {
          [classes.desktopHeader]: !isMediaDown,
        })}
      >
        {isMediaDown && <BackButton label="Маршрути" />}
        {!isMediaDown && (
          <Typography variant="h1" color="primary">
            Маршрути
          </Typography>
        )}
      </div>
      <div className={classes.tabContainer}>
        <CustomTabs
          value={urlSearchParams.get(RouteFilterNames.status) || "not_started"}
          onChange={(newValue: string) => {
            handleChangeStatus(newValue as User.SavedRoute["status"]);
          }}
          options={TAB_OPTIONS}
        />
      </div>

      {!savedRoutes.length && (
        <Typography variant="h5" color="primary" align="center">
          Все още нямаш запаметени маршрути.
        </Typography>
      )}
      {!!savedRoutes.length && isMediaDown && (
        <MobilePage
          selectedStatus={
            (urlSearchParams.get(
              RouteFilterNames.status
            ) as User.SavedRoute["status"]) || "not_started"
          }
        />
      )}
      {!!savedRoutes.length && !isMediaDown && (
        <DesktopPage
          selectedStatus={
            (urlSearchParams.get(
              RouteFilterNames.status
            ) as User.SavedRoute["status"]) || "not_started"
          }
        />
      )}
    </Container>
  );
};

export default WithMediaDown(SavedRoutesOverview, "md");
