import React from "react";
import MobileProfile from "./MobileProfile";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import DesktopEditProfile from "./DesktopProfile";

const Profile: React.FC<WithMediaDownProps> = ({ isMediaDown }) => {
	if (isMediaDown) {
		return <MobileProfile />;
	}
	return <DesktopEditProfile />;
};

export default WithMediaDown(Profile, "md");
