import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import usersReducer from "../slices/usersSlice";
import registrationReducer from "../slices/registrationSlice";
import blogPostReducer from "../slices/blogPostSlice";
import categoryReducer from "../slices/categorySlice";
import subcategoryReducer from "../slices/subcategorySlice";
import monthlyAccentReducer from "../slices/monthlyAccentSlice";
import regionReducer from "../slices/regionSlice";
import placeReducer from "../slices/placeSlice";
import notificationReducer from "../slices/notificationSlice";
import feedbackReducer from "../slices/feedbackSlice";
import routeReducer from "../slices/routeSlice";

const combinedReducers = combineReducers({
  users: usersReducer,
  blogPosts: blogPostReducer,
  categories: categoryReducer,
  subcategories: subcategoryReducer,
  monthlyAccent: monthlyAccentReducer,
  regions: regionReducer,
  places: placeReducer,
  routes: routeReducer,
  ui: combineReducers({
    registration: registrationReducer,
    notification: notificationReducer,
    feedback: feedbackReducer,
  }),
});

export const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
