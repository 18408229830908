import axios from "axios";
import BlogPost from "../entities/BlogPost";

export function fetchBlogPost(id: string): Promise<BlogPost.Type> {
  return new Promise<BlogPost.Type>((resolve, reject) => {
    axios
      .get(`/blog-post/${id}`)
      .then(({ data }) => {
        resolve(BlogPost.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
