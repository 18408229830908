import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCategoriesByIds as fetchCategories } from "../api/categoryApi";
import { AppState, AppThunk } from "../app/store";
import Category from "../entities/Category";

export interface CategoryState {
  byId: { [key: string]: Category.Type };
  fetching: { [key: string]: boolean };
  status: "idle" | "loading" | "failed";
}

type FetchCategoriesPayload = { categories: Category.Type[] };
type FetchInProgress = { ids: string[] };

const initialState: CategoryState = {
  byId: {},
  fetching: {},
  status: "idle",
};

export const ensureCategoriesByIds = (
  ids: string[]
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const categoriesToFetch = ids.filter((id) => {
    const category = getState().categories.byId[id];
    const isFetching = getState().categories.fetching[id];
    return !category && !isFetching;
  });
  if (categoriesToFetch.length) {
    return dispatch(fetchCategoriesByIds(ids));
  }
  return Promise.resolve();
};

export const fetchCategoriesByIds = (
  ids: string[]
): AppThunk<Promise<void>> => (dispatch, _) => {
  dispatch(setFetchingInProgress({ ids }));
  return fetchCategories(ids)
    .then((categories) => {
      dispatch(setCategories({ categories }));
    })
    .catch((error) => {
      dispatch(setFetchingFailed({ ids }));
    });
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<FetchCategoriesPayload>) => {
      const { categories } = action.payload;
      categories.forEach((category) => {
        state.byId[category.id] = { ...category };
        state.fetching[category.id] = false;
      });
      state.status = "idle";
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = true;
        state.status = "loading";
      });
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = false;
        state.status = "loading";
      });
      state.status = "failed";
    },
  },
});

export const {
  setCategories,
  setFetchingInProgress,
  setFetchingFailed,
} = categorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCategoryById = (state: AppState, id: string) =>
  state.categories.byId[id];

export default categorySlice.reducer;
