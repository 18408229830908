import { useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SIGNUP_PATH, SIGNUP_SUCCESS_PATH } from "../../routes";
import {
  selectRegistrationEmail,
  selectRegistrationPassword,
  setUserSubscriptionMethod,
} from "../../slices/registrationSlice";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import { SignupFormikValues } from "../signup";
import BenefitsOverviewPage from "../subscription/BenefitsOverviewPage";

const BenefitsOverview: React.FC<WithMediaDownProps> = function ({
  isMediaDown,
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { submitForm } = useFormikContext<SignupFormikValues>();

  const emailState = useAppSelector(selectRegistrationEmail);
  const passwordState = useAppSelector(selectRegistrationPassword);

  useEffect(() => {
    if (!emailState || !passwordState) {
      history.push(SIGNUP_PATH);
    }
  }, [emailState, passwordState]);

  const handleSubmitForm = useCallback(() => {
    dispatch(setUserSubscriptionMethod("free"));
    submitForm().then(() => {
      history.push(SIGNUP_SUCCESS_PATH);
    });
  }, [history]);

  return (
    <React.Fragment>
      {!isMediaDown && <BackButton />}
      <BenefitsOverviewPage onSubmitForm={handleSubmitForm} />
    </React.Fragment>
  );
};

export default WithMediaDown(BenefitsOverview, "md");
