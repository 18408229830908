import moment from "moment";
import { FeedbackContextType } from "../slices/feedbackSlice";

export namespace User {
  export type ProviderType = "facebook" | "google" | "local";
  export interface Type {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    activated: boolean;
    profilePicture?: string;
    providerType: ProviderType;
    hasEmailSubscription: boolean;
    hasSubscription: boolean;
    //availableSearchesLeft: number;
    favouritePlaces: string[];
    visitedPlaces: string[];
    savedRoutes: SavedRoute[];
    feedbacks: FeedbackContextType[];
    // subscriptionExpirationDate?: moment.Moment;
    deletedAt?: moment.Moment;
  }

  export const parse = function (data: any): User.Type {
    return {
      id: data.uuid,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      activated: data.activated,
      profilePicture: data.profile_picture,
      providerType: data.provider_type,
      hasEmailSubscription: data.email_subscription,
      hasSubscription: data.has_subscription,
      // availableSearchesLeft: data.available_searches_left,
      favouritePlaces: data.favourite_places
        ? data.favourite_places.map((el: any) => el.uuid)
        : [],
      visitedPlaces: data.visited_places
        ? data.visited_places.map((el: any) => el.uuid)
        : [],
      savedRoutes: data.saved_routes
        ? data.saved_routes.map(parseSavedRoute)
        : [],
      feedbacks: data.feedbacks,
      // subscriptionExpirationDate: data.subscription_expiration_date
      //   ? moment(data.subscription_expiration_date)
      //   : undefined,
      deletedAt: data.deleted_at ? moment(data.deleted_at) : undefined,
    };
  };

  export const parseSavedRoute = function (data: any): SavedRoute {
    return {
      id: data.uuid,
      title: data.title,
      routeId: data.route.uuid,
      visitedPlaces: data.visited_places
        ? data.visited_places.map((el: any) => el.uuid)
        : [],
      status: data.status,
      completedDate: data.completed_date
        ? moment(data.completed_date).locale("bg")
        : undefined,
      activities: data.activities,
    };
  };

  export interface SavedRoute {
    id: string;
    title: string;
    routeId: string;
    visitedPlaces: string[];
    status: "completed" | "partialy_completed" | "not_started";
    completedDate?: moment.Moment;
    activities: string[];
  }

  export enum SavedRouteStatus {
    completed = "completed",
    partialyCompleted = "partialy_completed",
    notStarted = "not_started",
  }
}

export default User;
