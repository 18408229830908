import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";
import { changeUserPassword, deactivateProfile } from "../../api/userAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_CLIENT_ID } from "../../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../constants";
import { BASE_PATH } from "../../routes";
import { showNotification } from "../../slices/notificationSlice";
import {
  changeUserProfileNames,
  selectCurrentUser,
  setCurrentUser,
  setProfileUpdateFailed,
  setProfileUpdateStatus,
} from "../../slices/usersSlice";
import { validatePassword } from "../../validation";
import PasswordStrength from "../common/PasswordStrength";
import UploadImageAvatar from "../common/UploadImageAvatar";

const styles = makeStyles((theme: Theme) => ({
  contentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  cardContainer: {
    padding: `${theme.spacing() * 3}px`,
    backgroundColor: theme.palette.common.white,
    boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
  },
  editNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "40%",
    marginRight: theme.spacing(3),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    height: "100%",
    minHeight: 230,
  },
  avatarContainer: {
    width: 200,
    height: 200,
    position: "relative",
    left: -100,
  },
  passwordContainer: {
    width: "30%",
  },
  deleteButton: {
    maxWidth: 300,
    alignSelf: "center",
  },
  confirmButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const DesktopEditProfile: React.FC = () => {
  const user = useAppSelector(selectCurrentUser);
  const profileUpdateStatus = useAppSelector(
    (state) => state.users.profileUpdateStatus
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = styles();
  const [
    removeProfileConfirmationDialog,
    setRemoveProfileConfirmationDialog,
  ] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(
    user ? user.firstName : ""
  );
  const [lastName, setLastName] = useState<string>(user ? user.lastName : "");
  const [passwordFieldType, setPasswordFieldType] = useState<string>(
    "password"
  );
  const [newPassword, setNewPassword] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const passwordStrengthErrors = useMemo(() => {
    return validationErrors.filter((el) => el !== "required");
  }, [validationErrors]);

  const handleSubmitPasswordForm = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(setProfileUpdateStatus("password"));
      changeUserPassword(newPassword)
        .then((response) => {
          if (response.status === 200) {
            setNewPassword("");
            dispatch(setProfileUpdateStatus("idle"));
            dispatch(
              showNotification({
                text: "Паролата беше променена успешно!",
                type: "success",
              })
            );
          }
        })
        .catch((_) => {
          showNotification({
            text: "Промените не бяха записани. Моля, опитай отново!",
            type: "warning",
          });
          dispatch(setProfileUpdateFailed());
        });
    },
    [newPassword]
  );

  const handleSubmitNamesForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(changeUserProfileNames(firstName, lastName));
    },
    [firstName, lastName]
  );

  const handleDialogClose = useCallback(() => {
    setRemoveProfileConfirmationDialog(false);
  }, []);

  const { signOut } = useGoogleLogout({
    onLogoutSuccess: () => {
      console.log("Logout Sucess!");
    },
    onFailure: () => {
      console.log("Logout Failed!");
    },
    clientId: GOOGLE_CLIENT_ID || "",
  });

  const handleDeactivateProfile = useCallback(() => {
    setRemoveProfileConfirmationDialog(false);
    deactivateProfile()
      .then(() => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(PROVIDER_TYPE_KEY);
        dispatch(setCurrentUser({ user: undefined }));
        if (user?.providerType === "google") {
          signOut();
        }
        mixpanel.track("DEACTIVATE_DESKTOP");
        mixpanel.reset();
        history.replace(BASE_PATH);
        dispatch(
          showNotification({
            text: "Профилът беше успешно деактивиран!",
            type: "success",
            duration: 5000,
          })
        );
      })
      .catch(() => {
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        });
      });
  }, []);

  useEffect(() => {
    setValidationErrors(validatePassword(newPassword));
  }, [newPassword]);
  return (
    <React.Fragment>
      <Typography variant="h1" color="primary" align="center">
        Профилни настройки
      </Typography>
      <div className={classes.contentContainer}>
        <div
          className={classNames(
            classes.cardContainer,
            classes.editNameContainer
          )}
        >
          <div className={classes.avatarContainer}>
            <UploadImageAvatar />
          </div>
          <form
            onSubmit={handleSubmitNamesForm}
            className={classes.formContainer}
          >
            <Typography variant="body2" color="primary">
              Добави снимка, име и фамилия:
            </Typography>
            <TextField
              variant="outlined"
              label="Име"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              helperText=" "
            />
            <TextField
              variant="outlined"
              label="Фамилия"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
              helperText=" "
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !firstName || !lastName || profileUpdateStatus === "names"
              }
            >
              ЗАПАЗИ ПРОМЕНИТЕ
            </Button>
          </form>
        </div>
        <div
          className={classNames(
            classes.cardContainer,
            classes.passwordContainer
          )}
        >
          <form
            className={classes.formContainer}
            onSubmit={handleSubmitPasswordForm}
          >
            <TextField
              name="password"
              label="Въведи нова парола"
              variant="outlined"
              value={newPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewPassword(e.target.value)
              }
              InputProps={{
                inputProps: {
                  type: passwordFieldType,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small">
                      <LockIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (passwordFieldType === "text") {
                          setPasswordFieldType("password");
                        } else {
                          setPasswordFieldType("text");
                        }
                      }}
                    >
                      {passwordFieldType === "text" ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <PasswordStrength errors={passwordStrengthErrors} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !newPassword ||
                !!passwordStrengthErrors.length ||
                profileUpdateStatus === "password"
              }
            >
              ЗАПАЗИ ПРОМЕНИТЕ
            </Button>
          </form>
        </div>
      </div>
      <Button
        className={classes.deleteButton}
        variant="text"
        onClick={() => {
          setRemoveProfileConfirmationDialog(true);
        }}
      >
        <Typography variant="body1" color="error">
          ДЕАКТИВИРАЙ ПРОФИЛА
        </Typography>
      </Button>
      <Dialog
        open={removeProfileConfirmationDialog}
        onClose={(_, reson) => {
          if (reson !== "backdropClick") {
            handleDialogClose();
          }
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant="h5" color="primary">
            Деактивиране на профила
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Сигурен ли си, че искаш да деактивираш профила си?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDialogClose}
          >
            Затвори
          </Button>
          <Button
            className={classes.confirmButton}
            variant="contained"
            onClick={handleDeactivateProfile}
            autoFocus
          >
            Деактивирай
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DesktopEditProfile;
