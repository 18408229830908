export namespace Category {
  export interface Type {
    id: string;
    name: string;
  }

  export const parse = function (data: any): Category.Type {
    return {
      id: data.uuid,
      name: data.name,
    };
  };
}

export default Category;
