import axios from "axios";
import moment from "moment";
import MonthlyAccent from "../entities/MonthlyAccent";

export function fetchMonthlyAccent(): Promise<MonthlyAccent.Type> {
  return new Promise<MonthlyAccent.Type>((resolve, reject) => {
    /**
     * Regarding this documentation here: https://momentjs.com/docs/#/get-set/month/
     * moment months are zero indexed, so January is month 0.
     */
    const month = moment().month() + 1;
    axios
      .get(`/monthly-accent?month=${month}`)
      .then(({ data }) => {
        resolve(MonthlyAccent.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
