import { Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const getDividerProperties = (theme: Theme): CSSProperties => ({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  padding: `0 ${theme.spacing() * 2}px `,
  "&:after, &:before": {
    content: "''",
    flex: 1,
    borderBottom: "1px solid #00000099",
    margin: `0 ${theme.spacing()}px `,
  },
});

// const routeTitleOverflowProperties = (): CSSProperties => ({
//   boxSizing: "border-box",
//   position: "relative",
//   maxHeight: 55,
//   overflow: "hidden",
//   "&::after": {
//     content: '"..."',
//     position: "absolute",
//     top: "45%",
//     left: "90%",
//     width: 28,
//     textAlign: "right",
//   },
// });

export const getCommonStyles = (theme: Theme) => ({
  divider: getDividerProperties(theme),
  // routeTitleOverflow: routeTitleOverflowProperties(),
});
