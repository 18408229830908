export namespace Region {
  export interface Type {
    id: string;
    name: string;
    location: {
      longitude: number;
      latitude: number;
    };
  }

  export const parse = function (data: any): Region.Type {
    return {
      id: data.uuid,
      name: data.name,
      location: data.location,
    };
  };
}

export default Region;
