import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ROUTE_OVERVIEW_PATH } from "../../routes";
import {
  changeRouteStatus,
  removeRoute,
  selectSavedRouteById,
} from "../../slices/usersSlice";
import { getCommonStyles } from "../../style";
import RemoveRouteConfirmationDialog from "../common/RemoveRouteConfirmationDialog";
import RouteCompleteConfirmationDialog from "../common/RouteCompleteConfirmationDialog";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import { getCommonCardStyles } from "../routeCardStyle";

const styles = makeStyles((theme: Theme) => ({
  ...getCommonCardStyles(theme),
  ...getCommonStyles(theme),
  ownCardRoot: {
    minHeight: 300,
  },
}));

interface OwnProps {
  routeId: string;
}

const MobileCard: React.FC<OwnProps> = ({ routeId }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector((state) => state.users.me);
  const savedRoute = useAppSelector((state) =>
    selectSavedRouteById(state, routeId)
  );
  const route = useAppSelector((state) =>
    savedRoute ? state.routes.byId[savedRoute.routeId] : null
  );
  const firstPlace = useAppSelector((state) =>
    route ? state.places.byId[route.places[0]] : null
  );

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [
    removeConfirmationDialogOpen,
    setRemoveConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [
    visitAllPlacesDialogOpen,
    setVisitAllPlacesDialogOpen,
  ] = useState<boolean>(false);
  const open = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);
  const isCompletedRoute = useMemo(() => {
    return (
      savedRoute &&
      savedRoute.status === "completed" &&
      !!savedRoute.completedDate
    );
  }, [savedRoute]);
  const id = useMemo(() => (open ? "simple-popover" : undefined), [open]);

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setMenuAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMenuClose = useCallback((event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setMenuAnchorEl(null);
  }, []);

  const handleRemoveRoute = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setRemoveConfirmationDialogOpen(false);
      if (savedRoute) {
        setLoading(true);
        dispatch(removeRoute(savedRoute.id)).then(() => {
          setLoading(false);
        });
      }
    },
    [savedRoute]
  );

  const handleNotCompleteRoute = useCallback(() => {
    if (savedRoute) {
      setLoading(true);
      dispatch(changeRouteStatus(savedRoute.id, "not_started", false)).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [savedRoute]);

  const handleCompleteRoute = useCallback(
    (visitAllPlaces: boolean) => {
      if (savedRoute) {
        setLoading(true);
        dispatch(changeRouteStatus(savedRoute.id, "completed", visitAllPlaces))
          .then(() => {
            setLoading(false);
            setVisitAllPlacesDialogOpen(false);
          })
          .catch(() => {
            setLoading(false);
            setVisitAllPlacesDialogOpen(false);
          });
      }
    },
    [savedRoute]
  );

  const handleRouteStatusChange = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      handleMenuClose(event);
      let areAllVisited = false;
      if (isCompletedRoute) {
        handleNotCompleteRoute();
      } else if (route && user) {
        areAllVisited = route.places.every((el) =>
          user.visitedPlaces.includes(el)
        );
        if (areAllVisited) {
          handleCompleteRoute(false);
        } else {
          setVisitAllPlacesDialogOpen(true);
        }
      }
      mixpanel.track("ROUTE_STATUS_CHANGE", {
        From: "Mobile Card",
        "Mark As": isCompletedRoute ? "Not Completed" : "Completed",
        "All visited": areAllVisited,
      });
    },
    [user, user?.visitedPlaces, route, route?.places, isCompletedRoute]
  );

  const handleRoutePreview = useCallback(() => {
    history.push(ROUTE_OVERVIEW_PATH.replace(":id", routeId));
  }, []);

  return (
    <WithLoadingBackdrop open={loading}>
      <Card
        onClick={handleRoutePreview}
        className={classNames(
          classes.cardRoot,
          classes.mobileCardRoot,
          classes.ownCardRoot
        )}
      >
        {savedRoute ? (
          <CardMedia
            className={classes.cardMedia}
            image={firstPlace?.coverPhoto}
          >
            <CardHeader
              classes={{
                root: classes.cardHeader,
                action: classes.cardAction,
              }}
              title={
                <Typography variant="h5">
                  {savedRoute ? savedRoute.title : "..."}
                </Typography>
              }
              subheader={
                isCompletedRoute ? (
                  <Typography variant="body1">
                    Завършен на:{" "}
                    {savedRoute.completedDate &&
                      savedRoute.completedDate.format("DD MMMM YYYY")}
                  </Typography>
                ) : null
              }
              action={
                <IconButton
                  aria-describedby={id}
                  size="small"
                  color="inherit"
                  onClick={handleMenuOpen}
                >
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <div className={classes.seeAccentContainer}>
              <IconButton onClick={handleRoutePreview} color="inherit">
                <Typography variant="body2">РАЗГЛЕДАЙ</Typography>
              </IconButton>
            </div>
          </CardMedia>
        ) : (
          <CircularProgress className={classes.loading} size={80} />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={menuAnchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleRouteStatusChange}>
            <LocationOnIcon color="primary" />
            <Typography variant="body1" color="primary">
              Oтбележи като {isCompletedRoute ? "непосетен" : "посетен"}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              handleMenuClose(event);
              setRemoveConfirmationDialogOpen(true);
            }}
          >
            <CloseIcon color="error" />
            <Typography variant="body1" color="error">
              Изтрий маршрут
            </Typography>
          </MenuItem>
        </Popover>
      </Card>
      <RouteCompleteConfirmationDialog
        open={visitAllPlacesDialogOpen}
        onConfirm={() => {
          handleCompleteRoute(true);
        }}
        onClose={() => {
          handleCompleteRoute(false);
          setVisitAllPlacesDialogOpen(false);
        }}
      />
      <RemoveRouteConfirmationDialog
        open={removeConfirmationDialogOpen}
        onConfirm={handleRemoveRoute}
        onClose={() => {
          setRemoveConfirmationDialogOpen(false);
        }}
      />
    </WithLoadingBackdrop>
  );
};

export default MobileCard;
