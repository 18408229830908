import axios from "axios";
import Region from "../entities/Region";

export function fetchRegionsByIds(ids: string[]): Promise<Region.Type[]> {
  return new Promise<Region.Type[]>((resolve, reject) => {
    axios
      .get(`/regions/?ids=${ids.join("&ids=")}`)
      .then(({ data: { result } }) => {
        resolve(result.map(Region.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchRegionIds(): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get("/regions/?include_fields=id")
      .then(({ data: { result } }) => {
        resolve(result.map((el: any) => el.uuid));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
