import MuiSnackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { hideNotification } from "../../slices/notificationSlice";

const styles = makeStyles((theme: Theme) => ({
  snackbarRoot: {
    zIndex: 9999,
  },
  alertRoot: {
    alignItems: "center",
  },
}));

const Snackbar: React.FC = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { type, text, duration } = useAppSelector(
    (state) => state.ui.notification
  );
  const [open, setOpen] = React.useState<boolean>(!!text && !!type);
  useEffect(() => {
    setOpen(!!text && !!type);
  }, [text, type]);
  const handleClose = useCallback(
    (_: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      dispatch(hideNotification());
      setOpen(false);
    },
    []
  );
  return (
    <MuiSnackbar
      className={classes.snackbarRoot}
      open={open}
      autoHideDuration={duration || 3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        className={classes.alertRoot}
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}
      >
        {text}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
