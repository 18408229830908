import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { LOGIN_PATH } from "../routes";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  logo: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  button: {
    marginBottom: theme.spacing() * 2,
  },
}));

const SuccessfulPasswordReset: React.FC<
  RouteComponentProps & WithMediaDownProps
> = function ({ history, isMediaDown }) {
  const classes = styles();

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <div>
        <div className={classes.logo}>
          <Logo size={isMediaDown ? "md" : "lg"} />
        </div>
        <Typography variant="h1" color="primary" className={classes.title}>
          Чудесно
        </Typography>
      </div>
      <Typography variant="button" color="primary">
        Ти успешно промени паролата си. Моля, влез отново в профила си!
      </Typography>
      <Button
        className={classes.button}
        onClick={() => {
          history.replace(LOGIN_PATH);
        }}
        variant="contained"
        color="primary"
        fullWidth
      >
        Влез
      </Button>
    </Container>
  );
};

export default WithMediaDown(SuccessfulPasswordReset, "md");
