import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSubcategoriesByIds as fetchSubcategories } from "../api/subcategoryAPI";
import { AppState, AppThunk } from "../app/store";
import SubCategory from "../entities/SubCategory";
import { ensureCategoriesByIds } from "./categorySlice";

export interface SubcategoryState {
  byId: { [key: string]: SubCategory.Type };
  fetching: { [key: string]: boolean };
  status: "idle" | "loading" | "failed";
}

type FetchSubCategoriesPayload = { subcategories: SubCategory.Type[] };
type FetchInProgress = { ids: string[] };

const initialState: SubcategoryState = {
  byId: {},
  fetching: {},
  status: "idle",
};

export const ensureSubcategoriesByIds = (
  ids: string[]
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const subcategoriesToFetch = ids.filter((id) => {
    const subcategory = getState().subcategories.byId[id];
    const isFetching = getState().subcategories.fetching[id];
    return !subcategory && !isFetching;
  });
  if (subcategoriesToFetch.length) {
    return dispatch(fetchSubcategoriesByIds(subcategoriesToFetch));
  }
  return Promise.resolve();
};

export const fetchSubcategoriesByIds = (
  ids: string[]
): AppThunk<Promise<void>> => (dispatch, _) => {
  dispatch(setFetchingInProgress({ ids }));
  return fetchSubcategories(ids)
    .then((subcategories) => {
      dispatch(ensureCategoriesByIds(subcategories.map((el) => el.categoryId)));
      dispatch(setSubcategories({ subcategories }));
    })
    .catch((error) => {
      dispatch(setFetchingFailed({ ids }));
    });
};

export const subcategorySlice = createSlice({
  name: "subcategories",
  initialState,
  reducers: {
    setSubcategories: (
      state,
      action: PayloadAction<FetchSubCategoriesPayload>
    ) => {
      const { subcategories } = action.payload;
      subcategories.forEach((subcategory) => {
        state.byId[subcategory.id] = { ...subcategory };
        state.fetching[subcategory.id] = false;
      });
      state.status = "idle";
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = true;
        state.status = "loading";
      });
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = false;
        state.status = "loading";
      });
      state.status = "failed";
    },
  },
});

export const {
  setSubcategories,
  setFetchingInProgress,
  setFetchingFailed,
} = subcategorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSubcategoryById = (state: AppState, id: string) =>
  state.subcategories.byId[id];

export default subcategorySlice.reducer;
