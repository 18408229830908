import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios, { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../../constants";
import {
  APPLICATION_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
} from "../../routes";
import { getCommonStyles } from "../../style";
import { validateEmail } from "../../validation";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import Logo from "../common/Logo";
import { getSignupStyles } from "../common/signupStyle";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  basicInput: {
    marginBottom: theme.spacing(),
  },
  infoText: {
    marginTop: theme.spacing(),
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
  },
}));

const PasswordResetPage: React.FC<
  RouteComponentProps & WithMediaDownProps
> = function ({ history, isMediaDown }) {
  const [responseError, setResponseError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const classes = styles();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      history.replace(APPLICATION_PATH);
    }
  }, []);

  const handleSubmitReset = useCallback(() => {
    if (emailError) {
      return;
    }
    setIsSubmitting(true);
    setResponseError("");
    axios
      .post("/password-reset", { email })
      .then(() => {
        setIsSubmitting(false);
        history.push(RESET_PASSWORD_SUCCESS_PATH);
      })
      .catch((error: AxiosError) => {
        const { response } = error;
        setIsSubmitting(false);
        setResponseError(
          response?.data.message || "Нещо се обърка. Моля, опитай отново!"
        );
      });
  }, [email, emailError]);

  return (
    <React.Fragment>
      {!isMediaDown && <BackButton />}
      <WithLoadingBackdrop open={isSubmitting}>
        <Container
          maxWidth={false}
          disableGutters
          className={classes.container}
        >
          <div className={classes.titleContainer}>
            {isMediaDown && <BackButton />}
            <Logo size={isMediaDown ? "md" : "lg"} />
            <Typography
              variant="h1"
              color="primary"
              className={classes.title}
              align="center"
            >
              Забравена парола
            </Typography>
          </div>
          <div className={classes.basicCredentialsContainer}>
            <TextField
              className={classes.basicInput}
              label="Въведи е-поща"
              error={!!emailError || !!responseError}
              helperText={emailError || responseError || " "}
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setEmailError("");
                setEmail(event.target.value);
                setResponseError("");
              }}
              onBlur={() => {
                const validationResponse = validateEmail(email);
                if (validationResponse.length) {
                  if (validationResponse.length === 1) {
                    setEmailError(validationResponse[0]);
                  } else {
                    setEmailError(
                      validationResponse[validationResponse.length - 1]
                    );
                  }
                } else {
                  setEmailError("");
                }
              }}
              InputProps={{
                inputProps: {
                  type: "email",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleSubmitReset}
              variant="contained"
              color="primary"
              fullWidth
              disabled={!email}
            >
              Продължи
            </Button>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              className={classes.infoText}
            >
              Не се притеснявай! Ще ти изпратим линк за смяна на паролата по
              имейл.
            </Typography>
          </div>
          <div className={classes.footerContainer}>
            <Typography variant="body2" color="primary">
              Помниш си паролата?&nbsp;
              <Link to={LOGIN_PATH}>
                <Typography variant="body1" component="span" color="primary">
                  Влез
                </Typography>
              </Link>
            </Typography>
          </div>
        </Container>
      </WithLoadingBackdrop>
    </React.Fragment>
  );
};

export default withRouter(WithMediaDown(PasswordResetPage, "md"));
