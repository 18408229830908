import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { checkResetPasswordTokenExisting } from "../api/authAPI";
import {
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_CONFIRMATION_SUCCESS_PATH,
} from "../routes";
import { validatePassword } from "../validation";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";
import PasswordStrength from "./common/PasswordStrength";
import WithLoadingBackdrop from "./common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  logo: {
    margin: `${theme.spacing() * 2}px 0`,
  },
  footerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing() * 2,
  },
}));

type ComponentProps = RouteComponentProps<
  { userId: string; token: string },
  StaticContext,
  {}
> &
  WithMediaDownProps;

const PasswordResetConfirmation: React.FC<ComponentProps> = function ({
  history,
  match,
  isMediaDown,
}) {
  const classes = styles();

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [tokenInvalid, setTokenInvalid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const passwordStrengthErrors = useMemo(() => {
    return validationErrors.filter((el) => el !== "required");
  }, [validationErrors]);

  useEffect(() => {
    const userId = match.params.userId;
    const token = match.params.token;
    if (!userId || !token) {
      history.replace(FORGOT_PASSWORD_PATH);
    } else {
      checkResetPasswordTokenExisting(token).then((existing) => {
        setTokenInvalid(!existing);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setValidationErrors(validatePassword(password));
  }, [password]);

  const handleSubmitReset = useCallback(() => {
    if (tokenInvalid) {
      history.replace(LOGIN_PATH);
    } else {
      const userId = match.params.userId;
      const token = match.params.token;
      axios
        .post("/password-reset/submit", {
          user_id: userId,
          password,
          token,
        })
        .then(() => {
          history.replace(RESET_PASSWORD_CONFIRMATION_SUCCESS_PATH);
        });
    }
  }, [password, tokenInvalid]);
  return (
    <WithLoadingBackdrop open={loading}>
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.logo}>
            <Logo size={isMediaDown ? "md" : "lg"} />
          </div>
          <Typography variant="h1" color="primary" className={classes.title}>
            {tokenInvalid ? "Грешка" : "Въведи парола"}
          </Typography>
        </div>
        <div className={classes.content}>
          {tokenInvalid ? (
            <Typography variant="button" color="primary">
              Този линк вече е бил използван!
            </Typography>
          ) : (
            <>
              <TextField
                label="Въведи парола"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  inputProps: {
                    type: showPassword ? "text" : "password",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faLock} />
                    </InputAdornment>
                  ),
                  endAdornment: password ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <PasswordStrength errors={passwordStrengthErrors} />
            </>
          )}
        </div>
        <div className={classes.footerContainer}>
          <Button
            onClick={handleSubmitReset}
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              tokenInvalid
                ? false
                : !password || !!passwordStrengthErrors.length
            }
          >
            {tokenInvalid ? "Начало" : "Продължи"}
          </Button>
        </div>
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(PasswordResetConfirmation, "md");
