import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import User from "../../entities/User";
import { selectSavedRoutesByStatus } from "../../slices/usersSlice";
import DesktopCard from "./DesktopCard";

const styles = makeStyles((theme: Theme) => ({
  container: {
    overflow: "auto",
  },
  item: {
    margin: `${theme.spacing() / 2}px 0`,
  },
  noRoutesText: {
    margin: "0 auto",
  },
}));

interface OwnProps {
  selectedStatus: User.SavedRoute["status"];
}

const DesktopPage: React.FC<OwnProps> = ({ selectedStatus }) => {
  const classes = styles();
  const routes = useAppSelector((state) =>
    selectSavedRoutesByStatus(state, selectedStatus)
  );
  return (
    <Grid className={classes.container} container>
      {routes.map((el) => (
        <Grid item xs={12} key={el.id} className={classes.item}>
          <DesktopCard routeId={el.id} />
        </Grid>
      ))}
      {!routes.length && (
        <Typography
          className={classes.noRoutesText}
          variant="h5"
          color="primary"
          align="center"
        >
          Нямаш {selectedStatus === "completed" ? "завършени" : "незавършени"}{" "}
          маршрути.
        </Typography>
      )}
    </Grid>
  );
};

export default DesktopPage;
