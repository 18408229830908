import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PLACE_OVERVIEW_PATH } from "../../routes";
import { selectCategoryById } from "../../slices/categorySlice";
import { ensurePlacesByIds, selectPlaceById } from "../../slices/placeSlice";
import { selectRegionById } from "../../slices/regionSlice";
import {
  selectIsFavouritePlace,
  selectIsVisitedPlace,
  updateFavouritePlaces,
  updateVisitedPlaces,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 130,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    overflow: "hidden",
    flex: 1,
    "&:last-child": {
      padding: `0 ${theme.spacing()}px`,
    },
  },
  actionArea: {
    width: "auto",
  },
  media: {
    width: 115,
    height: 130,
  },
  desktopMedia: {
    width: 130,
    height: 130,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconButton: {
    flex: 1,
    padding: `0 ${theme.spacing() / 2}px`,
    height: "100%",
  },
  iconButtonLabel: {
    flexDirection: "column",
  },
  favouriteIcon: {
    color: theme.palette.info.main,
  },
  category: {
    fontSize: 12,
  },
  title: {
    lineHeight: "normal",
  },
}));

interface OwnProps {
  placeId: string;
  isSelected: boolean;
  disableSelection: boolean;
  onPlaceSelect: () => void;
  onPlaceRemove: () => void;
  onChangeFavourite?: () => void;
  onChangeVisited?: () => void;
}

type ComponentProps = OwnProps & WithMediaDownProps;

const PlaceCard: React.FC<ComponentProps> = ({
  placeId,
  isSelected,
  disableSelection,
  isMediaDown,
  onPlaceSelect,
  onPlaceRemove,
  onChangeVisited,
  onChangeFavourite,
}) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isVisited = useAppSelector((state) =>
    selectIsVisitedPlace(state, placeId)
  );
  const isInFavourite = useAppSelector((state) =>
    selectIsFavouritePlace(state, placeId)
  );
  const place = useAppSelector((state) => selectPlaceById(state, placeId));
  const placeRegion = useAppSelector((state) =>
    selectRegionById(state, place ? place.regionId : "")
  );
  const placeCategory = useAppSelector((state) =>
    selectCategoryById(state, place ? place.categoryId : "")
  );
  useEffect(() => {
    if (!place) {
      dispatch(ensurePlacesByIds([placeId]));
    }
  }, []);

  const handleFavouriteClick = useCallback(() => {
    dispatch(updateFavouritePlaces(placeId)).then(() => {
      if (onChangeFavourite) onChangeFavourite();
    });
  }, []);
  const handleVisitedClick = useCallback(() => {
    dispatch(updateVisitedPlaces(placeId)).then(() => {
      if (onChangeVisited) onChangeVisited();
    });
  }, []);

  const handlePlacePreview = useCallback(() => {
    history.push(PLACE_OVERVIEW_PATH.replace(":id", placeId));
  }, []);
  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea
        className={classes.actionArea}
        onClick={handlePlacePreview}
      >
        {place ? (
          <CardMedia
            className={classNames(classes.media, {
              [classes.desktopMedia]: !isMediaDown,
            })}
            image={place.coverPhoto}
            title="Cover photo"
          />
        ) : (
          <CircularProgress />
        )}
      </CardActionArea>
      <CardContent className={classes.content}>
        {place && placeRegion ? (
          <>
            <Typography className={classes.title} variant="button" noWrap>
              {place.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Област {placeRegion.name}
            </Typography>
            {placeCategory && (
              <Typography
                variant="body2"
                className={classes.category}
                color="primary"
              >
                {placeCategory.name}
              </Typography>
            )}
            <div className={classes.actions}>
              <IconButton
                onClick={handleFavouriteClick}
                classes={{
                  label: classes.iconButtonLabel,
                  root: classes.iconButton,
                }}
              >
                {isInFavourite ? (
                  <FavoriteIcon className={classes.favouriteIcon} />
                ) : (
                  <FavoriteBorderIcon />
                )}
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classNames({
                    [classes.favouriteIcon]: isInFavourite,
                  })}
                >
                  Любими
                  {/* {isInFavourite ? "Премахни от любими" : "Добави в любими"} */}
                </Typography>
              </IconButton>
              <IconButton
                onClick={handleVisitedClick}
                classes={{
                  label: classes.iconButtonLabel,
                  root: classes.iconButton,
                }}
              >
                {isVisited ? (
                  <LocationOnIcon color="primary" />
                ) : (
                  <LocationOnOutlinedIcon />
                )}
                <Typography
                  variant="subtitle1"
                  color={isVisited ? "primary" : "textSecondary"}
                >
                  Посетени
                  {/* {isVisited ? "Премахни от посетени" : "Добави в посетени"} */}
                </Typography>
              </IconButton>
              <IconButton
                disabled={disableSelection && !isSelected}
                classes={{
                  label: classes.iconButtonLabel,
                  root: classes.iconButton,
                }}
                onClick={isSelected ? onPlaceRemove : onPlaceSelect}
              >
                {isSelected ? (
                  <CheckCircleOutlineIcon color="primary" />
                ) : (
                  <AddIcon color={disableSelection ? "inherit" : "primary"} />
                )}
                <Typography
                  variant="subtitle1"
                  color={
                    !isSelected && disableSelection
                      ? "textSecondary"
                      : "primary"
                  }
                >
                  {isSelected ? "Премахни" : "Добави"}
                </Typography>
              </IconButton>
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </CardContent>
    </Card>
  );
};

export default WithMediaDown(PlaceCard, "md");
