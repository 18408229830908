import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PlaceRequest, submitPlaceRequest } from "../../api/userAPI";
import { useAppDispatch } from "../../app/hooks";
import { APP_TERMS_AND_CONDITIONS_PATH } from "../../routes";
import { showNotification } from "../../slices/notificationSlice";
import AutocompleteOptionRow from "./AutocompleteOptionRow";
import { AutocompleteOption } from "./AutocompleteTextField";
import BackButton from "./BackButton";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";
import WithLoadingBackdrop from "./WithLoadingBackdrop";

let autocompleteService: google.maps.places.AutocompleteService;

const styles = makeStyles((theme: Theme) => ({
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    overflow: "hidden",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  itemSpacing: {
    margin: `${theme.spacing()}px 0`,
  },
  imageContainer: {
    position: "relative",
    display: "inline",
  },
  imageItem: {
    width: "20%",
    height: "100%",
    padding: theme.spacing(),
  },
  clearImage: {
    position: "absolute",
    bottom: -theme.spacing(),
    right: -theme.spacing(),
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  desktopHeader: {
    justifyContent: "center",
  },
  autocompleteInputRoute: {
    padding: `${theme.spacing()}px !important`,
  },
  disclaimer: {
    color: theme.palette.info.main,
    marginBottom: theme.spacing(),
  },
  desktopDisclaimer: {
    color: theme.palette.common.white,
  },
  inputElement: {
    borderColor: "#afa8a8",
  },
}));

const AddPlacePage: React.FC<WithMediaDownProps> = ({ isMediaDown }) => {
  const classes = styles();
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [location, setLocation] = useState<{
    longitude: number;
    latitude: number;
  }>();
  const [destination, setDestination] = useState<string>("");
  const [pictures, setPictures] = useState<File[]>([]);
  const [predictions, setPredictions] = useState<AutocompleteOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption>();

  useEffect(() => {
    if (destination && destination.length >= 3) {
      if (!autocompleteService) {
        autocompleteService = new google.maps.places.AutocompleteService();
      }
      autocompleteService
        .getPlacePredictions({
          input: destination,
          types: ["geocode", "establishment"],
          componentRestrictions: { country: "bg" },
        })
        .then((response) => {
          const result = [
            ...response.predictions.map((el) => ({
              text: el.description,
              placeId: el.place_id,
            })),
          ];
          setPredictions(result);
        });
    }
    if (!destination) {
      setPredictions([]);
    }
  }, [destination]);

  const handleChangeImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const files = event.target.files;
      if (files) {
        const newPhotos = [...pictures];
        Object.keys(files).forEach((key) => {
          const el = files ? files[Number(key)] : null;
          if (el) {
            newPhotos.push(el);
          }
        });
        setPictures(newPhotos);
      }
    },
    [pictures]
  );

  const handleSubmitRequest = useCallback(() => {
    setSubmitting(true);
    const request: PlaceRequest = {
      name,
      pictures: pictures,
    };
    if (description) {
      request["description"] = description;
    }
    if (location) {
      request["location"] = location;
    }
    if (destination) {
      request["destination"] = destination;
    }
    submitPlaceRequest(request)
      .then(() => {
        dispatch(
          showNotification({
            text: "Сърдечни благодарности! Обектът беше успешно изпратен за ревю.",
            type: "success",
            duration: 5000,
          })
        );
        mixpanel.track("ADD_PLACE_SUBMIT", {
          "Place Name": name,
          Pictures: pictures.length,
          Location: location || "N/A",
          Destination: destination || "N/A",
        });
        resetState();
      })
      .catch(() => {
        resetState();
      });
  }, [name, description, location, destination, pictures]);

  const resetState = useCallback(() => {
    setName("");
    setDestination("");
    setPictures([]);
    setLocation(undefined);
    setDescription("");
    setTermsAccepted(false);
    setSubmitting(false);
  }, []);

  const handleRemoveImage = useCallback(
    (index: number) => {
      const result = [...pictures];
      result.splice(index, 1);
      setPictures(result);
    },
    [pictures]
  );

  const handleSelectOption = useCallback((option: AutocompleteOption) => {
    if (option && option.placeId) {
      const result: AutocompleteOption = {
        text: option.text,
        placeId: option.placeId,
      };
      if (option.location) {
        setLocation({
          latitude: option.location.lat(),
          longitude: option.location.lng(),
        });
      }
      setDestination(option.text);
      setSelectedOption(result);
    }
  }, []);

  return (
    <WithLoadingBackdrop open={submitting}>
      <Container className={classes.container} disableGutters maxWidth="sm">
        <div>
          <input
            className={classes.input}
            type="file"
            ref={inputFile}
            onChange={handleChangeImage}
            multiple
          />
          <div>
            {isMediaDown && <BackButton />}
            <Typography
              gutterBottom
              variant={isMediaDown ? "h3" : "h1"}
              color="primary"
              align="center"
            >
              Добави обект
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <TextField
            className={classes.itemSpacing}
            label="Име на обект"
            variant="outlined"
            value={name}
            fullWidth
            onChange={(e) => {
              setName(e.target.value);
            }}
            InputProps={{
              classes: { notchedOutline: classes.inputElement },
              startAdornment: (
                <InputAdornment position="start">
                  <EditIcon
                    fontSize={isMediaDown ? "small" : "medium"}
                    color="primary"
                  />
                </InputAdornment>
              ),
              endAdornment: name ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setName("");
                    }}
                  >
                    <CancelOutlinedIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Autocomplete
            classes={{ inputRoot: classes.autocompleteInputRoute }}
            fullWidth
            freeSolo
            value={selectedOption}
            inputValue={destination}
            options={predictions}
            getOptionLabel={(option) => option.text}
            onChange={(_, newValue) => {
              handleSelectOption(newValue as AutocompleteOption);
            }}
            blurOnSelect
            renderOption={(option) => {
              return <AutocompleteOptionRow option={option} />;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.itemSpacing}
                label="Локация на обект"
                placeholder="Въведи поне три символа..."
                variant="outlined"
                value={destination}
                fullWidth
                onChange={(e) => {
                  setDestination(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.inputElement },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddLocationIcon
                        fontSize={isMediaDown ? "small" : "medium"}
                        color="primary"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: destination ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setDestination("");
                        }}
                      >
                        <CancelOutlinedIcon color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
          <Button
            className={classes.itemSpacing}
            variant="outlined"
            color="primary"
            disabled={pictures.length === 5}
            fullWidth
            onClick={() => {
              inputFile.current && inputFile.current.click();
            }}
          >
            ДОБАВИ СНИМКИ
          </Button>
          {!!pictures.length && (
            <div>
              {pictures.map((picture, idx) => (
                <div key={idx} className={classes.imageContainer}>
                  <img
                    className={classes.imageItem}
                    src={URL.createObjectURL(picture)}
                  />
                  <IconButton
                    onClick={() => handleRemoveImage(idx)}
                    className={classes.clearImage}
                  >
                    <CancelOutlinedIcon color="error" />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
          {/* <Button
            className={classes.itemSpacing}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {}}
          >
            ПОСОЧИ НА КАРТАТА
          </Button> */}
          <TextField
            InputProps={{ classes: { notchedOutline: classes.inputElement } }}
            className={classes.itemSpacing}
            label="Добави описание"
            variant="outlined"
            value={description}
            fullWidth
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            multiline
            minRows={8}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                name="termsAndConditions"
                color={isMediaDown ? "primary" : "secondary"}
              />
            }
            label={
              <Link to={APP_TERMS_AND_CONDITIONS_PATH}>
                <Typography
                  variant="overline"
                  color={isMediaDown ? "primary" : "secondary"}
                >
                  Приемам <u>условията за ползване</u>
                </Typography>
              </Link>
            }
          />
          <Typography
            component="div"
            className={classNames(classes.disclaimer, {
              [classes.desktopDisclaimer]: !isMediaDown,
            })}
            variant="caption"
          >
            *Моля, добавяйте само ваши авторски снимки.
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!name || !pictures.length || !termsAccepted || submitting}
            onClick={handleSubmitRequest}
          >
            {submitting ? "ДОБАВЯНЕ..." : "ДОБАВИ ОБЕКТ"}
          </Button>
        </div>
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(AddPlacePage, "md");
