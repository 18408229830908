export const BASE_PATH = "/";
export const APPLICATION_PATH = "/app";
export const REACTIVATE_USER_PATH = `${APPLICATION_PATH}/re-activate`;
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const SIGNUP_PASSWORD_PATH = `${SIGNUP_PATH}/password`;
export const SIGNUP_SUBSCRIPTION_BENEFITS_PATH = `${SIGNUP_PATH}/subscription-benefits`;
export const LOGIN_SUBSCRIPTION_BENEFITS_PATH = `${LOGIN_PATH}/subscription-benefits`;
export const SIGNUP_SUBSCRIPTIONS_PATH = `${SIGNUP_PATH}/subscriptions`;
export const SIGNUP_SUBSCRIPTION_OVERVIEW_PATH = `${SIGNUP_PATH}/subscription/overview/:type`;
export const SIGNUP_SUCCESS_PATH = "/signup-success";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_SUCCESS_PATH = "/reset-password/success";
export const RESET_PASSWORD_CONFIRMATION_PATH =
  "/reset-password/:userId/:token";
export const RESET_PASSWORD_CONFIRMATION_SUCCESS_PATH =
  "/reset-password/confirmation";
export const TERMS_AND_CONDITIONS_PATH = "/terms-and-conditions";
export const APP_TERMS_AND_CONDITIONS_PATH = `${APPLICATION_PATH}${TERMS_AND_CONDITIONS_PATH}`;
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const APP_PRIVACY_POLICY_PATH = `${APPLICATION_PATH}${PRIVACY_POLICY_PATH}`;
export const VERIFICATION_PATH = "/verification/:userId/:token";
export const PROFILE_PATH = `${APPLICATION_PATH}/profile`;
export const PROFILE_EDIT_PATH = `${PROFILE_PATH}/edit`;
export const PROFILE_EDIT_PASSWORD_PATH = `${PROFILE_EDIT_PATH}/password`;
export const PLACES_PATH = `${APPLICATION_PATH}/places`;
export const PLACE_OVERVIEW_PATH = `${PLACES_PATH}/:id`;
// Saved routes overview
export const ROUTES_PATH = `${APPLICATION_PATH}/routes`;
// Single saved route overview
export const ROUTE_OVERVIEW_PATH = `${ROUTES_PATH}/:id`;
// Generated routes overview
export const GENERATED_ROUTES_PATH = `${ROUTES_PATH}/generated`;
// Single generated route overview
export const GENERATED_ROUTE_OVERVIEW_PATH = `${ROUTES_PATH}/generated/:id`;
export const FEEDBACK_PATH = `${APPLICATION_PATH}/feedback`;
export const ADD_PLACE_PATH = `${APPLICATION_PATH}/add-place`;
export const ABOUT_US_PATH = `/about-us`;
export const APPLICATION_ABOUT_US_PATH = `${APPLICATION_PATH}/about-us`;
