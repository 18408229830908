import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import AddIcon from "@material-ui/icons/Add";
import TimelineIcon from "@material-ui/icons/Timeline";
import WhereToVoteSharpIcon from "@material-ui/icons/WhereToVoteSharp";
import classNames from "classnames";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RouteFilterNames } from "../../../constants";
import User from "../../../entities/User";
import {
  ADD_PLACE_PATH,
  APPLICATION_PATH,
  PLACES_PATH,
  PROFILE_PATH,
  ROUTES_PATH,
} from "../../../routes";
import { selectCurrentUser } from "../../../slices/usersSlice";

const styles = makeStyles((theme: Theme) => ({
  navigationContainer: {
    marginTop: theme.spacing(3),
  },
  navigationLinksContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  navigationLink: {
    flex: 1,
    textAlign: "center",
  },
  navigationLinkContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    alignItems: "center",
  },
  generateRouteCircleContainer: {
    position: "fixed",
    bottom: 32,
  },
  generateRouteCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    zIndex: 1,
    margin: "0 auto",
    width: "fit-content",
    padding: theme.spacing(),
    background: theme.palette.primary.main,
  },
  generateRouteContainer: {
    position: "relative",
    top: theme.spacing(),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  logo: {
    width: 33,
    height: 30,
  },
  pulseAnimation: {
    animation: "$pulse-animation 1s infinite",
  },
  "@keyframes pulse-animation": {
    "0%": {
      boxShadow: `0 0 0 0px #00666680`,
    },
    "100%": {
      boxShadow: `0 0 0 8px #00666699`,
    },
  },
}));

const MobileHeader: React.FC = () => {
  const classes = styles();
  const history = useHistory();
  const user = useAppSelector(selectCurrentUser);
  return (
    <AppBar
      color="inherit"
      className={classes.navigationContainer}
      position="relative"
    >
      <Toolbar disableGutters>
        <ul className={classes.navigationLinksContainer}>
          <li className={classes.navigationLink}>
            <IconButton
              disabled={!user?.activated}
              onClick={() => {
                history.push(ADD_PLACE_PATH);
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.navigationLinkContent}
                color="textSecondary"
              >
                <AddIcon color={user?.activated ? "primary" : "disabled"} />
                Добави обект
              </Typography>
            </IconButton>
          </li>
          <li className={classes.navigationLink}>
            <IconButton
              disabled={!user?.activated}
              onClick={() => {
                history.push({
                  pathname: ROUTES_PATH,
                  search: `${RouteFilterNames.status}=${User.SavedRouteStatus.notStarted}`,
                });
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.navigationLinkContent}
                color="textSecondary"
              >
                <TimelineIcon
                  color={user?.activated ? "primary" : "disabled"}
                />
                Маршрути
              </Typography>
            </IconButton>
          </li>
          <li
            className={classNames(
              classes.navigationLink,
              classes.generateRouteContainer
            )}
          >
            <IconButton>
              <Link to={APPLICATION_PATH}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.navigationLinkContent}
                >
                  <div className={classes.generateRouteCircleContainer}>
                    <div className={classes.generateRouteCircle}>
                      <img
                        src={`/mobile-button-logo.svg`}
                        className={classes.logo}
                        alt="Logo"
                      />
                    </div>
                  </div>
                  Генерирай маршрут
                </Typography>
              </Link>
            </IconButton>
          </li>
          <li className={classes.navigationLink}>
            <IconButton
              disabled={!user?.activated}
              onClick={() => {
                history.push(PLACES_PATH);
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.navigationLinkContent}
                color="textSecondary"
              >
                <WhereToVoteSharpIcon
                  color={user?.activated ? "primary" : "disabled"}
                />
                Обекти
              </Typography>
            </IconButton>
          </li>
          <li className={classes.navigationLink}>
            <IconButton>
              <Link to={PROFILE_PATH}>
                <Typography
                  variant="subtitle2"
                  className={classes.navigationLinkContent}
                  color="textSecondary"
                >
                  <Avatar
                    alt={user ? user.firstName : "firstName"}
                    src={user ? user.profilePicture : ""}
                    className={classNames(classes.avatar, {
                      [classes.pulseAnimation]: user && !user.firstName,
                    })}
                  >
                    {(!user || !user.profilePicture) && (
                      <AccountCircleSharpIcon color="primary" />
                    )}
                  </Avatar>
                  {user ? user.firstName || "Потребител" : "..."}
                </Typography>
              </Link>
            </IconButton>
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
};

export default MobileHeader;
