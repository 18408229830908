import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { APP_TERMS_AND_CONDITIONS_PATH } from "../routes";
import BackButton from "./common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";

const styles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: `${theme.spacing() * 2}px 0`,
    textAlign: "center",
  },
  sectionTitle: {
    margin: `${theme.spacing()}px 0`,
  },
  backButton: {
    alignSelf: "flex-start",
    padding: `${theme.spacing() * 2}px 0`,
  },
  contentMarginContainer: {
    marginTop: theme.spacing() * 4,
  },
}));

const TermsAndConditionsPage: React.FC<WithMediaDownProps> = function ({
  isMediaDown,
}) {
  const classes = styles();
  const location = useLocation();
  const isInApplicationTC = useMemo(() => {
    return matchPath(location.pathname, {
      path: APP_TERMS_AND_CONDITIONS_PATH,
      exact: true,
      strict: false,
    })?.isExact;
  }, [location]);

  return (
    <Container disableGutters maxWidth="md">
      {!isMediaDown && !isInApplicationTC && <BackButton />}
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div
          className={classNames(classes.content, {
            [classes.contentMarginContainer]: !isMediaDown,
          })}
        >
          {isMediaDown && !isInApplicationTC && <BackButton />}
          {!isInApplicationTC && <Logo size={isMediaDown ? "md" : "lg"} />}
          <Typography variant="h1" color="primary" className={classes.title}>
            Условия за ползване
          </Typography>
          <Typography variant="body2" color="textPrimary" align="justify">
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ОБЩИ ПОЛОЖЕНИЯ
            </Typography>
            Настоящият документ съдържа Общи условия, съгласно които дружеството
            ЕКСПЛОР ТЕХНОЛОДЖИ ООД, ЕИК 206530000, наричано за краткост
            ДОСТАВЧИКА в този документ, предоставя услуги на
            потребителите/клиентите си (наричани за краткост ПОТРЕБИТЕЛИ)
            посредством онлайн платформата GoXplore (https://www.goxplore.bg/ -
            наричана за краткост ПЛАТФОРМАТА). Тези условия обвързват всички
            потребители. Посещавайки ПЛАТФОРМАТА, всеки ПОТРЕБИТЕЛ изцяло приема
            и се задължава да спазва настоящите общи условия.
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ПРАВА И ЗАДЪЛЖЕНИЯ
            </Typography>
            1. ПОТРЕБИТЕЛЯТ има право да използва ПЛАТФОРМАТА, както и
            информацията и материалите (снимки, статии), съдържащи се в нея,
            само за позволени от закона цели, без да накърнява правата и да
            ограничава достъпа до ПЛАТФОРМАТА на трета страна.
            <br />
            2. Ползването на ПЛАТФОРМАТА е по инициатива, желание и за сметка на
            ПОТРЕБИТЕЛЯ, при спазване ограниченията в ЗАПСП в полза на титуляря
            на авторското право – ДОСТАВЧИКА.
            <br />
            3. ПОТРЕБИТЕЛЯТ има право да разглежда, копира и съхранява
            материалите от ПЛАТФОРМАТА единствено за лична употреба.
            <br />
            4. ПОТРЕБИТЕЛЯТ е длъжен при използване на ПЛАТФОРМАТА да:
            <br />
            &emsp;4.1) Спазва българското законодателство, приложимите чужди
            закони и директиви;
            <br />
            &emsp;4.2) Не нарушава и да зачита основните права и свободи на
            гражданите и правата на човека, съгласно Конституцията и законите на
            Република България и признатите международни актове; да не накърнява
            доброто име на другиго и да не призовава към насилствена промяна на
            конституционно установения ред, към извършване на престъпление, към
            насилие над личността или към разпалване на расова, национална,
            етническа или религиозна вражда;
            <br />
            &emsp;4.3) Не зарежда, изпраща, предава, разпространява или използва
            по какъвто и да било начин и да не прави достояние на трети лица
            софтуер, компютърни програми, файлове, приложения или други
            материали, съдържащи компютърни вируси, системи за неоторизиран
            отдалечен контрол (“троянски коне”), компютърни кодове, или
            материали, предназначени да прекъсват, затрудняват, нарушават или
            ограничават нормалното функциониране на компютърен хардуер или
            софтуер или телекомуникационните съоръжения или имащи за цел
            неоторизирано проникване или достъп в чуждестранни ресурси или
            софтуер;
            <br />
            &emsp;4.4) Уведоми незабавно ДОСТАВЧИКА при извършено или открито
            нарушение при използване на предоставяните услуги.
            <br />
            5. На ПОТРЕБИТЕЛЯ е забранено да:
            <br />
            &emsp;5.1) Променя, копира, публикува, изпраща, дава под наем,
            продава, използва с комерсиална цел съдържанието в ПЛАТФОРМАТА.
            Някоя от тези дейности може да се извърши само и единствено с
            изричното позволение от ДОСТАВЧИКА.
            <br />
            &emsp;5.2) Да използва неправомерно ПЛАТФОРМАТА или съдържанието в
            нея (изпращане или предаване на материали със заплашително, невярно,
            подвеждащо, оскърбително, обидно, нарушаващо конкуренцията,
            оклеветяващо, неприлично, порнографско или друг вид незаконно
            съдържание или каквито и да са материали, които представляват или
            подбуждат поведение, което може да бъде квалифицирано като
            престъпление, да доведе до гражданска или наказателна отговорност
            или по друг начин да наруши законите на Република България).
            <br />
            &emsp;5.3) Да нарушава чужди имуществени или неимуществени,
            абсолютни или относителни права и интереси, като право на
            собственост, право на интелектуална собственост и др.;
            <br />
            6. ЕКСПЛОР ТЕХНОЛОДЖИ ООД си запазва правото да коригира (добавя,
            променя, премества и изтрива) съдържанието или части от него
            (включително цени на услуги, публикации, данни, услуги или друга
            информация, показана на или свързана с настоящия сайт), да
            отстранява евентуални допуснати грешки, неточности и пропуски по
            всяко време без предизвестие.
            <br />
            7. Договорният език е български, а плащанията в ПЛАТФОРМАТА ще бъдат
            извършвани в български левове с ДДС.
            <br />
            8. ПОТРЕБИТЕЛЯТ носи пълна отговорност за опазването на своето
            потребителско име и парола, както и за всички действия, които се
            извършват от него или от трето лице чрез използване на
            потребителското име и паролата. Потребителят е длъжен незабавно да
            уведоми ДОСТАВЧИКА за всеки случай на неправомерен достъп чрез
            използване на неговото потребителско име и парола, както и винаги,
            когато съществува опасност от такова използване.
            <br />
            9. ПОТРЕБИТЕЛЯТ е длъжен да споделя в ПЛАТФОРМАТА само авторско
            съдържание, над което има всички осигурени авторски права. В случай
            споделяне на съдържание в ПЛАТФОРМАТА от ПОТРЕБИТЕЛ, единствено
            ПОТРЕБИТЕЛЯТ носи отговорност за евентуалното нарушение на
            авторските права на трети лица.
            <br />
            10. ДОСТАВЧИКЪТ е длъжен след получаване на плащането се задължава
            да осигури на ПОТРЕБИТЕЛЯ закупената от него услуга в срок.
            <br />
            11. ДОСТАВЧИКЪТ има право, но не и задължение да запазва материали и
            информация, разположени на сървъра на ПЛАТФОРМАТА.
            <br />
            12. ДОСТАВЧИКЪТ не носи отговорност за вреди, причинени върху
            софтуера, хардуера или телекомуникационните съоръжения, или за
            загуба на данни, произтекли от материали или ресурси, търсени,
            заредени или използвани по какъвто и да било начин посредством
            предоставяните услуги. Съветите, консултациите или помощта, оказани
            от специалистите и служителите на ДОСТАВЧИКА във връзка с ползването
            на услугите от потребителите, не поражда каквато и да било
            отговорност или задължения за ДОСТАВЧИКА.
            <br />
            13. ДОСТАВЧИКЪТ има право да събира и използва информация отнасяща
            се до ПОТРЕБИТЕЛЯ на платформата. Информацията може да бъде
            използвана, освен в случай на изрично несъгласие на ПОТРЕБИТЕЛЯ,
            изпратено на следният имейл адрес: info@goxplore.bg.
            <br />
            14. ДОСТАВЧИКЪТ се задължава да използва информацията от точка 12
            само за цели, съобразени с българското законодателство,
            международните закони и директиви, и етичните и добри нрави.
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ЛИЧНИ ДАННИ
            </Typography>
            ДОСТАВЧИКЪТ гарантира на своите ПОТРЕБИТЕЛИ конфиденциалността на
            предоставената информация и лични данни. Данните няма да бъдат
            използвани, предоставяни или довеждани до знанието на трети лица
            извън случаите и при условията, посочени в настоящите Общи условия и
            в Политиката за поверителност на ПЛАТФОРМАТА. ДОСТАВЧИКЪТ защитава
            личните данни на ПОТРЕБИТЕЛЯ, станали му известни при попълване на
            електронната форма за регистрация/вход в ПЛАТФОРМАТА, като това
            задължение отпада в случай, че ПОТРЕБИТЕЛЯТ е предоставил неверни
            данни. При спазване на действащото законодателство и клаузите на
            настоящите Общи условия и Политиката за поверителност, ДОСТАВЧИКЪТ
            може да използва личните данни на ПОТРЕБИТЕЛЯ единствено и само за
            целите, описани в Политиката за поверителност. Всякакви други цели,
            за които се използват данните, ще бъдат съобразени с българското
            законодателство, приложимите международни актове, Интернет етиката,
            правилата на морала и добрите нрави.
            <br />
            ДОСТАВЧИКЪТ се задължава да не разкрива никакви лични данни за
            ПОТРЕБИТЕЛЯ на трети лица – държавни органи, търговски дружества,
            физически лица и други, освен в случаите когато е получил изричното
            писмено съгласие на ПОТРЕБИТЕЛЯ, информацията е изискана от държавни
            органи или длъжностни лица, които според действащото законодателство
            са оправомощени да изискват и събират такава информация. ДОСТАВЧИКЪТ
            е длъжен да предостави информацията по силата на закона.
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              АВТОРСКО ПРАВО
            </Typography>
            Дизайнът, структурата и съдържанието на ПЛАТФОРМАТА са защитени с
            авторско право. Последните са собственост на ДОСТАВЧИКА, който
            притежава изключителното право за ползването им. Всяко неоторизирано
            ползване на съдържанието е нарушение на авторски права или други
            законови разпоредби.
            <br />
            ПОТРЕБИТЕЛЯТ има право да разглежда, копира и съхранява материалите
            от ПЛАТФОРМАТА единствено за лична употреба.
            <br />
            ПОТРЕБИТЕЛЯТ няма право да разпространява материалите от ПЛАТФОРМАТА
            с търговска и публична цел, освен като информация за популяризиране
            на услугите на ДОСТАВЧИКА.
            <br />
            Всички права върху интелектуална собственост, отнасящи се до всички
            материали, публикувани в ПЛАТФОРМАТА са защитени от разпоредбите на
            Закона за авторското право и сродните права и Наказателният кодекс,
            собственост са или на ДОСТАВЧИКА, или на трета страна, която е
            отстъпила правото им за ползване на ДОСТАВЧИКА предварително. При
            нарушаване на закона, виновното лице дължи обезщетение за всички
            вреди, които са пряка и непосредствена последица от нарушението,
            като се вземат предвид всички обстоятелства, свързани с нарушението.
            <br />
            ПОТРЕБИТЕЛЯТ е длъжен да споделя в ПЛАТФОРМАТА само авторско
            съдържание, над което има всички осигурени авторски права. В случай
            споделяне на съдържание в ПЛАТФОРМАТА от ПОТРЕБИТЕЛ, единствено
            ПОТРЕБИТЕЛЯТ носи отговорност за евентуалното нарушение на
            авторските права на трети лица.
            <br />
            ДОСТАВЧИКЪТ не се задължава да следи за спазването на авторските
            права от страна на ПОТРЕБИТЕЛЯ.
            <br />
            ДОСТАВЧИКЪТ има право при установяване на нарушения, свързани с
            авторското право от страна на ПОТРЕБИТЕЛЯ, да заличи всякакво
            съдържание без предварително уведомяване на ПОТРЕБИТЕЛЯ.
            <br />
            ДОСТАВЧИКЪТ има право да заличи всякакви материали, добавени в
            ПЛАТФОРМАТА от ПОТРЕБИТЕЛЯ, ако тези материали са в разрез основните
            права и свободи на гражданите и правата на човека, съгласно
            Конституцията и законите на Република България и признатите
            международни актове; накърняват доброто име на другиго и/или
            призовават към насилствена промяна на конституционно установения
            ред, към извършване на престъпление, към насилие над личността или
            към разпалване на расова, национална, етническа или религиозна
            вражда.
            <br />
            ДОСТАВЧИКЪТ си запазва правото да не одобри и публикува материали,
            добавени от ПОТРЕБИТЕЛЯ.
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionTitle}
            >
              ИЗМЕНЕНИЯ В ОБЩИТЕ УСЛОВИЯ
            </Typography>
            Общите условия за използване на ПЛАТФОРМАТА могат да бъдат променяни
            по всяко време от ДОСТАВЧИКА, като той има право и да променя
            характеристиките на предоставяните услуги и настоящите Общи условия
            и на основание промени в законодателството. ДОСТАВЧИКЪТ се задължава
            да уведоми ПОТРЕБИТЕЛЯ за промените в Общите условия, като на видно
            място в Интернет страницата си публикува съобщение за измененията им
            и даде достатъчен срок да се запознае с тях. В дадения срок, ако
            ПОТРЕБИТЕЛЯТ не заяви, че отхвърля промените, то той се счита
            обвързан от тях. В случай, че ПОТРЕБИТЕЛЯТ заяви в дадения срок, че
            не е съгласен с промените, то ДОСТАВЧИКЪТ има право веднага да спре
            или прекрати предоставянето на услугите на ПОТРЕБИТЕЛЯ.
          </Typography>
        </div>
      </Container>
    </Container>
  );
};

export default WithMediaDown(TermsAndConditionsPage, "md");
