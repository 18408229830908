import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchRegionsByIds as fetchRegions } from "../api/regionAPI";
import { AppState, AppThunk } from "../app/store";
import Region from "../entities/Region";

export interface RegionState {
  byId: { [key: string]: Region.Type };
  fetching: { [key: string]: boolean };
  status: "idle" | "loading" | "failed";
}

type FetchRegionsPayload = { regions: Region.Type[] };
type FetchInProgress = { ids: string[] };

const initialState: RegionState = {
  byId: {},
  fetching: {},
  status: "idle",
};

export const ensureRegionsByIds = (ids: string[]): AppThunk<Promise<void>> => (
  dispatch,
  getState
) => {
  const regionsToFetch = ids.filter((id) => {
    const region = getState().regions.byId[id];
    const isFetching = getState().regions.fetching[id];
    return !region && !isFetching;
  });
  if (regionsToFetch.length) {
    return dispatch(fetchRegionsByIds(regionsToFetch));
  }
  return Promise.resolve();
};

export const fetchRegionsByIds = (ids: string[]): AppThunk<Promise<void>> => (
  dispatch,
  _
) => {
  dispatch(setFetchingInProgress({ ids }));
  return fetchRegions(ids)
    .then((regions) => {
      dispatch(setRegions({ regions }));
    })
    .catch((error) => {
      dispatch(setFetchingFailed({ ids }));
    });
};

export const regionSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    setRegions: (state, action: PayloadAction<FetchRegionsPayload>) => {
      const { regions } = action.payload;
      regions.forEach((region) => {
        state.byId[region.id] = { ...region };
        state.fetching[region.id] = false;
      });
      state.status = "idle";
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = true;
        state.status = "loading";
      });
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      ids.forEach((id) => {
        state.fetching[id] = false;
        state.status = "loading";
      });
      state.status = "failed";
    },
  },
});

export const {
  setRegions,
  setFetchingInProgress,
  setFetchingFailed,
} = regionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRegionById = (state: AppState, id: string) =>
  state.regions.byId[id];

export default regionSlice.reducer;
