import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import SearchIcon from "@material-ui/icons/Search";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import classNames from "classnames";
import { debounce } from "lodash";
import mixpanel from "mixpanel-browser";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fetchCategoryIds } from "../api/categoryApi";
import { PlaceFilterConfig } from "../api/placeAPI";
import { fetchRegionIds } from "../api/regionAPI";
import {
  fetchSubcategoriesByCategoryId,
  fetchSubcategoriesByCategoryIds,
} from "../api/subcategoryAPI";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { PlaceFilterNames } from "../constants";
import { ADD_PLACE_PATH, GENERATED_ROUTE_OVERVIEW_PATH } from "../routes";
import { ensureCategoriesByIds } from "../slices/categorySlice";
import { fetchPlaces } from "../slices/placeSlice";
import { ensureRegionsByIds } from "../slices/regionSlice";
import { generateOwnRoute } from "../slices/routeSlice";
import { ensureSubcategoriesByIds } from "../slices/subcategorySlice";
import { selectCurrentUser } from "../slices/usersSlice";
import BackButton from "./common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import PlaceCard from "./common/PlaceCard";
import PlaceChipFilter, { ChipFilterOption } from "./common/PlaceChipFilter";
import WithLoadingBackdrop from "./common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    flex: 1,
  },
  subcontainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  filterSubcontainer: {
    display: "flex",
    width: "100%",
  },
  chipItem: {
    margin: `${theme.spacing()}px auto`,
  },
  searchInput: {
    marginTop: theme.spacing(),
  },
  pagination: {
    width: "100%",
    margin: `${theme.spacing()}px 0`,
  },
  paginationUl: {
    flexWrap: "nowrap",
    justifyContent: "center",
    "& > li": {
      width: 35,
    },
  },
  button: {
    marginBottom: theme.spacing() / 2,
  },
  loadingItem: {
    flex: "0 0 auto",
    margin: "0 auto",
  },
  footer: {
    width: "100%",
    maxWidth: 450,
    alignSelf: "center",
    marginTop: theme.spacing() / 2,
  },
}));

const PlacesPage: React.FC<WithMediaDownProps> = ({ isMediaDown }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useAppSelector(selectCurrentUser);
  const count = useAppSelector((state) => state.places.count);
  const regions = useAppSelector((state) => Object.values(state.regions.byId));
  const categories = useAppSelector((state) =>
    Object.values(state.categories.byId)
  );
  const subcategories = useAppSelector((state) =>
    Object.values(state.subcategories.byId)
  );
  const isLoading = useAppSelector(
    (state) => state.places.status === "loading"
  );

  const urlSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const [placeIds, setPlaceIds] = useState<string[]>([]);
  const [pageLimit] = useState<number>(isMediaDown ? 18 : 15);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [routeLoading, setRouteLoading] = useState<boolean>(false);
  const [optimizeRoute, setOptimizeRoute] = useState<boolean>(false);
  const [generateConfirmationOpen, setGenerateConfirmationOpen] =
    useState<boolean>(false);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState<
    string[]
  >([]);

  const selectedSubcategories = useMemo(() => {
    const result: ChipFilterOption[] = subcategories
      .filter((subcategory) => selectedSubcategoryIds.includes(subcategory.id))
      .map((el) => ({
        id: el.id,
        label: el.name,
      }));
    return result;
  }, [selectedSubcategoryIds, subcategories]);

  const hasSomeFilters = useMemo(() => {
    return (
      urlSearchParams.has(PlaceFilterNames.search) ||
      urlSearchParams.has(PlaceFilterNames.page) ||
      urlSearchParams.has(PlaceFilterNames.region) ||
      urlSearchParams.has(PlaceFilterNames.category) ||
      urlSearchParams.has(PlaceFilterNames.subcategory) ||
      urlSearchParams.has(PlaceFilterNames.visited) ||
      urlSearchParams.has(PlaceFilterNames.notVisited) ||
      urlSearchParams.has(PlaceFilterNames.favourite)
    );
  }, [urlSearchParams]);

  useEffect(() => {
    fetchRegionIds().then((ids) => {
      dispatch(ensureRegionsByIds(ids));
    });
    fetchCategoryIds().then((categoryIds) => {
      dispatch(ensureCategoriesByIds(categoryIds));
    });
  }, []);

  useEffect(() => {
    if (count) {
      const result = count / pageLimit;
      if (Number.isInteger(result)) {
        setPaginationCount(result);
      } else {
        setPaginationCount(Number.parseInt(String(result)) + 1);
      }
    } else {
      setPaginationCount(0);
    }
  }, [count]);

  useEffect(() => {
    fetchPage();
  }, [urlSearchParams]);

  const handlePlaceChange = useCallback(() => {
    if (hasSomeFilters) {
      fetchPage();
    }
  }, [hasSomeFilters, urlSearchParams]);

  const fetchPage = useCallback(() => {
    if (
      urlSearchParams.has(PlaceFilterNames.visited) &&
      urlSearchParams.has(PlaceFilterNames.notVisited) &&
      urlSearchParams.has(PlaceFilterNames.favourite)
    ) {
      urlSearchParams.delete(PlaceFilterNames.visited);
      urlSearchParams.delete(PlaceFilterNames.notVisited);
      urlSearchParams.delete(PlaceFilterNames.favourite);
      urlSearchParams.delete(PlaceFilterNames.page);
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
      return;
    }
    const search = urlSearchParams.get(PlaceFilterNames.search) || "";
    const page = Number(urlSearchParams.get(PlaceFilterNames.page)) || 1;
    const regions = urlSearchParams.getAll(PlaceFilterNames.region);
    const categories = urlSearchParams.getAll(PlaceFilterNames.category);
    const subcategories = urlSearchParams.getAll(PlaceFilterNames.subcategory);
    const visited = urlSearchParams.has(PlaceFilterNames.visited);
    const notVisited = urlSearchParams.has(PlaceFilterNames.notVisited);
    const favourite = urlSearchParams.has(PlaceFilterNames.favourite);
    const filter: PlaceFilterConfig = {
      search,
      page,
      limit: pageLimit,
      regions,
      categories,
      subcategories,
      visited,
      notVisited,
      favourite,
    };
    if (categories.length) {
      fetchSubcategoriesByCategoryIds(categories).then((subcategoryIds) => {
        dispatch(ensureSubcategoriesByIds(subcategoryIds));
        setSelectedSubcategoryIds(subcategoryIds);
      });
    }
    debouncedFetchPlaces.current(filter);
  }, [urlSearchParams]);

  const debouncedFetchPlaces = useRef(
    debounce((filter: PlaceFilterConfig) => {
      setPlaceIds([]);
      dispatch(fetchPlaces(filter)).then((result) => {
        setPlaceIds(result);
      });
    }, 500)
  );

  const handlePlaceSelect = useCallback(
    (id: string) => {
      if (!selectedPlaces.length) {
        setSnackbarOpen(true);
      }
      setSelectedPlaces((prevState) => [...prevState, id]);
    },
    [selectedPlaces]
  );

  const handlePlaceRemove = useCallback((id: string) => {
    setSelectedPlaces((prevState) => prevState.filter((el) => el !== id));
  }, []);

  const handleCategoryChange = useCallback(
    (categoryId?: string) => {
      if (!categoryId) {
        setSelectedSubcategoryIds([]);
        return;
      }
      fetchSubcategoriesByCategoryId(categoryId).then((ids) => {
        dispatch(ensureSubcategoriesByIds(ids));
        setSelectedSubcategoryIds(ids);
      });
    },
    [location.search]
  );
  const handleCategoryClear = useCallback(() => {
    setSelectedSubcategoryIds([]);
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set(PlaceFilterNames.page, String(page));
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [location]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set(PlaceFilterNames.search, event.target.value);
      urlSearchParams.delete(PlaceFilterNames.page);
      mixpanel.track("CHANGE_PLACE_FILTERS", {
        "Filter Type": PlaceFilterNames.search,
        Search: event.target.value,
      });
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [location]
  );

  const handleClearSearch = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(PlaceFilterNames.search);
    urlSearchParams.delete(PlaceFilterNames.page);
    history.replace({
      pathname: location.pathname,
      search: urlSearchParams.toString(),
    });
  }, [location]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleGenerateRouteClick = useCallback(() => {
    if (!user) {
      return;
    }
    setGenerateConfirmationOpen(true);
  }, [user, selectedPlaces, optimizeRoute]);

  const handleGenerateRoute = useCallback(() => {
    setGenerateConfirmationOpen(false);
    if (selectedPlaces.length && selectedPlaces.length <= 10) {
      setRouteLoading(true);
      dispatch(generateOwnRoute(selectedPlaces, optimizeRoute))
        .then((id) => {
          setRouteLoading(false);
          setOptimizeRoute(false);
          mixpanel.track("GENERATE_ROUTE_PLACES", {
            Places: selectedPlaces,
            Route: id,
            Optimize: optimizeRoute,
          });
          history.push({
            pathname: GENERATED_ROUTE_OVERVIEW_PATH.replace(":id", id),
          });
        })
        .catch(() => {
          setRouteLoading(false);
          setGenerateConfirmationOpen(false);
          setOptimizeRoute(false);
        });
    }
  }, [selectedPlaces, optimizeRoute]);

  const handleAdditionalFilterSelect = useCallback(
    (filterType?: keyof typeof PlaceFilterNames) => {
      const urlSearchParams = new URLSearchParams(location.search);
      if (!filterType) {
        urlSearchParams.delete(PlaceFilterNames.visited);
        urlSearchParams.delete(PlaceFilterNames.notVisited);
        urlSearchParams.delete(PlaceFilterNames.favourite);
      } else {
        if (urlSearchParams.has(PlaceFilterNames[filterType])) {
          urlSearchParams.delete(PlaceFilterNames[filterType]);
        } else {
          urlSearchParams.set(PlaceFilterNames[filterType], String(true));
        }
        if (filterType === "visited") {
          urlSearchParams.delete(PlaceFilterNames.notVisited);
        }
        if (filterType === "notVisited") {
          urlSearchParams.delete(PlaceFilterNames.visited);
        }
      }
      urlSearchParams.delete(PlaceFilterNames.page);
      mixpanel.track("CHANGE_PLACE_FILTERS", {
        "Filter Type": filterType || "All",
      });
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [location.search]
  );

  return (
    <WithLoadingBackdrop open={routeLoading || isLoading}>
      <Container
        maxWidth="lg"
        className={classNames(classes.container)}
        disableGutters
      >
        <TextField
          className={classes.searchInput}
          label="Търси по име"
          value={urlSearchParams.get(PlaceFilterNames.search) || ""}
          variant="outlined"
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {isMediaDown ? (
                  <div>
                    <BackButton />
                  </div>
                ) : (
                  <SearchIcon color="primary" />
                )}
              </InputAdornment>
            ),
            endAdornment: urlSearchParams.get(PlaceFilterNames.search) ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <CancelOutlinedIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <Container
          disableGutters
          maxWidth="md"
          className={classes.filterContainer}
        >
          <Chip
            className={classes.chipItem}
            label="Всички"
            clickable
            color="primary"
            variant={
              !urlSearchParams.has(PlaceFilterNames.favourite) &&
              !urlSearchParams.has(PlaceFilterNames.visited) &&
              !urlSearchParams.has(PlaceFilterNames.notVisited)
                ? "default"
                : "outlined"
            }
            onClick={() => {
              handleAdditionalFilterSelect();
            }}
          />
          <Chip
            className={classes.chipItem}
            label="Любими"
            clickable
            color="primary"
            variant={
              urlSearchParams.has(PlaceFilterNames.favourite)
                ? "default"
                : "outlined"
            }
            onClick={() => {
              handleAdditionalFilterSelect("favourite");
            }}
          />
          <Chip
            className={classes.chipItem}
            label="Посетени"
            clickable
            color="primary"
            variant={
              urlSearchParams.has(PlaceFilterNames.visited)
                ? "default"
                : "outlined"
            }
            onClick={() => {
              handleAdditionalFilterSelect("visited");
            }}
          />
          <Chip
            className={classes.chipItem}
            label="Непосетени"
            clickable
            color="primary"
            variant={
              urlSearchParams.has(PlaceFilterNames.notVisited)
                ? "default"
                : "outlined"
            }
            onClick={() => {
              handleAdditionalFilterSelect("notVisited");
            }}
          />
          <PlaceChipFilter
            label="Област"
            filterType="region"
            options={regions.map((region) => ({
              id: region.id,
              label: region.name,
            }))}
          />
          <PlaceChipFilter
            label="Категория"
            filterType="category"
            options={categories.map((category) => ({
              id: category.id,
              label: category.name,
            }))}
            onSelectOption={handleCategoryChange}
            onClearOptions={handleCategoryClear}
          />
          <PlaceChipFilter
            label="Подкатегория"
            filterType="subcategory"
            options={selectedSubcategories}
          />
        </Container>
        <div className={classes.subcontainer}>
          <Grid container alignItems="center" spacing={1}>
            {placeIds.map((el) => (
              <Grid key={el} item xs={12} sm={6} md={4}>
                <PlaceCard
                  placeId={el}
                  isSelected={selectedPlaces.includes(el)}
                  disableSelection={selectedPlaces.length === 10}
                  onPlaceSelect={() => handlePlaceSelect(el)}
                  onPlaceRemove={() => handlePlaceRemove(el)}
                  onChangeFavourite={handlePlaceChange}
                  onChangeVisited={handlePlaceChange}
                />
              </Grid>
            ))}
            {count === 0 && !placeIds.length && !isLoading && (
              <Grid xs={12} item>
                <Typography variant="h5" color="primary" align="center">
                  Няма намерени резултати.
                </Typography>
              </Grid>
            )}
          </Grid>
          {count && !isLoading ? (
            <Pagination
              className={classes.pagination}
              classes={{
                root: classes.pagination,
                ul: classes.paginationUl,
              }}
              onChange={(_, page) => {
                handlePageChange(page);
              }}
              color="primary"
              page={Number(urlSearchParams.get(PlaceFilterNames.page)) || 1}
              count={paginationCount}
              shape="rounded"
            />
          ) : null}
        </div>
        {!isLoading && (
          <div className={classes.footer}>
            {!!selectedPlaces.length && (
              <Button
                fullWidth
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleGenerateRouteClick}
              >
                ГЕНЕРИРАЙ МАРШРУТ ({selectedPlaces.length})
              </Button>
            )}
            <Button
              fullWidth
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(ADD_PLACE_PATH);
              }}
            >
              ПРЕДЛОЖИ ОБЕКТ
            </Button>
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="info" variant="filled">
            Може да добавиш максимум 10 обекта в маршрут.
          </Alert>
        </Snackbar>
        <Dialog
          open={generateConfirmationOpen}
          maxWidth="lg"
          onClose={(_, reson) => {
            if (reson !== "backdropClick") {
              setOptimizeRoute(false);
              setGenerateConfirmationOpen(false);
            }
          }}
        >
          <DialogTitle disableTypography>
            <Typography variant="h5" color="primary">
              Генерирай маршрут
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              {/* {user && user.subscriptionExpirationDate
                ? "Желаеш ли да оптимизираме маршрута ти?"
                : "Това действие ще ти коства един кредит. Искаш ли да продължиш?"} */}
              Желаеш ли да оптимизираме маршрута ти?
            </Typography>
            <FormControlLabel
              value="end"
              onChange={(_, checked) => {
                setOptimizeRoute(checked);
              }}
              control={<Checkbox color="primary" checked={optimizeRoute} />}
              label={
                <Typography variant="body2">Оптимизирай маршрут</Typography>
              }
              color="primary"
              labelPlacement="end"
            />
            <Typography variant="overline" color="primary" component="p">
              Оптимизирането на маршрут означава, че ние ще подредим обектите в
              оптимален за теб ред.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOptimizeRoute(false);
                setGenerateConfirmationOpen(false);
              }}
            >
              Затвори
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateRoute}
              autoFocus
            >
              Продължи
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(PlacesPage, "md");
