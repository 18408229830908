import { makeStyles, Theme } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PlaceFilterNames } from "../../constants";

const styles = makeStyles((theme: Theme) => ({
  item: {
    margin: `${theme.spacing()}px auto`,
  },
}));

export interface ChipFilterOption {
  id: string;
  label: string;
}

interface OwnProps {
  label: string;
  filterType: keyof typeof PlaceFilterNames;
  options: ChipFilterOption[];
  onSelectOption?: (id?: string) => void;
  onClearOptions?: () => void;
}

const PlaceChipFilter: React.FC<OwnProps> = ({
  filterType,
  options,
  label,
  onSelectOption,
  onClearOptions,
}) => {
  const classes = styles();
  const history = useHistory();
  const location = useLocation();
  const chipRef = useRef<HTMLElement>();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const open = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);
  const selectedOptions = useMemo(() => {
    return new URLSearchParams(location.search).getAll(
      PlaceFilterNames[filterType]
    );
  }, [location.search]);
  const id = useMemo(() => (open ? "region-popover" : undefined), [open]);

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setMenuAnchorEl(chipRef.current || null);
    },
    [chipRef]
  );

  const handleMenuClose = useCallback((event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setMenuAnchorEl(null);
  }, []);

  const handleOptionSelect = useCallback(
    (id: string) => {
      const urlSearchParams = new URLSearchParams(location.search);
      let newOptions: string[] = [];
      if (selectedOptions.includes(id)) {
        newOptions = selectedOptions.filter((el) => el !== id);
        urlSearchParams.delete(PlaceFilterNames[filterType]);
        if (newOptions.length) {
          newOptions.forEach((el) => {
            urlSearchParams.append(PlaceFilterNames[filterType], el);
          });
        }
        if (onSelectOption) {
          onSelectOption();
        }
      } else {
        urlSearchParams.append(PlaceFilterNames[filterType], id);
        if (onSelectOption) {
          onSelectOption(id);
        }
      }
      urlSearchParams.delete(PlaceFilterNames.page);
      mixpanel.track("CHANGE_PLACE_FILTERS", {
        "Filter Type": PlaceFilterNames[filterType],
        Search: newOptions,
      });
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [location, selectedOptions]
  );

  const handleRemoveSelection = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(PlaceFilterNames[filterType]);
    urlSearchParams.delete(PlaceFilterNames.page);
    if (filterType === "category") {
      urlSearchParams.delete(PlaceFilterNames.subcategory);
    }
    history.replace({
      pathname: location.pathname,
      search: urlSearchParams.toString(),
    });
    if (onClearOptions) {
      onClearOptions();
    }
  }, [location]);

  return (
    <React.Fragment>
      <Chip
        innerRef={chipRef}
        className={classes.item}
        label={label}
        clickable
        disabled={!options.length}
        color="primary"
        variant={selectedOptions.length ? "default" : "outlined"}
        onClick={handleMenuOpen}
        onDelete={(event) => {
          if (selectedOptions.length) {
            handleRemoveSelection();
          } else {
            handleMenuOpen(event);
          }
        }}
        deleteIcon={
          selectedOptions.length ? (
            <ClearIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              handleOptionSelect(option.id);
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {option.label}
            </Typography>
            {selectedOptions.includes(option.id) && (
              <CheckIcon fontSize="small" color="primary" />
            )}
          </MenuItem>
        ))}
      </Popover>
    </React.Fragment>
  );
};

export default PlaceChipFilter;
