import axios from "axios";
import User from "../entities/User";

export interface PlaceRequest {
  name: string;
  pictures: File[];
  description?: string;
  destination?: string;
  location?: {
    longitude: number;
    latitude: number;
  };
}

export function fetchCurrentUser(): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    axios
      .get("/users/me")
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserPassword(password: string): Promise<any> {
  const parsedData = { password };
  return new Promise<any>((resolve, reject) => {
    axios
      .post("/users/change-password", parsedData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export function changeUserProfileNames(
  firstName: string,
  lastName: string
): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    const parsedData = { first_name: firstName, last_name: lastName };
    return axios
      .post("/users/edit-profile", parsedData)
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserEmailNotifications(
  hasSubscribed: boolean
): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    const parsedData = { email_subscription: hasSubscribed };
    return axios
      .post("/users/edit-profile", parsedData)
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserProfilePicture(file: File): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    const formData = new FormData();
    formData.append("profile_picture", file);
    return axios
      .post("/users/change-image", formData)
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateFavouritePlaces(placeIds: string[]): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    return axios
      .put("/users/favourite-places", { favourite_places: placeIds })
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateVisitedPlaces(placeIds: string[]): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    return axios
      .put("/users/visited-places", { visited_places: placeIds })
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function saveRoute(
  routeId: string,
  isRandom: boolean
): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    return axios
      .post("/users/save-route", { route_id: routeId, is_random: isRandom })
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeRoute(routeId: string): Promise<User.SavedRoute[]> {
  return new Promise<User.SavedRoute[]>((resolve, reject) => {
    return axios
      .delete(`/users/remove-route/${routeId}`)
      .then(({ data }) => {
        resolve(data.map(User.parseSavedRoute));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeRouteStatus(
  routeId: string,
  status: User.SavedRoute["status"],
  visitAllPlaces: boolean
): Promise<User.SavedRoute[]> {
  return new Promise<User.SavedRoute[]>((resolve, reject) => {
    return axios
      .post("/users/change-route-status", {
        route_id: routeId,
        status,
        mark_visited_places: visitAllPlaces,
      })
      .then(({ data }) => {
        resolve(data.map(User.parseSavedRoute));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeRouteTitle(
  routeId: string,
  title: string
): Promise<User.SavedRoute[]> {
  return new Promise<User.SavedRoute[]>((resolve, reject) => {
    return axios
      .put("/users/change-route-name", { route_id: routeId, title })
      .then(({ data }) => {
        resolve(data.map(User.parseSavedRoute));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deactivateProfile(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    return axios
      .delete("/users")
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function activeteProfile(): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    return axios
      .put("/users/activate")
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitPlaceRequest(placeRequest: PlaceRequest): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const formData = new FormData();
    formData.set("name", placeRequest.name);
    if (placeRequest.description) {
      formData.set("description", placeRequest.description);
    }
    if (placeRequest.location) {
      formData.set("latitude", String(placeRequest.location.latitude));
      formData.set("longitude", String(placeRequest.location.longitude));
    }
    if (placeRequest.destination) {
      formData.set("destination", placeRequest.destination);
    }
    placeRequest.pictures.forEach((file) => {
      formData.append("pictures", file);
    });
    return axios
      .post("/place-request", formData)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchUsersByIds(ids: string[]): Promise<User.Type[]> {
  return new Promise<User.Type[]>((resolve, reject) => {
    axios
      .get(
        `/users/?ids=${ids.join(
          "&ids="
        )}&include_fields=first_name&include_fields=last_name&include_fields=profile_picture&include_fields=id`
      )
      .then(({ data: { result } }) => {
        resolve(result.map(User.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resendVerification(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .post("/users/resend-validation")
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
