import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

interface OwnProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const RouteCompleteConfirmationDialog: React.FC<OwnProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reson) => {
        if (reson !== "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" color="primary">
          Завършване на маршрут
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Искаш ли да маркираме всички обекти в този маршрут като посетени?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Не
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          autoFocus
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RouteCompleteConfirmationDialog;
