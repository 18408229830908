export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
export const PROVIDER_TYPE_KEY = "PROVIDER_TYPE";
export const FEEDBACK_MAPING = {
  general: "Оставете ни обратна връзка.",
  registration: "Как оценяваш процеса по регистрация?",
  save_random_route:
    "Как оценяваш възможността за генериране на случаен маршрут?",
  save_generated_route: "Как оценяваш възможността за генериране на маршрут?",
  visit_place: "Как оценяваш възможността за добавяне на обекти като посетени?",
  add_favourite_place:
    "Как оценяваш възможността за добавяне на обекти като любими?",
};
export const PlaceFilterNames = {
  page: "page",
  limit: "limit",
  search: "search",
  region: "region",
  category: "category",
  subcategory: "subcategory",
  favourite: "favourite",
  visited: "visited",
  notVisited: "not_visited",
  includeFields: "include_fields",
};

export const RouteFilterNames = {
  status: "status",
};
