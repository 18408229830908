import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface OwnProps {
  open: boolean;
}

const WithLoadingBackdrop: React.FC<OwnProps> = function ({ children, open }) {
  const classes = styles();
  return (
    <>
      {children}
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress size={80} />
      </Backdrop>
    </>
  );
};

export default WithLoadingBackdrop;
