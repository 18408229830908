import axios from "axios";
import User from "../entities/User";
import { FeedbackContextType } from "../slices/feedbackSlice";

export interface FeedbackRequest {
  context: FeedbackContextType;
  rating?: number;
  text?: string;
  email: string;
  provider_type: User.ProviderType;
}

export function submitFeedback(feedback: FeedbackRequest): Promise<User.Type> {
  return new Promise<User.Type>((resolve, reject) => {
    axios
      .post("/feedback", feedback)
      .then(({ data }) => {
        resolve(User.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
