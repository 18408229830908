import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FeedbackRequest,
  submitFeedback as submitFeedbackApi,
} from "../api/feedbackAPI";
import { AppThunk } from "../app/store";
import { setCurrentUser } from "./usersSlice";

export type FeedbackContextType =
  | "general"
  | "registration"
  | "save_random_route"
  | "save_generated_route"
  | "visit_place"
  | "add_favourite_place";

interface FeedbackState {
  context?: FeedbackContextType;
}

const initialState: FeedbackState = {};

export const submitFeedback = (
  feedback: FeedbackRequest
): AppThunk<Promise<void>> => (dispatch) => {
  return new Promise((resolve, reject) => {
    submitFeedbackApi(feedback)
      .then((user) => {
        dispatch(setCurrentUser({ user }));
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    showFeedbackForm: (
      state,
      action: PayloadAction<FeedbackState["context"]>
    ) => {
      state.context = action.payload;
    },
    hideFeedbackForm: (state) => {
      state.context = undefined;
    },
  },
});

export const { showFeedbackForm, hideFeedbackForm } = feedbackSlice.actions;

export default feedbackSlice.reducer;
