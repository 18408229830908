import Container from "@material-ui/core/Container";
import mixpanel from "mixpanel-browser";
import "moment/locale/bg";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { setInterceptApiRequest, setInterceptApiResponse } from "./api/authAPI";
import AboutUsPage from "./components/common/AboutUs";
import FeedbackDialog from "./components/common/FeedbackDialog";
import Layout from "./components/common/Layout";
import Snackbar from "./components/common/Snackbar";
import HomePage from "./components/home";
import LoginPage from "./components/LoginPage";
import PasswordResetPage from "./components/passwordReset/PasswordResetPage";
import SuccessfulResetSubmitPage from "./components/passwordReset/SuccessfulResetSubmit";
import PasswordResetConfirmationComponent from "./components/PasswordResetConfirmationComponent";
import PrivateRoute from "./components/PrivateRoute";
import SignupPage from "./components/signup";
import SuccessfulSignup from "./components/signup/SuccessfulSignup";
import SuccessfulPasswordReset from "./components/SuccessfulPasswordReset";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import VerificationComponent from "./components/VerificationComponent";
import WelcomePage from "./components/WelcomePage";
import { MIXPANEL_TOKEN } from "./config";
import {
  ABOUT_US_PATH,
  APPLICATION_PATH,
  APP_PRIVACY_POLICY_PATH,
  BASE_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  PRIVACY_POLICY_PATH,
  RESET_PASSWORD_CONFIRMATION_PATH,
  RESET_PASSWORD_CONFIRMATION_SUCCESS_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  SIGNUP_PATH,
  SIGNUP_SUBSCRIPTIONS_PATH,
  SIGNUP_SUCCESS_PATH,
  TERMS_AND_CONDITIONS_PATH,
  VERIFICATION_PATH,
} from "./routes";

function App() {
  mixpanel.init(MIXPANEL_TOKEN || "", { debug: true });

  return (
    <>
      <Switch>
        <PrivateRoute
          path={APPLICATION_PATH}
          render={() => {
            setInterceptApiRequest();
            setInterceptApiResponse();
            return <HomePage />;
          }}
        />
        <Route
          path={BASE_PATH}
          render={(props) => {
            let maxWidth: "sm" | "md" | "lg" = "sm";
            if (props.location.pathname === BASE_PATH) {
              maxWidth = "lg";
            }
            if (props.location.pathname === SIGNUP_SUBSCRIPTIONS_PATH) {
              maxWidth = "md";
            }
            if (props.location.pathname === ABOUT_US_PATH) {
              maxWidth = "lg";
            }
            if (props.location.pathname === TERMS_AND_CONDITIONS_PATH) {
              maxWidth = "lg";
            }
            if (props.location.pathname === PRIVACY_POLICY_PATH) {
              maxWidth = "lg";
            }
            return (
              <Container maxWidth={maxWidth} style={{ height: "100%" }}>
                <Layout>
                  <Route path={LOGIN_PATH} component={LoginPage} />
                  <Route exact path={ABOUT_US_PATH} component={AboutUsPage} />
                  <Route
                    exact
                    path={TERMS_AND_CONDITIONS_PATH}
                    component={TermsAndConditionsPage}
                  />
                  <Route
                    exact
                    path={PRIVACY_POLICY_PATH}
                    component={PrivacyPolicyPage}
                  />
                  <Route
                    exact
                    path={FORGOT_PASSWORD_PATH}
                    component={PasswordResetPage}
                  />
                  <Route
                    exact
                    path={RESET_PASSWORD_SUCCESS_PATH}
                    component={SuccessfulResetSubmitPage}
                  />
                  <Route
                    exact
                    path={VERIFICATION_PATH}
                    component={VerificationComponent}
                  />
                  <Route
                    exact
                    path={RESET_PASSWORD_CONFIRMATION_PATH}
                    component={PasswordResetConfirmationComponent}
                  />
                  <Route
                    exact
                    path={RESET_PASSWORD_CONFIRMATION_SUCCESS_PATH}
                    component={SuccessfulPasswordReset}
                  />
                  <Route
                    exact
                    path={SIGNUP_SUCCESS_PATH}
                    component={SuccessfulSignup}
                  />
                  <Route path={SIGNUP_PATH} component={SignupPage} />
                  <Route exact path={BASE_PATH} component={WelcomePage} />
                </Layout>
              </Container>
            );
          }}
        />
      </Switch>
      <Snackbar />
      <FeedbackDialog />
    </>
  );
}

export default App;
