import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import classNames from "classnames";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import Route from "../../entities/Route";
import { GeneratedRoute } from "../../slices/routeSlice";
import { getCommonCardStyles } from "../routeCardStyle";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";

const styles = makeStyles((theme: Theme) => ({
  ...getCommonCardStyles(theme),
}));

interface OwnProps {
  route: Route.Type | GeneratedRoute;
  minHeight: number;
  onPreview: () => void;
}
type ComponentProps = OwnProps & WithMediaDownProps;

const RouteCard: React.FC<ComponentProps> = ({
  route,
  minHeight,
  onPreview,
  isMediaDown,
}) => {
  const classes = styles();

  const firstPlace = useAppSelector((state) =>
    route ? state.places.byId[route.places[0]] : null
  );
  return (
    <Card
      onClick={onPreview}
      style={{ minHeight }}
      className={classNames(classes.cardRoot, {
        [classes.mobileCardRoot]: isMediaDown,
      })}
    >
      {route ? (
        <CardMedia className={classes.cardMedia} image={firstPlace?.coverPhoto}>
          <CardHeader
            className={classes.cardHeader}
            title={
              <Typography variant="h5" align="left">
                {route ? route.title : "..."}
              </Typography>
            }
          />
          <div className={classes.seeAccentContainer}>
            {(route as GeneratedRoute).isSaved ? (
              <IconButton color="inherit">
                <CheckCircleOutlineIcon />
                &nbsp;
                <Typography variant="body2">ЗАПАЗЕН</Typography>
              </IconButton>
            ) : (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onPreview();
                }}
                color="inherit"
              >
                <Typography variant="body2">РАЗГЛЕДАЙ</Typography>
              </IconButton>
            )}
          </div>
        </CardMedia>
      ) : (
        <CircularProgress className={classes.loading} size={80} />
      )}
    </Card>
  );
};

export default WithMediaDown(RouteCard, "md");
