import { createTheme } from "@material-ui/core/styles";
import type from "@material-ui/lab/themeAugmentation";

const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: { margin: 8 },
    },
    MuiChip: {
      label: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiAlert: {
      filledSuccess: {
        backgroundColor: "#006666",
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiButton: {
      contained: {
        color: "white",
        fontFamily: "Noto-Sans-Bold",
        "&:disabled": {
          color: "#f2f2f2",
        },
      },
      outlinedPrimary: {
        fontFamily: "Noto-Sans-Bold",
        backgroundColor: "white",
        border: "1px solid #006666",
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: "Noto-Sans",
        fontSize: 16,
        color: "#00000099",
      },
    },
  },
  palette: {
    text: {
      secondary: "#00000099",
    },
    primary: {
      main: "#006666",
    },
    secondary: {
      main: "#0BF7BC",
      light: "#00666699",
    },
    error: {
      main: "#B00020",
    },
    info: {
      main: "#E77728",
      light: "#E7772890",
      dark: "#c35d16",
    },
  },
  typography: {
    fontFamily: "Noto-Sans",
    h1: {
      fontFamily: "Gilroy-ExtraBold",
      fontSize: 48,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontFamily: "Gilroy-ExtraBold",
      fontSize: 32,
    },
    h4: {
      fontSize: 24,
    },
    h5: {
      fontSize: 20,
      fontFamily: "Gilroy-ExtraBold",
    },
    body1: {
      fontSize: 14,
      fontFamily: "Noto-Sans-Bold",
    },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      fontSize: 8,
    },
    caption: {
      fontSize: 10,
      letterSpacing: 1.5,
      textTransform: "uppercase",
    },
    overline: {
      textTransform: "none",
    },
    button: {
      fontSize: 16,
      fontWeight: "normal",
      textTransform: "none",
    },
  },
});

export default theme;
