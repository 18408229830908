import axios from "axios";
import Category from "../entities/Category";

export function fetchCategoriesByIds(ids: string[]): Promise<Category.Type[]> {
  return new Promise<Category.Type[]>((resolve, reject) => {
    axios
      .get(`/categories/?ids=${ids.join("&ids=")}`)
      .then(({ data: { result } }) => {
        resolve(result.map(Category.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchCategoryIds(): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get("/categories/?include_fields=id")
      .then(({ data: { result } }) => {
        resolve(result.map((el: any) => el.uuid));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
