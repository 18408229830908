import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SwitchComponent from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import ReplyIcon from "@material-ui/icons/Reply";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { useGoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";
import { resendVerification } from "../../api/userAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_CLIENT_ID } from "../../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../constants";
import {
  APPLICATION_ABOUT_US_PATH,
  BASE_PATH,
  FEEDBACK_PATH,
  PROFILE_EDIT_PATH,
  PROFILE_PATH,
} from "../../routes";
import { showNotification } from "../../slices/notificationSlice";
import {
  changeUserEmailNotifications,
  selectCurrentUser,
  setCurrentUser,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import ProfileOverviewCard from "../common/ProfileOverviewCard";
import UploadImageAvatar from "../common/UploadImageAvatar";

const styles = makeStyles((theme: Theme) => ({
  gridContainer: {
    gap: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  settingsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  avatarContainer: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(),
  },
  iconMargin: {
    marginRight: theme.spacing(),
  },
  accordion: {
    width: "100%",
    backgroundColor: "inherit",
    padding: 0,
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    alignItems: "center",
    minHeight: "unset",
    padding: 0,
    margin: 0,
  },
  accordionSummaryContent: {
    margin: 0,
  },
  button: {
    padding: `${theme.spacing(2)}px 0`,
    justifyContent: "flex-start",
  },
  buttonStartIcon: {
    marginRight: theme.spacing(),
    marginLeft: 0,
  },
}));

const ProfileOverview: React.FC<WithMediaDownProps> = ({ isMediaDown }) => {
  const classes = styles();
  const user = useAppSelector(selectCurrentUser);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { signOut } = useGoogleLogout({
    onLogoutSuccess: () => {
      console.log("Logout Sucess!");
    },
    onFailure: () => {
      console.log("Logout Failed!");
    },
    clientId: GOOGLE_CLIENT_ID || "",
  });

  useEffect(() => {
    if (user && !user.firstName) {
      dispatch(
        showNotification({
          text: "Моля, попълни информацията в профила си!",
          type: "warning",
          duration: 5000,
        })
      );
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(PROVIDER_TYPE_KEY);
    dispatch(setCurrentUser({ user: undefined }));
    if (user?.providerType === "google") {
      signOut();
    }
    mixpanel.track("LOGOUT");
    mixpanel.reset();
    history.replace(BASE_PATH);
  };

  return (
    <Grid
      className={classes.gridContainer}
      container
      direction="column"
      alignContent="flex-start"
    >
      <div className={classes.content}>
        <div className={classes.avatarContainer}>
          <UploadImageAvatar />
        </div>
        <Typography variant="body1" color="primary">
          {user
            ? user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : "Потребител"
            : "..."}
        </Typography>
      </div>
      {user?.activated && <ProfileOverviewCard />}
      {/* {user?.activated && (
        <div>
          <Typography variant="body2" color="primary">
            Абонамент
          </Typography>
          <SubscriptionCard
            type={user?.hasSubscription ? "multiple" : "free"}
            selected
            subscriptionText={
              user.subscriptionExpirationDate
                ? `Изтича на: ${user.subscriptionExpirationDate.format(
                    "DD MMMM YYYY"
                  )}`
                : `Остават ${user?.availableSearchesLeft} пътувания`
            }
          />
        </div>
      )} */}

      <div className={classes.settingsContainer}>
        {!user?.activated && (
          <Button
            classes={{
              root: classes.button,
              startIcon: classes.buttonStartIcon,
            }}
            size="large"
            variant="text"
            color="primary"
            startIcon={<DraftsIcon />}
            fullWidth
            onClick={() => {
              resendVerification().then(() => {
                dispatch(
                  showNotification({
                    text: "Изпратихме нов имейл към твоята поща. Очаквай го скоро!",
                    type: "success",
                    duration: 3000,
                  })
                );
              });
            }}
          >
            <Typography variant="body2" color="primary">
              Не получих имейл за активация
            </Typography>
          </Button>
        )}
        <Button
          classes={{
            root: classes.button,
            startIcon: classes.buttonStartIcon,
          }}
          size="large"
          variant="text"
          color="primary"
          startIcon={<CreateIcon />}
          fullWidth
          onClick={() => {
            history.push(isMediaDown ? PROFILE_EDIT_PATH : PROFILE_PATH);
          }}
        >
          <Typography variant="body2" color="primary">
            Редактирай профил
          </Typography>
        </Button>
        <Accordion elevation={0} className={classes.accordion}>
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.accordionSummaryContent,
            }}
            expandIcon={<ExpandMore />}
          >
            <EmailIcon
              color="primary"
              fontSize="small"
              className={classes.iconMargin}
            />
            <Typography variant="body2" color="primary">
              Управление на имейл известията
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlLabel
              control={
                <SwitchComponent
                  checked={user?.hasEmailSubscription}
                  onChange={(e) => {
                    dispatch(changeUserEmailNotifications(e.target.checked));
                  }}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="overline" color="primary">
                  Получаване на имейли от GoXplore
                </Typography>
              }
              labelPlacement="start"
            />
          </AccordionDetails>
        </Accordion>
        <Button
          classes={{
            root: classes.button,
            startIcon: classes.buttonStartIcon,
          }}
          variant="text"
          color="primary"
          startIcon={<ReplyIcon />}
          fullWidth
          onClick={() => {
            history.push(FEEDBACK_PATH);
          }}
        >
          <Typography variant="body2" color="primary">
            Обратна връзка
          </Typography>
        </Button>
        <Button
          classes={{
            root: classes.button,
            startIcon: classes.buttonStartIcon,
          }}
          variant="text"
          color="primary"
          startIcon={<InfoIcon />}
          fullWidth
          onClick={() => {
            history.push(APPLICATION_ABOUT_US_PATH);
          }}
        >
          <Typography variant="body2" color="primary">
            За нас
          </Typography>
        </Button>
        <Button
          classes={{
            root: classes.button,
            startIcon: classes.buttonStartIcon,
          }}
          variant="text"
          color="primary"
          startIcon={<ExitToAppIcon />}
          fullWidth
          onClick={handleLogout}
        >
          <Typography variant="body2" color="primary">
            Изход от профила
          </Typography>
        </Button>
      </div>
    </Grid>
  );
};

export default WithMediaDown(ProfileOverview, "md");
