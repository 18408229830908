import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { LOGIN_PATH } from "../routes";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";
import WithLoadingBackdrop from "./common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing() * 3,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

type ComponentProps = RouteComponentProps<
  { userId: string; token: string },
  StaticContext,
  {}
> &
  WithMediaDownProps;

const VerificationComponent: React.FC<ComponentProps> = function ({
  history,
  match,
  isMediaDown,
}) {
  const classes = styles();
  const [fetching, setFetching] = useState<boolean>(true);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>("");

  useEffect(() => {
    const userId = match.params.userId;
    const token = match.params.token;
    axios
      .post("/validate", { user: userId, token })
      .then(() => {
        setIsVerified(true);
        setFetching(false);
      })
      .catch((error: AxiosError) => {
        const { response } = error;
        setResponseError(
          response?.data.message || "Нещо се обърка. Моля опитайте отново!"
        );
        setFetching(false);
      });
  }, [match.params.userId, match.params.token]);

  return (
    <WithLoadingBackdrop open={fetching}>
      <Container maxWidth={false} disableGutters className={classes.container}>
        <div className={classes.headerContainer}>
          <Logo size={isMediaDown ? "md" : "lg"} />
          <Typography variant="h1" color="primary">
            {responseError ? "Грешка" : "Добре дошъл"}
          </Typography>
        </div>
        {!fetching && !isVerified && !!responseError && (
          <Typography variant="h5" color="textSecondary">
            {responseError}
          </Typography>
        )}

        {!fetching && isVerified && !responseError && (
          <Typography variant="h5" color="textSecondary">
            Профилът беше успешно потвърден. Моля, влез в профила си!
          </Typography>
        )}
        <Button
          onClick={() => {
            history.replace(LOGIN_PATH);
          }}
          variant="contained"
          color="primary"
          fullWidth
          disabled={fetching}
        >
          Към приложението
        </Button>
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(VerificationComponent, "md");
