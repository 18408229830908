import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import {
  fetchPlaces as fetchPlacesApi,
  fetchPlacesByIds as fetchPlacesByIdsApi,
  fetchSinglePlaceById as fetchSinglePlaceByIdApi,
  submitPlaceComment as submitPlaceCommentApi,
  PlaceFilterConfig,
} from "../api/placeAPI";
import { AppState, AppThunk } from "../app/store";
import Place from "../entities/Place";
import { ensureCategoriesByIds } from "./categorySlice";
import { showNotification } from "./notificationSlice";
import { ensureRegionsByIds } from "./regionSlice";
import { ensureSubcategoriesByIds } from "./subcategorySlice";

export interface PlaceState {
  byId: { [key: string]: Place.Type };
  fetching: { [key: string]: boolean };
  count?: number;
  status: "idle" | "loading" | "failed";
}

type FetchPlacesPayload = { places: Place.Type[] };
type FetchInProgress = { ids: string[] };

const initialState: PlaceState = {
  byId: {},
  fetching: {},
  status: "idle",
};

export const fetchPlaces =
  (filter: PlaceFilterConfig): AppThunk<Promise<string[]>> =>
  (dispatch) => {
    return new Promise<string[]>((resolve, reject) => {
      dispatch(setStatus("loading"));
      fetchPlacesApi(filter)
        .then(({ result, count }) => {
          dispatch(ensurePlacesByIds(result)).then(() => {
            dispatch(setCount(count));
            resolve(result);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export const ensureSinglePlaceById =
  (id: string): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    const place = getState().places.byId[id];
    const isFetching = getState().places.fetching[id];
    if (!place || !isFetching) {
      return dispatch(fetchSinglePlaceById(id));
    } else {
      dispatch(setStatus("idle"));
    }
    return Promise.resolve();
  };

export const fetchSinglePlaceById =
  (id: string): AppThunk<Promise<void>> =>
  (dispatch, _) => {
    dispatch(setFetchingInProgress({ ids: [id] }));
    return fetchSinglePlaceByIdApi(id)
      .then((place) => {
        const regionPromise = dispatch(ensureRegionsByIds([place.regionId]));
        const categoryPromise = dispatch(
          ensureCategoriesByIds([place.categoryId])
        );
        const subcategoryPromise = dispatch(
          ensureSubcategoriesByIds([place.subCategoryId])
        );
        return Promise.all([
          regionPromise,
          categoryPromise,
          subcategoryPromise,
        ]).then((_) => {
          dispatch(setPlaces({ places: [place] }));
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(setFetchingFailed({ ids: [id] }));
      });
  };

export const ensurePlacesByIds =
  (ids: string[]): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    const placesToFetch = ids.filter((id) => {
      const place = getState().places.byId[id];
      const isFetching = getState().places.fetching[id];
      return !place && !isFetching;
    });
    if (placesToFetch.length) {
      return dispatch(fetchPlacesByIds(ids));
    } else {
      dispatch(setStatus("idle"));
    }
    return Promise.resolve();
  };

export const fetchPlacesByIds =
  (ids: string[]): AppThunk<Promise<void>> =>
  (dispatch, _) => {
    dispatch(setFetchingInProgress({ ids }));
    return fetchPlacesByIdsApi(ids)
      .then((places) => {
        const regionIds = places.map((el) => el.regionId);
        const categoryIds = places.map((el) => el.categoryId);
        const subcategoryIds = places.map((el) => el.subCategoryId);

        const regionPromise = dispatch(ensureRegionsByIds(regionIds));
        const categoryPromise = dispatch(ensureCategoriesByIds(categoryIds));
        const subcategoryPromise = dispatch(
          ensureSubcategoriesByIds(subcategoryIds)
        );
        return Promise.all([
          regionPromise,
          categoryPromise,
          subcategoryPromise,
        ]).then((_) => {
          dispatch(setPlaces({ places }));
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(setFetchingFailed({ ids }));
      });
  };

export const submitPlaceComment =
  (placeId: string, comment: string): AppThunk<Promise<void>> =>
  (dispatch, _) => {
    return submitPlaceCommentApi(placeId, comment).then((place) => {
      mixpanel.track("SUBMIT_COMMENT", {
        Place: placeId,
        Comment: comment,
      });
      dispatch(
        showNotification({
          text: "Коментарът беше записан успешно!",
          type: "success",
        })
      );
      dispatch(setPlaces({ places: [place] }));
    });
  };

export const placeSlice = createSlice({
  name: "places",
  initialState,
  reducers: {
    setPlaces: (state, action: PayloadAction<FetchPlacesPayload>) => {
      const { places } = action.payload;
      places.forEach((place) => {
        state.byId[place.id] = { ...place };
        state.fetching[place.id] = false;
      });
      state.status = "idle";
    },
    setFetchingInProgress: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      const fetching: { [key: string]: boolean } = {};
      ids.forEach((id) => {
        fetching[id] = true;
      });
      state.fetching = {
        ...state.fetching,
        ...fetching,
      };
      state.status = "loading";
    },
    setFetchingFailed: (state, action: PayloadAction<FetchInProgress>) => {
      const { ids } = action.payload;
      const fetching: { [key: string]: boolean } = {};
      ids.forEach((id) => {
        fetching[id] = false;
      });
      state.fetching = {
        ...state.fetching,
        ...fetching,
      };
      state.status = "failed";
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setStatus: (state, action: PayloadAction<PlaceState["status"]>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setPlaces,
  setCount,
  setStatus,
  setFetchingInProgress,
  setFetchingFailed,
} = placeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPlaceById = (state: AppState, id: string) =>
  state.places.byId[id];

export const selectPlaceByIds = (state: AppState, ids: string[]) =>
  ids.map((id) => state.places.byId[id]).filter((el) => !!el);

export const selectCommentsByPlaceId = (state: AppState, id: string) => {
  const place = state.places.byId[id];
  if (!place) {
    return [];
  }
  const sorted = place.notes.slice().sort((a, b) => {
    if (a.createdAt.isBefore(b.createdAt)) {
      return 1;
    } else if (b.createdAt.isBefore(a.createdAt)) {
      return -1;
    }
    return 0;
  });
  return sorted;
};

export default placeSlice.reducer;
