import Place from "./entities/Place";

const GOOGLE_MAPS_API = "https://www.google.com/maps/dir/?api=1";

export const generateGoogleMapsLink = (places: Place.Type[]) => {
  const urlSearchParams = new URLSearchParams();
  const originPlace = places[0];
  const destinationPlace = places[places.length - 1];
  const waypoints = places.slice(1, places.length - 1);
  let waypointsText = "";
  waypoints.forEach((waypoint, idx) => {
    if (idx === 0) {
      waypointsText = waypointsText.concat(
        `${waypoint.location.latitude}, ${waypoint.location.longitude}`
      );
    } else {
      waypointsText = waypointsText.concat(
        `|${waypoint.location.latitude}, ${waypoint.location.longitude}`
      );
    }
  });
  urlSearchParams.set(
    "origin",
    `${originPlace.location.latitude}, ${originPlace.location.longitude}`
  );
  urlSearchParams.set(
    "destination",
    `${destinationPlace.location.latitude}, ${destinationPlace.location.longitude}`
  );
  if (waypointsText) {
    urlSearchParams.set("waypoints", waypointsText);
  }
  urlSearchParams.set("travelmode", "driving");
  return `${GOOGLE_MAPS_API}&${urlSearchParams.toString()}`;
};
