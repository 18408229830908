import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { AxiosError } from "axios";
import { Field, Formik, FormikHelpers } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { facebookLogin, googleLogin, login } from "../api/authAPI";
import { useAppDispatch } from "../app/hooks";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../constants";
import User from "../entities/User";
import {
  APPLICATION_PATH,
  FORGOT_PASSWORD_PATH,
  SIGNUP_PATH,
  LOGIN_SUBSCRIPTION_BENEFITS_PATH,
} from "../routes";
import { resetRegistrationState } from "../slices/registrationSlice";
import { getCommonStyles } from "../style";
import { FormikValidationSchema } from "../validation";
import BackButton from "./common/BackButton";
import FacebookSignupButton from "./common/FacebookSignupButton";
import FormikTextField from "./common/formik/FormikTextField";
import GoogleSignupButton from "./common/GoogleSignupButton";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";
import { getSignupStyles } from "./common/signupStyle";
import WithLoadingBackdrop from "./common/WithLoadingBackdrop";
import BenefitsOverviewPage from "./subscription/BenefitsOverviewPage";

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  basicLoginInput: {
    marginBottom: theme.spacing(),
  },
  backButton: {
    fontSize: "1rem",
    alignSelf: "flex-start",
  },
  submitError: {
    marginBottom: theme.spacing(),
  },
}));

export interface LoginFormikValues {
  email: string;
  password: string;
  showPassword: boolean;
  submitError: string;
}

type ComponentProps = RouteComponentProps & WithMediaDownProps;

const LoginPage: React.FC<ComponentProps> = function ({
  history,
  location,
  isMediaDown,
}) {
  const classes = styles();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const [socialLoading, setSocialLoading] = useState<boolean>(false);
  const [socialLogin, setSocialLogin] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      history.replace(APPLICATION_PATH);
    }
  }, []);

  useEffect(() => {
    dispatch(resetRegistrationState());
  }, []);

  const initialValues = useMemo<LoginFormikValues>(
    () => ({
      email: "",
      password: "",
      showPassword: false,
      submitError: "",
    }),
    []
  );

  const handleSocialBenefitSubmit = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    history.replace(urlSearchParams.get("next") || APPLICATION_PATH);
  }, [location]);

  const handleSuccessfulSignup = useCallback(
    (token: string, providerType: User.ProviderType) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      localStorage.setItem(PROVIDER_TYPE_KEY, providerType);
    },
    [history, location]
  );

  const handleSignup = useCallback(
    (
      { email, password }: LoginFormikValues,
      { setSubmitting }: FormikHelpers<LoginFormikValues>
    ) => {
      setSubmitting(true);
      login(email, password)
        .then((data) => {
          setSubmitting(false);
          handleSuccessfulSignup(data.token, "local");
          const urlSearchParams = new URLSearchParams(location.search);
          history.replace(urlSearchParams.get("next") || APPLICATION_PATH);
        })
        .catch((_) => {
          setSubmitting(false);
        });
    },
    [history, location, handleSuccessfulSignup]
  );

  const handleFacebookResponse = useCallback(
    (userInfo: ReactFacebookLoginInfo) => {
      facebookLogin(userInfo)
        .then((data) => {
          if (data.token) {
            setSocialLoading(false);
            handleSuccessfulSignup(data.token, "facebook");
            if (data.isNew) {
              history.push(LOGIN_SUBSCRIPTION_BENEFITS_PATH);
            } else {
              handleSocialBenefitSubmit();
            }
          } else {
            alert("Something went wrong!");
          }
        })
        .catch((error: AxiosError) => {
          const { response } = error;
          console.log("Error -> ", response);
        });
    },
    [handleSuccessfulSignup]
  );

  const handleGoogleResponse = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (response.code) {
        console.log("GoogleLoginResponseOffline -> ", response.code);
      } else {
        const { profileObj, tokenId } = response as GoogleLoginResponse;
        googleLogin(profileObj.email, tokenId)
          .then((data) => {
            if (data.token) {
              setSocialLoading(false);
              handleSuccessfulSignup(data.token, "google");
              if (data.isNew) {
                history.push(LOGIN_SUBSCRIPTION_BENEFITS_PATH);
              } else {
                handleSocialBenefitSubmit();
              }
            } else {
              alert("Something went wrong!");
            }
          })
          .catch((error: AxiosError) => {
            const { response } = error;
            console.log("Error -> ", response);
          });
      }
    },
    [handleSuccessfulSignup]
  );

  return (
    <React.Fragment>
      {!isMediaDown && <BackButton />}
      <Formik
        initialValues={initialValues}
        validationSchema={FormikValidationSchema}
        onSubmit={handleSignup}
      >
        {({
          isSubmitting,
          values: { email, password, showPassword, submitError },
          setFieldValue,
          submitForm,
        }) => (
          <WithLoadingBackdrop open={isSubmitting || socialLoading}>
            <Switch>
              <Route
                exact
                path={LOGIN_SUBSCRIPTION_BENEFITS_PATH}
                render={() => {
                  return (
                    <BenefitsOverviewPage
                      onSubmitForm={handleSocialBenefitSubmit}
                    />
                  );
                }}
              />
              <Route exact path={path}>
                <Container
                  maxWidth={false}
                  disableGutters
                  className={classes.container}
                >
                  <div className={classes.titleContainer}>
                    {isMediaDown && <BackButton />}
                    <Logo size={isMediaDown ? "md" : "lg"} />
                    <Typography variant="h1" color="primary">
                      Вход
                    </Typography>
                  </div>
                  <div className={classes.content}>
                    <div className={classes.socialMediaContainer}>
                      <FacebookSignupButton
                        redirectUri={window.location.href}
                        callback={handleFacebookResponse}
                        onFailure={() => {
                          setSocialLoading(false);
                          setSocialLogin(false);
                        }}
                        onClick={() => {
                          setSocialLoading(true);
                          setSocialLogin(true);
                        }}
                      />
                      <GoogleSignupButton
                        onSuccess={handleGoogleResponse}
                        onFailure={() => {
                          setSocialLoading(false);
                          setSocialLogin(false);
                        }}
                        onRequest={() => {
                          setSocialLoading(true);
                          setSocialLogin(true);
                        }}
                      />
                    </div>
                    <div className={classes.divider}>
                      <Typography variant="body2" color="textSecondary">
                        или
                      </Typography>
                    </div>
                    <div className={classes.basicCredentialsContainer}>
                      <Field
                        component={FormikTextField}
                        name="email"
                        className={classes.basicLoginInput}
                        label="Въведи е-поща"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            type: "email",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Field
                        component={FormikTextField}
                        name="password"
                        className={classes.basicLoginInput}
                        label="Въведи парола"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            type: showPassword ? "text" : "password",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faLock} />
                            </InputAdornment>
                          ),
                          endAdornment: password ? (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setFieldValue("showPassword", !showPassword);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                      {submitError && (
                        <Typography
                          component="p"
                          variant="caption"
                          color="error"
                          className={classes.submitError}
                        >
                          {submitError}
                        </Typography>
                      )}
                      <Button
                        onClick={submitForm}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting || !email || !password}
                      >
                        Влез
                      </Button>
                    </div>
                  </div>
                  <div className={classes.footerContainer}>
                    <Typography variant="body2" color="primary">
                      Нямаш профил?&nbsp;
                      <Link to={SIGNUP_PATH}>
                        <Typography
                          variant="body1"
                          component="span"
                          color="primary"
                        >
                          Регистрирай се
                        </Typography>
                      </Link>
                    </Typography>
                    <Link to={FORGOT_PASSWORD_PATH}>
                      <Typography variant="body1" color="primary">
                        Забравена парола
                      </Typography>
                    </Link>
                  </div>
                </Container>
              </Route>
            </Switch>
          </WithLoadingBackdrop>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default WithMediaDown(LoginPage, "md");
