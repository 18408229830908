import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { generateGoogleMapsLink } from "../../googleMapsUtils";
import { selectPlaceByIds } from "../../slices/placeSlice";
import { ensureRoutesByIds } from "../../slices/routeSlice";
import {
  changeRouteStatus,
  changeRouteTitle,
  selectSavedRouteById,
} from "../../slices/usersSlice";
import BackButton from "../common/BackButton";
import { WithMediaDown, WithMediaDownProps } from "../common/hoc/withMedia";
import PlaceOverviewCard from "../common/PlaceOverviewCard";
import RouteCompleteConfirmationDialog from "../common/RouteCompleteConfirmationDialog";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  gridContainer: {
    overflow: "auto",
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  gridItem: {
    minWidth: "calc(100% - 16px)",
  },
  mobileButtonSpace: {
    marginTop: theme.spacing(),
  },
  desktopButtonSpace: {
    marginLeft: theme.spacing(),
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "0 auto",
    paddingBottom: theme.spacing(),
  },
  desktopHeader: {
    justifyContent: "space-between",
  },
  actionContainerMobile: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing()}px 0`,
    background: theme.palette.common.white,
  },
  actionContainerDesktop: {
    display: "flex",
    minWidth: "fit-content",
  },
  loading: {
    alignSelf: "center",
  },
  completedMobileTitle: {
    marginLeft: theme.spacing(),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
}));

type ComponentProps = RouteComponentProps<{ id: string }> & WithMediaDownProps;

const RouteOverviewPage: React.FC<ComponentProps> = ({
  match,
  isMediaDown,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.me);
  const savedRoute = useAppSelector((state) =>
    selectSavedRouteById(state, match.params.id)
  );
  const route = useAppSelector((state) =>
    savedRoute ? state.routes.byId[savedRoute.routeId] : null
  );
  const places = useAppSelector((state) =>
    savedRoute && route ? selectPlaceByIds(state, route.places) : []
  );
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>(
    savedRoute ? savedRoute.title : ""
  );
  const [
    visitAllPlacesDialogOpen,
    setVisitAllPlacesDialogOpen,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [removing, setRemoving] = useState<boolean>(false);
  const [
    completeRouteDialogOpen,
    setCompleteRouteDialogOpen,
  ] = useState<boolean>(false);

  const isCompletedRoute = useMemo(() => {
    return (
      savedRoute &&
      savedRoute.status === "completed" &&
      !!savedRoute.completedDate
    );
  }, [savedRoute]);

  useEffect(() => {
    if (savedRoute && !route) {
      dispatch(ensureRoutesByIds([savedRoute.routeId])).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const handleChangeRouteTitle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (savedRoute && savedRoute.title !== newTitle) {
        event.stopPropagation();
        dispatch(changeRouteTitle(savedRoute.id, newTitle))
          .then(() => {
            setIsEditingTitle(false);
            setNewTitle("");
          })
          .catch(() => {
            setIsEditingTitle(false);
            setNewTitle("");
          });
      } else {
        setIsEditingTitle(false);
        setNewTitle("");
      }
    },
    [newTitle, savedRoute]
  );

  const handleLoadOnMap = useCallback(() => {
    window.open(generateGoogleMapsLink(places), "_blank");
  }, [places]);

  const handleVisitedClick = useCallback(
    (visitedPlaces: string[]) => {
      if (route) {
        if (route.places.length && !isCompletedRoute) {
          const shoulCompleteRoute = route.places.every((el) =>
            visitedPlaces.includes(el)
          );
          setCompleteRouteDialogOpen(shoulCompleteRoute);
        }
      }
    },
    [route, isCompletedRoute]
  );

  const handleNotCompleteRoute = useCallback(() => {
    if (savedRoute) {
      setRemoving(true);
      dispatch(changeRouteStatus(savedRoute.id, "not_started", false)).then(
        () => {
          setRemoving(false);
        }
      );
    }
  }, [savedRoute]);

  const handleCompleteRoute = useCallback(
    (visitAllPlaces: boolean) => {
      if (savedRoute) {
        setLoading(true);
        dispatch(changeRouteStatus(savedRoute.id, "completed", visitAllPlaces))
          .then(() => {
            setLoading(false);
            setCompleteRouteDialogOpen(false);
            setVisitAllPlacesDialogOpen(false);
          })
          .catch(() => {
            setLoading(false);
            setCompleteRouteDialogOpen(false);
            setVisitAllPlacesDialogOpen(false);
          });
      }
    },
    [savedRoute]
  );

  const handleRouteStatusChange = useCallback(() => {
    let areAllVisited = false;
    if (isCompletedRoute) {
      handleNotCompleteRoute();
    } else if (route && user) {
      areAllVisited = route.places.every((el) =>
        user.visitedPlaces.includes(el)
      );
      if (areAllVisited) {
        handleCompleteRoute(false);
      } else {
        setVisitAllPlacesDialogOpen(true);
      }
    }
    mixpanel.track("ROUTE_STATUS_CHANGE", {
      From: "Overvie Page",
      "Mark As": isCompletedRoute ? "Not Completed" : "Completed",
      "All visited": areAllVisited,
    });
  }, [user, user?.visitedPlaces, route, route?.places, isCompletedRoute]);

  const buttonContainer = useMemo(
    () => (
      <div
        className={classNames(
          { [classes.actionContainerMobile]: isMediaDown },
          { [classes.actionContainerDesktop]: !isMediaDown }
        )}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleRouteStatusChange}
        >
          ОТБЕЛЕЖИ КАТО {isCompletedRoute ? "НЕПОСЕТЕН" : "ПОСЕТЕН"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleLoadOnMap}
          className={classNames(
            { [classes.mobileButtonSpace]: isMediaDown },
            { [classes.desktopButtonSpace]: !isMediaDown }
          )}
        >
          ПОКАЖИ НА КАРТАТА
        </Button>
      </div>
    ),
    [
      user,
      user?.visitedPlaces,
      route,
      route?.places,
      isCompletedRoute,
      isMediaDown,
    ]
  );

  if (!savedRoute) {
    return (
      <Container className={classes.container} maxWidth="lg" disableGutters>
        {loading ? (
          <CircularProgress size={80} className={classes.loading} />
        ) : (
          <React.Fragment>
            {isMediaDown && <BackButton />}
            <Typography
              variant={isMediaDown ? "h5" : "h3"}
              color="primary"
              align="center"
            >
              Не беше открит такъв маршрут!
            </Typography>
          </React.Fragment>
        )}
      </Container>
    );
  }

  return (
    <WithLoadingBackdrop open={removing || !route}>
      <Container className={classes.container} maxWidth="lg" disableGutters>
        <div
          className={classNames(classes.header, {
            [classes.desktopHeader]: !isMediaDown,
          })}
        >
          {isMediaDown && <BackButton />}
          <React.Fragment>
            {isEditingTitle ? (
              <div
                className={classNames(classes.header, {
                  [classes.desktopHeader]: !isMediaDown,
                })}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  color="primary"
                  autoFocus
                  value={newTitle}
                  onFocus={() => {
                    if (!newTitle) {
                      setNewTitle(savedRoute.title);
                    }
                  }}
                  onBlur={() => {
                    setIsEditingTitle(false);
                    setNewTitle(savedRoute.title);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewTitle(e.target.value)
                  }
                />
                <IconButton onMouseDown={handleChangeRouteTitle}>
                  <CheckIcon
                    fontSize={isMediaDown ? "small" : "medium"}
                    color="primary"
                  />
                </IconButton>
              </div>
            ) : (
              <div className={classes.titleContainer}>
                <Typography
                  variant={isMediaDown ? "h5" : "h3"}
                  color="primary"
                  noWrap={isMediaDown}
                  align="left"
                >
                  {savedRoute.title}
                </Typography>
                <IconButton onClick={() => setIsEditingTitle(true)}>
                  <EditIcon
                    fontSize={isMediaDown ? "small" : "medium"}
                    color="primary"
                  />
                </IconButton>
              </div>
            )}
            {!isMediaDown && buttonContainer}
          </React.Fragment>
        </div>
        {isCompletedRoute ? (
          <Typography
            variant="body1"
            color="primary"
            className={classNames({
              [classes.completedMobileTitle]: isMediaDown,
            })}
          >
            Завършен на:{" "}
            {savedRoute &&
              savedRoute.completedDate &&
              savedRoute.completedDate.format("DD MMMM YYYY")}
          </Typography>
        ) : null}
        <Grid
          className={classes.gridContainer}
          container
          spacing={1}
          wrap={isMediaDown ? "nowrap" : "wrap"}
        >
          {route &&
            route.places.map((placeId) => {
              return (
                <Grid
                  key={placeId}
                  item
                  xs={6}
                  lg={12}
                  className={isMediaDown ? classes.gridItem : ""}
                >
                  <PlaceOverviewCard
                    variant={isMediaDown ? "vertical" : "horizontal"}
                    placeId={placeId}
                    onVisitedClick={handleVisitedClick}
                  />
                </Grid>
              );
            })}
        </Grid>
        {isMediaDown && buttonContainer}
        <Dialog
          open={completeRouteDialogOpen}
          onClose={(_, reson) => {
            if (reson !== "backdropClick") {
              setCompleteRouteDialogOpen(false);
            }
          }}
        >
          <DialogTitle disableTypography>
            <Typography variant="h5" color="primary">
              Завърши маршрут
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Всички обекти от маршрута ти са отбелязани като посетени. Искаш ли
              да отбележиш маршрута си като посетен?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setCompleteRouteDialogOpen(false);
              }}
            >
              Затвори
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCompleteRouteDialogOpen(false);
                handleCompleteRoute(false);
              }}
              autoFocus
            >
              Потвърди
            </Button>
          </DialogActions>
        </Dialog>
        <RouteCompleteConfirmationDialog
          open={visitAllPlacesDialogOpen}
          onConfirm={() => {
            handleCompleteRoute(true);
          }}
          onClose={() => {
            handleCompleteRoute(false);
            setVisitAllPlacesDialogOpen(false);
          }}
        />
      </Container>
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(RouteOverviewPage, "md");
