import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useMemo } from "react";
import { PasswordValidationText } from "../../validation";

const ERROR_PROGRESS_STEP = 25;

const styles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  titleColor: {
    fontSize: 10,
    color: theme.palette.secondary.light,
  },
  list: {
    paddingInlineStart: theme.spacing() * 2,
    textAlign: "left",
    listStyleType: "circle",
  },
}));

interface OwnProps {
  errors: string[];
}

const PasswordStrength: React.FC<OwnProps> = function ({ errors }) {
  const classes = styles();
  const passwordStrength = useMemo(
    () => 100 - errors.length * ERROR_PROGRESS_STEP,
    [errors]
  );
  return (
    <div className={classes.container}>
      <Typography variant="overline" className={classes.titleColor}>
        Сила на паролата:
      </Typography>
      <LinearProgress
        color="primary"
        variant="determinate"
        value={passwordStrength}
      />
      <ul className={classes.list}>
        {Object.keys(PasswordValidationText).map((key) => (
          <li key={key}>
            <Typography
              variant="body1"
              color={errors.includes(key) ? "textSecondary" : "primary"}
            >
              {PasswordValidationText[key]}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordStrength;
