import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBlogPost } from "../api/blogPostsApi";
import { AppState, AppThunk } from "../app/store";
import BlogPost from "../entities/BlogPost";
import { ensureSinglePlaceById } from "./placeSlice";

export interface BlogPostsState {
  byId: { [key: string]: BlogPost.Type };
  fetching: { [key: string]: boolean };
  status: "idle" | "loading" | "failed";
}

type FetchBlogPostPayload = { post: BlogPost.Type };

const initialState: BlogPostsState = {
  byId: {},
  fetching: {},
  status: "idle",
};

export const ensureBlogPostById = (id: string): AppThunk<Promise<void>> => (
  dispatch,
  getState
) => {
  const blogPost = getState().blogPosts.byId[id];
  const isFetching = getState().blogPosts.fetching[id];
  if (!blogPost && !isFetching) {
    return dispatch(fetchBlogPostById(id));
  }
  return Promise.resolve();
};

export const fetchBlogPostById = (id: string): AppThunk<Promise<void>> => (
  dispatch,
  _
) => {
  dispatch(setFetchingInProgress(id));
  return fetchBlogPost(id)
    .then((blogPost) => {
      dispatch(ensureSinglePlaceById(blogPost.placeId)).then(() => {
        dispatch(setSingleBlogPost({ post: blogPost }));
      });
    })
    .catch((error) => {
      dispatch(setFetchingFailed());
    });
};

export const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState,
  reducers: {
    setSingleBlogPost: (state, action: PayloadAction<FetchBlogPostPayload>) => {
      const { post } = action.payload;
      state.byId[post.id] = { ...post };
      state.fetching[post.id] = false;
      state.status = "idle";
    },
    setFetchingInProgress: (state, action) => {
      state.fetching[action.payload] = true;
      state.status = "loading";
    },
    setFetchingFailed: (state) => {
      state.status = "failed";
    },
  },
});

export const {
  setSingleBlogPost,
  setFetchingInProgress,
  setFetchingFailed,
} = blogPostsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBlogPostById = (state: AppState, id: string) =>
  state.blogPosts.byId[id];

export default blogPostsSlice.reducer;
