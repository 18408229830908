import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import Carousel from "react-alice-carousel";
import { Link, RouteComponentProps } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../constants";
import {
  ABOUT_US_PATH,
  APPLICATION_PATH,
  LOGIN_PATH,
  PRIVACY_POLICY_PATH,
  SIGNUP_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from "../routes";
import { getCommonStyles } from "../style";
import AccentCard from "./common/AccentCard";
import { WithMediaDown, WithMediaDownProps } from "./common/hoc/withMedia";
import Logo from "./common/Logo";
import { getSignupStyles } from "./common/signupStyle";

const CAROUSEL_CONFIG = {
  0: { items: 1 },
  600: { items: 2 },
  1280: { items: 3 },
};

const styles = makeStyles((theme: Theme) => ({
  ...getSignupStyles(theme),
  ...getCommonStyles(theme),
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  desktopInnerContainer: {
    flex: 1,
  },
  cardMedia: {
    height: "100%",
  },
  cardRoot: {
    height: 200,
    color: "white",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  cardTitle: {
    fontFamily: "Mixa-Bold",
  },
  cardWhy: {
    backgroundImage: "url(https://cdn.goxplore.bg/private/card_why.png)",
  },
  cardWhat: {
    backgroundImage: "url(https://cdn.goxplore.bg/private/card_what.png)",
  },
  cardHow: {
    backgroundImage: "url(https://cdn.goxplore.bg/private/card_how.png)",
  },
  button: {
    margin: `${theme.spacing()}px 0`,
    maxWidth: 350,
    width: "100%",
  },
  footer: {
    margin: `${theme.spacing()}px 0`,
  },
}));

type ComponentProps = RouteComponentProps & WithMediaDownProps;

const WelcomePage: React.FC<ComponentProps> = ({ history, isMediaDown }) => {
  const classes = styles();
  const accentReference = useRef<HTMLDivElement>(null);
  const carouselItems = useMemo(() => {
    const result = [0, 1, 2].map((el) => (
      <Card
        key={el}
        className={classNames(
          classes.cardRoot,
          {
            [classes.cardWhy]: el === 0,
          },
          {
            [classes.cardWhat]: el === 1,
          },
          {
            [classes.cardHow]: el === 2,
          }
        )}
      >
        <CardContent className={classes.cardContent}>
          <Typography variant="h3" className={classes.cardTitle}>
            {el === 0 && "Защо го правим?"}
            {el === 1 && "Какво правим?"}
            {el === 2 && "Как го правим?"}
          </Typography>
          <Typography variant="body1">
            {el === 0 &&
              "Защото обичаме да пътуваме и посещаваме нови прекрасни кътчета в България и искаме и ти да можеш да им се насладиш!"}
            {el === 1 &&
              "Даваме ти възможност лесно и бързо да си организираш незабравими пътувания и да преоткриваш родни красоти!"}
            {el === 2 &&
              "В GoXplore ти предлагаме разнообразни обекти и маршрути, за да имаш винаги ясна посока към следващото ти приключение!"}
          </Typography>
        </CardContent>
      </Card>
    ));
    if (!isMediaDown) {
      result.unshift(<AccentCard />);
    }
    return result;
  }, [isMediaDown]);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      history.replace(APPLICATION_PATH);
    }
  }, []);

  return (
    <Container
      maxWidth={isMediaDown ? "sm" : "lg"}
      className={classes.container}
      disableGutters
    >
      <div
        className={classNames(classes.innerContainer, {
          [classes.desktopInnerContainer]: !isMediaDown,
        })}
      >
        <div className={classes.titleContainer}>
          <Logo size={isMediaDown ? "md" : "lg"} />
          <Typography
            variant={isMediaDown ? "h5" : "h1"}
            color="primary"
            align="center"
          >
            Добре дошъл в платформата!
          </Typography>
        </div>
        <div className={classes.content}>
          <Carousel
            responsive={CAROUSEL_CONFIG}
            autoPlay
            infinite
            autoPlayInterval={5000}
            disableButtonsControls
            items={carouselItems}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={() => history.push(LOGIN_PATH)}
          >
            Вход
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => history.push(SIGNUP_PATH)}
          >
            Регистрация
          </Button>
        </div>

        {isMediaDown && (
          <div className={classes.content}>
            <Typography variant="body2">АКЦЕНТ НА МЕСЕЦА</Typography>
            <IconButton
              size="small"
              onClick={() =>
                accentReference.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </IconButton>
          </div>
        )}
        {isMediaDown && (
          <div ref={accentReference}>
            <AccentCard />
          </div>
        )}
        <div
          className={classNames(classes.footerContainer, {
            [classes.footer]: isMediaDown,
          })}
        >
          <Link to={ABOUT_US_PATH}>
            <Typography variant="body1" color="primary">
              За нас
            </Typography>
          </Link>
          <Link to={TERMS_AND_CONDITIONS_PATH}>
            <Typography variant="body1" color="primary">
              Условия за ползване
            </Typography>
          </Link>
          <Link to={PRIVACY_POLICY_PATH}>
            <Typography variant="body1" color="primary">
              Политика за поверителност
            </Typography>
          </Link>
          <Typography variant="body2" color="primary">
            Всички права запазени GoXplore 2021
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default WithMediaDown(WelcomePage, "md");
