import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useState } from "react";
import { useGoogleLogout } from "react-google-login";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { deactivateProfile } from "../../../api/userAPI";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GOOGLE_CLIENT_ID } from "../../../config";
import { ACCESS_TOKEN_KEY, PROVIDER_TYPE_KEY } from "../../../constants";
import { BASE_PATH, PROFILE_EDIT_PASSWORD_PATH } from "../../../routes";
import { showNotification } from "../../../slices/notificationSlice";
import {
  changeUserProfileNames,
  selectCurrentUser,
  setCurrentUser,
} from "../../../slices/usersSlice";
import BackButton from "../BackButton";
import UploadImageAvatar from "../UploadImageAvatar";

const styles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
  },
  formContainerSpace: {
    justifyContent: "space-around ",
    textAlign: "center",
  },
  buttonSpace: {
    margin: `${theme.spacing() / 2}px 0`,
  },
  avatarContainer: {
    margin: `${theme.spacing(2)}px auto`,
    width: 180,
    height: 180,
  },
  confirmButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const MobileEditProfile: React.FC<RouteComponentProps> = ({ history }) => {
  const user = useAppSelector(selectCurrentUser);
  const profileUpdateStatus = useAppSelector(
    (state) => state.users.profileUpdateStatus
  );
  const classes = styles();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>(
    user ? user.firstName : ""
  );
  const [lastName, setLastName] = useState<string>(user ? user.lastName : "");
  const [
    removeProfileConfirmationDialog,
    setRemoveProfileConfirmationDialog,
  ] = useState<boolean>(false);

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(changeUserProfileNames(firstName, lastName));
    },
    [firstName, lastName]
  );

  const handleDialogClose = useCallback(() => {
    setRemoveProfileConfirmationDialog(false);
  }, []);

  const { signOut } = useGoogleLogout({
    onLogoutSuccess: () => {
      console.log("Logout Sucess!");
    },
    onFailure: () => {
      console.log("Logout Failed!");
    },
    clientId: GOOGLE_CLIENT_ID || "",
  });

  const handleDeactivateProfile = useCallback(() => {
    setRemoveProfileConfirmationDialog(false);
    deactivateProfile()
      .then(() => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(PROVIDER_TYPE_KEY);
        dispatch(setCurrentUser({ user: undefined }));
        if (user?.providerType === "google") {
          signOut();
        }
        mixpanel.track("DEACTIVATE_MOBILE");
        mixpanel.reset();
        history.replace(BASE_PATH);
        dispatch(
          showNotification({
            text: "Профилът беше успешно деактивиран!",
            type: "success",
            duration: 5000,
          })
        );
      })
      .catch(() => {
        showNotification({
          text: "Промените не бяха записани. Моля, опитай отново!",
          type: "warning",
        });
      });
  }, []);

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.buttonContent}>
          <BackButton label="Моят профил" />
        </div>
        <div className={classes.avatarContainer}>
          <UploadImageAvatar />
        </div>
      </div>
      <form className={classes.formContainer} onSubmit={handleFormSubmit}>
        <TextField
          variant="outlined"
          label="Име"
          value={firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFirstName(e.target.value)
          }
          helperText=" "
        />
        <TextField
          className={classes.buttonSpace}
          variant="outlined"
          label="Фамилия"
          value={lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLastName(e.target.value)
          }
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.buttonSpace}
          disabled={
            (!firstName && !lastName) || profileUpdateStatus === "names"
          }
        >
          ЗАПАЗИ ПРОМЕНИТЕ
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.buttonSpace}
          onClick={() => {
            history.push(PROFILE_EDIT_PASSWORD_PATH);
          }}
        >
          ПРОМЕНИ ПАРОЛА
        </Button>
        <Button
          variant="text"
          className={classes.buttonSpace}
          onClick={() => {
            setRemoveProfileConfirmationDialog(true);
          }}
        >
          <Typography variant="body1" color="error">
            ДЕАКТИВИРАЙ ПРОФИЛА
          </Typography>
        </Button>
        <Dialog
          open={removeProfileConfirmationDialog}
          onClose={(_, reson) => {
            if (reson !== "backdropClick") {
              handleDialogClose();
            }
          }}
        >
          <DialogTitle disableTypography>
            <Typography variant="h5" color="primary">
              Деактивиране на профила
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Сигурен ли си, че искаш да деактивираш профила си?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDialogClose}
            >
              Затвори
            </Button>
            <Button
              className={classes.confirmButton}
              variant="contained"
              onClick={handleDeactivateProfile}
              autoFocus
            >
              Деактивирай
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default withRouter(MobileEditProfile);
