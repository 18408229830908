import axios from "axios";
import Route from "../entities/Route";

export interface GenerateRoutesConfig {
  name: string;
  longitude: number;
  latitude: number;
}

export function fetchRoutesByIds(ids: string[]): Promise<Route.Type[]> {
  return new Promise<Route.Type[]>((resolve, reject) => {
    axios
      .get(`/routes/?ids=${ids.join("&ids=")}`)
      .then(({ data: { result } }) => {
        resolve(result.map(Route.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchRandomRoute(): Promise<Route.Type> {
  return new Promise<Route.Type>((resolve, reject) => {
    axios
      .get("/routes/random-route")
      .then(({ data }) => {
        resolve(Route.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateOwnRoute(
  routeIds: string[],
  optimizeRoute: boolean
): Promise<Route.Type> {
  return new Promise<Route.Type>((resolve, reject) => {
    const urlSearchParams = new URLSearchParams();
    routeIds.forEach((id) => {
      urlSearchParams.append("ids", id);
    });
    urlSearchParams.set("optimize", String(optimizeRoute));
    axios
      .get(`/routes/generate-own-route?${urlSearchParams.toString()}`)
      .then(({ data }) => {
        resolve(Route.parse(data));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateRoutes(
  config: GenerateRoutesConfig
): Promise<Route.Type[]> {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set("latitude", String(config.latitude));
  urlSearchParams.set("longitude", String(config.longitude));
  return new Promise<Route.Type[]>((resolve, reject) => {
    axios
      .get(`/routes/generate-routes?${urlSearchParams.toString()}`)
      .then(({ data: { routes } }) => {
        resolve(routes.map(Route.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
