import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import classNames from "classnames";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Lightbox from "react-image-lightbox";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GOOGLE_API_KEY } from "../../config";
import { selectCategoryById } from "../../slices/categorySlice";
import { ensurePlacesByIds, selectPlaceById } from "../../slices/placeSlice";
import { selectRegionById } from "../../slices/regionSlice";
import { selectSubcategoryById } from "../../slices/subcategorySlice";
import {
  selectIsFavouritePlace,
  selectIsVisitedPlace,
  updateFavouritePlaces,
  updateVisitedPlaces,
} from "../../slices/usersSlice";
import { WithMediaDown, WithMediaDownProps } from "./hoc/withMedia";
import PlaceCommentsBackdrop from "./PlaceCommentsBackdrop";
import WithLoadingBackdrop from "./WithLoadingBackdrop";

interface OwnProps {
  variant: "vertical" | "horizontal";
  placeId: string;
  onFavouriteClick?: (favouritePlaces: string[]) => void;
  onVisitedClick?: (visitedPlaces: string[]) => void;
}

const styles = makeStyles((theme: Theme) => ({
  cardRoot: {
    borderRadius: 20,
  },
  gridItemPadding: {
    padding: `0 ${theme.spacing(2)}px !important`,
  },
  cardMedia: {
    width: "100%",
    position: "relative",
    color: theme.palette.common.white,
    height: 300,
  },
  cardMediaHorizontal: {
    height: "80%",
  },
  verticalMapSize: {
    minHeight: 250,
    border: 0,
  },
  horizontalMapSize: {
    height: 230,
    border: 0,
  },
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  seeGaleryContainer: {
    position: "absolute",
    bottom: 10,
    right: 20,
  },
  photoLength: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(3),
    height: theme.spacing(3),
    bottom: 20,
    left: 20,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
  },
  galleryColor: {
    color: theme.palette.common.white,
  },
  favouriteIcon: {
    color: theme.palette.info.main,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 80,
  },
  desktopButtonSpacing: {
    margin: `0 ${theme.spacing()}px`,
  },
  desktopButtonText: {
    fontSize: 10,
  },
  mobileButtonContainer: {
    margin: `0 ${theme.spacing() / 2}px`,
    justifyContent: "space-between",
  },
  learnMoreContainer: {
    display: "flex",
    justifyContent: "center",
  },
  iconButtonLabel: {
    flexDirection: "column",
  },
  iconButtonSubcontainer: {
    display: "flex",
  },
}));

type ComponentProps = OwnProps & WithMediaDownProps;

const PlaceOverviewCard: React.FC<ComponentProps> = ({
  variant = "vertical",
  placeId,
  isMediaDown,
  onFavouriteClick,
  onVisitedClick,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [commentsBackdropOpened, setCommentsBackdropOpened] =
    useState<boolean>(false);
  const mapRef = createRef<HTMLDivElement>();
  const place = useAppSelector((state) => selectPlaceById(state, placeId));
  const region = useAppSelector((state) =>
    selectRegionById(state, place ? place.regionId : "")
  );
  const category = useAppSelector((state) =>
    selectCategoryById(state, place ? place.categoryId : "")
  );
  const subcategory = useAppSelector((state) =>
    selectSubcategoryById(state, place ? place.subCategoryId : "")
  );
  const isVisited = useAppSelector((state) =>
    selectIsVisitedPlace(state, placeId)
  );
  const isInFavourite = useAppSelector((state) =>
    selectIsFavouritePlace(state, placeId)
  );
  const images: string[] = useMemo(() => {
    return place ? [place.coverPhoto!, ...place.pictures] : [];
  }, [place]);

  useEffect(() => {
    if (!place) {
      dispatch(ensurePlacesByIds([placeId])).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const mapSrc = useMemo(() => {
    if (place) {
      return `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${place.location.latitude},${place.location.longitude}&center=${place.location.latitude},${place.location.longitude}&zoom=12&region=bg`;
    }
    return "";
  }, [place]);

  // useEffect(() => {
  //   if (mapRef && mapRef.current && place) {
  //     const map = new google.maps.Map(mapRef.current, {
  //       center: {
  //         lat: place.location.latitude,
  //         lng: place.location.longitude,
  //       },
  //       zoom: 12,
  //     });
  //     new google.maps.Marker({
  //       position: {
  //         lat: place.location.latitude,
  //         lng: place.location.longitude,
  //       },
  //       map,
  //     });
  //   }
  // }, [mapRef.current, place]);

  const handleFavouriteClick = useCallback(() => {
    dispatch(updateFavouritePlaces(placeId)).then((favouritePlaces) => {
      if (onFavouriteClick) {
        onFavouriteClick(favouritePlaces);
      }
    });
  }, [onFavouriteClick]);
  const handleVisitedClick = useCallback(() => {
    dispatch(updateVisitedPlaces(placeId)).then((visitedPlaces) => {
      if (onVisitedClick) {
        onVisitedClick(visitedPlaces);
      }
    });
  }, [onVisitedClick]);

  if (!place && !loading) {
    return (
      <Typography variant="h5" color="primary" align="center">
        Не беше открит такъв обект!
      </Typography>
    );
  }

  return (
    <WithLoadingBackdrop open={loading}>
      <Card variant="outlined" className={classes.cardRoot}>
        {place && region && (
          <Grid
            spacing={2}
            container
            justifyContent="center"
            direction={variant === "vertical" ? "column" : "row"}
          >
            <Grid item xs={12} lg={variant === "vertical" ? 12 : 3}>
              <CardMedia
                onClick={() => setIsOpen(true)}
                image={place.coverPhoto}
                className={classNames(classes.cardMedia, {
                  [classes.cardMediaHorizontal]: variant === "horizontal",
                })}
              >
                <div
                  className={classes.seeGaleryContainer}
                  onClick={() => setIsOpen(true)}
                >
                  <IconButton>
                    <Typography
                      variant="body2"
                      className={classes.galleryColor}
                    >
                      ГАЛЕРИЯ
                    </Typography>
                  </IconButton>
                </div>
                <div className={classes.photoLength}>
                  <Typography variant="body2">
                    {place.pictures.length + 1}
                  </Typography>
                </div>
              </CardMedia>
              <div
                className={classNames(classes.alignItems, {
                  [classes.mobileButtonContainer]: isMediaDown,
                })}
              >
                <div
                  className={classNames(classes.buttonContainer, {
                    [classes.desktopButtonSpacing]: !isMediaDown,
                  })}
                >
                  <IconButton
                    classes={{ label: classes.iconButtonLabel }}
                    onClick={handleFavouriteClick}
                  >
                    <div className={classes.iconButtonSubcontainer}>
                      {isInFavourite ? (
                        <FavoriteIcon className={classes.favouriteIcon} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classNames({
                          [classes.favouriteIcon]: isInFavourite,
                        })}
                      >
                        &nbsp;{place.favouriteCount}
                      </Typography>
                    </div>
                    <Typography
                      className={classNames(
                        {
                          [classes.desktopButtonText]: !isMediaDown,
                        },
                        {
                          [classes.favouriteIcon]: isInFavourite,
                        }
                      )}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Любими
                      {/* {isInFavourite ? "Премахни от любими" : "Добави в любими"} */}
                    </Typography>
                  </IconButton>
                </div>
                <div
                  className={classNames(classes.buttonContainer, {
                    [classes.desktopButtonSpacing]: !isMediaDown,
                  })}
                >
                  <IconButton
                    classes={{ label: classes.iconButtonLabel }}
                    onClick={handleVisitedClick}
                  >
                    <div className={classes.iconButtonSubcontainer}>
                      {isVisited ? (
                        <LocationOnIcon color="primary" />
                      ) : (
                        <LocationOnOutlinedIcon />
                      )}
                      <Typography
                        variant="subtitle1"
                        color={isVisited ? "primary" : "textSecondary"}
                      >
                        &nbsp;{place.visitedCount}
                      </Typography>
                    </div>
                    <Typography
                      className={classNames({
                        [classes.desktopButtonText]: !isMediaDown,
                      })}
                      variant="subtitle1"
                      color={isVisited ? "primary" : "textSecondary"}
                    >
                      Посетени
                      {/* {isVisited ? "Премахни от посетени" : "Добави в посетени"} */}
                    </Typography>
                  </IconButton>
                </div>
                <div
                  className={classNames(classes.buttonContainer, {
                    [classes.desktopButtonSpacing]: !isMediaDown,
                  })}
                >
                  <IconButton
                    classes={{ label: classes.iconButtonLabel }}
                    onClick={() => {
                      setCommentsBackdropOpened(true);
                    }}
                  >
                    <div className={classes.iconButtonSubcontainer}>
                      <CommentOutlinedIcon />
                      <Typography variant="subtitle1" color="textSecondary">
                        &nbsp;{place.notes.length}
                      </Typography>
                    </div>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={classNames({
                        [classes.desktopButtonText]: !isMediaDown,
                      })}
                    >
                      Коментари
                    </Typography>
                  </IconButton>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={variant === "vertical" ? 12 : 6}
              className={
                variant === "vertical" ? classes.gridItemPadding : undefined
              }
            >
              <Typography variant="h5" color="primary" align="left">
                {place.name}
              </Typography>
              <Typography variant="body2" color="primary">
                Област {region.name.toUpperCase()}
              </Typography>
              {category && (
                <Typography variant="body2" color="primary">
                  {category.name}
                </Typography>
              )}
              {subcategory && (
                <Typography variant="body2" color="textSecondary">
                  {subcategory.name}
                </Typography>
              )}
              <Collapse
                in={showMore}
                collapsedSize={
                  variant === "vertical" ? (isMediaDown ? 100 : 80) : 100
                }
              >
                <Typography variant="body2">{place.description}</Typography>
              </Collapse>
              <div className={classes.learnMoreContainer}>
                <Button
                  onClick={() => setShowMore(!showMore)}
                  variant="text"
                  color="primary"
                >
                  {showMore ? "СКРИЙ" : "ПРОЧЕТИ ПОВЕЧЕ"}
                </Button>
              </div>
            </Grid>
            {/* <Grid
              item
              xs={12}
              lg={variant === "vertical" ? 12 : 2}
              className={classNames(
                variant === "horizontal" ? classes.alignItems : undefined,
                variant === "vertical" ? classes.gridItemPadding : undefined
              )}
            >
              <Button
                fullWidth
                startIcon={<AddIcon />}
                variant="outlined"
                color="primary"
              >
                <Typography variant="body2">
                  Добави място за хапване наблизо
                </Typography>
              </Button>
            </Grid> */}
            <Grid
              item
              xs={12}
              lg={variant === "vertical" ? 12 : 3}
              className={classNames(
                classes.mapContainer,
                variant === "vertical" ? classes.gridItemPadding : undefined
              )}
            >
              <Typography
                variant="caption"
                color="primary"
                align="center"
                gutterBottom
              >
                НА КАРТАТА
              </Typography>
              {/* <div
                ref={mapRef}
                className={
                  variant === "vertical"
                    ? classes.verticalMapSize
                    : classes.horizontalMapSize
                }
              /> */}
              <iframe
                className={
                  variant === "vertical"
                    ? classes.verticalMapSize
                    : classes.horizontalMapSize
                }
                loading="lazy"
                src={mapSrc}
              ></iframe>
            </Grid>
          </Grid>
        )}
      </Card>
      {isOpen ? (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => {
            setImageIndex(0);
            setIsOpen(false);
          }}
          onMovePrevRequest={() => {
            setImageIndex((imageIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setImageIndex((imageIndex + 1) % images.length);
          }}
        />
      ) : null}
      <PlaceCommentsBackdrop
        open={commentsBackdropOpened}
        placeId={placeId}
        onleBackdropClose={() => setCommentsBackdropOpened(false)}
      />
    </WithLoadingBackdrop>
  );
};

export default WithMediaDown(PlaceOverviewCard, "md");
