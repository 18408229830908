import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";
import * as React from "react";

type CustomTextFieldProps = Omit<TextFieldProps, "classes">;

export type FormikTextFieldProps = FieldProps &
  Omit<CustomTextFieldProps, "name" | "value" | "error"> & {
    transformInput: (valueToTransform: string) => string;
  };

export function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  onChange,
  transformInput,
  helperText,
  variant,
  ...props
}: FormikTextFieldProps): CustomTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText || " ",
    disabled: disabled || isSubmitting,
    onBlur:
      onBlur ||
      function (e) {
        fieldOnBlur(e || field.name);
      },
    onChange:
      onChange ||
      function (e) {
        if (transformInput) {
          e.target.value = transformInput(e.target.value);
        }
        fieldOnChange(e);
      },
    ...field,
    ...props,
  };
}

const FormikTextField: React.FC<FormikTextFieldProps> = ({
  children,
  variant,
  ...props
}) => (
  <TextField {...fieldToTextField(props)} variant={variant || "outlined"}>
    {children}
  </TextField>
);

export default FormikTextField;
