import axios from "axios";
import SubCategory from "../entities/SubCategory";

export function fetchSubcategoriesByIds(
  ids: string[]
): Promise<SubCategory.Type[]> {
  return new Promise<SubCategory.Type[]>((resolve, reject) => {
    axios
      .get(`/subcategories/?ids=${ids.join("&ids=")}`)
      .then(({ data: { result } }) => {
        resolve(result.map(SubCategory.parse));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchSubcategoryIds(): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get("/subcategories/?include_fields=id")
      .then(({ data: { result } }) => {
        resolve(result.map((el: any) => el.uuid));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchSubcategoriesByCategoryId(
  categoryId: string
): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get(`/subcategories/?category_id=${categoryId}&include_fields=id`)
      .then(({ data: { result } }) => {
        resolve(result.map((el: any) => el.uuid));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchSubcategoriesByCategoryIds(
  categoryIds: string[]
): Promise<string[]> {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set("include_fields", "id");
  categoryIds.forEach((id) => {
    urlSearchParams.append("category_id", id);
  });
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get(`/subcategories/?${urlSearchParams.toString()}`)
      .then(({ data: { result } }) => {
        resolve(result.map((el: any) => el.uuid));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
